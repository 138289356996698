import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-tailwind/react";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import DefaultLayout from "layouts/default";
import AuthLayout from "layouts/auth";
import Loader from "components/spinners/Loader";
import { setUpDataManager } from "dataManager";
import { stateMappings } from "./redux/stateMappings";
import { getStatesThunk, getStoresThunk, signOutThunk } from './redux/auth/thunks';
import { getClippedCouponsThunk } from "./redux/coupon/thunks";
import { getClientConfigsThunk, getClientThunk } from "./redux/client/thunks";
import { getAccountThunk, getShopperThunk, setThemeModeThunk } from './redux/user/thunks';
import { THEME_COLOR } from "variables/constants";
import { setThemeColors } from "lib/common";
import { withRouter } from "lib/withRouter";
import { STEVES_THEME } from "./material-tailwind-themes";
import config from "config";

class App extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.loadData();
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      location,
      getShopper,
      signOut
    } = this.props;

    if (JSON.stringify(prevProps.isAuthenticated) !== JSON.stringify(isAuthenticated)) {
      this.loadData();
    }

    if (location.pathname !== prevProps.location.pathname) {
      if (isAuthenticated) {
        getShopper(signOut, false)
      }
    }
  }

  loadData = async () => {
    const {
      getConfigs,
      getClient,
      getAccount,
      getShopper,
      getClippedCoupons,
      getStores,
      getStates,
      signOut,
      isAuthenticated,
      accountid,
    } = this.props;

    try {
      await Promise.all([
        ...[isAuthenticated ? [
          getAccount(accountid),
          getClippedCoupons(accountid),
          getShopper(signOut),
        ] : []],
        getConfigs(),
        getClient(),
        getStores(),
        getStates(),
      ]);
    } catch {
      
    }
  }

  render() {
    const { isAdmin, isAuthenticated, loading, client } = this.props;

    // if (!!client.isDefault) { // if client is not existed, navigates to default website.
    //   const defaultURL = "https://"+config.domain.replace("*", client.username);
    //   window.location.href = defaultURL;
    //   return;
    // }

    // customize theme colors
    const txColor = client?.txColor || THEME_COLOR.TEXT;
    const bgColor = client?.bgColor || THEME_COLOR.BACKGROUND;
    const brColor = client?.brColor || THEME_COLOR.BORDER;
    setThemeColors(bgColor, brColor, txColor);

    return (
      <ThemeProvider value={STEVES_THEME}>
        {loading && <Loader color={brColor} />}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
          lightBackground
        />
        <Routes>
          <Route exact path="auth/*" element={!isAuthenticated ? <AuthLayout /> : <Navigate to="/" replace />} />
          <Route exact path="/*" element={<DefaultLayout isAuthenticated={isAuthenticated} isAdmin={isAdmin} />} />
        </Routes>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated,
    isAdmin: sm.isAdmin,
    admin: sm.user.admin,
    client: sm.client.client || {},
    accountid: sm.accountid,
    loading: sm.loading,
  };
};

const mapActionsToProps = {
  setUpDataManager,
  getConfigs: getClientConfigsThunk,
  getClient: getClientThunk,
  getStates: getStatesThunk,
  getStores: getStoresThunk,
  getAccount: getAccountThunk,
  getShopper: getShopperThunk,
  getClippedCoupons: getClippedCouponsThunk,
  setTheme: setThemeModeThunk,
  signOut: signOutThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(App));

