import { Link } from "react-router-dom";
import routes from "routes.js";
import { withTranslation } from "react-i18next";

const Footer = ({ t }) => {
  const footerRoutes = routes.filter(route => route.primary);

  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <p className="mb-4 text-center text-sm theme-tx-color dark:text-gray-600 dark:hover:text-white sm:!mb-0 md:text-base">
          {t("common:copyright", {year: 1900+new Date().getYear(), company:"Loyalty Lane"})}
        </p>
      </h5>
      <div>
        <ul className="flex flex-wrap justify-center items-center gap-3 sm:flex-nowrap md:gap-10">
          {footerRoutes.map((route, index) =>
            <li
              key={`li-route-${index}`}
            >
              <Link
                to={`${route.layout}/${route.path}`}
                className="text-base font-medium text-gray-600 theme-tx-color-hover dark:hover:text-white"
              >
                {t(route.name)}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
