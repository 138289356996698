export const applicationComplex = [
  {
    label: 'Full Name',
    span: 4,
    child: [
      {
        label: 'First Name',
        name: 'firstName',
        component: 'input',
        required: true
      },
      {
        label: 'Last Name',
        name: 'lastName',
        component: 'input',
        required: true
      },
      {
        label: 'Middle Name',
        name: 'middleName',
        component: 'input',
        required: true
      },
      {
        label: 'Maiden Name',
        name: 'maidenName',
        component: 'input',
        required: true
      }
    ]
  },
  {
    label: 'Address',
    span: 4,
    child: [
      {
        label: 'Street',
        name: 'street',
        component: 'input',
        required: true
      },
      {
        label: 'City',
        name: 'city',
        component: 'input',
        required: true
      },
      {
        label: 'State',
        name: 'state',
        component: 'input',
        required: true
      },
      {
        label: 'Zip',
        name: 'zip',
        component: 'input',
        required: true
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Phone Number',
        name: 'phoneNumber',
        component: 'input',
        required: true
      },
      {
        label: 'E-mail',
        name: 'email',
        component: 'input',
        required: true
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'Are you at least 16 years of age?',
        name: 'is16Adult',
        component: 'checkbox',
        default: true
      },
      {
        label: 'Are you at least 18 years of age?',
        name: 'is18Adult',
        component: 'checkbox',
        default: true
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'Are you a citizen of the United States?',
        name: 'isUSCitizen',
        component: 'checkbox',
        default: true
      },
      {
        label: 'If no, are you authorized to work in the United States?',
        name: 'workAuth',
        component: 'checkbox',
        default: true
      },
      {
        label: 'Have you ever pled guilty, no contest or been convicted of a misdemeanor or a felony? Do not include minor traffic violations or arrests without convictions. Answering yes to this question does not constitute an automatic bar to employment. Factors such as the date of the offense, nature of the violation, and position applied for will be taken into account.',
        name: 'violation',
        component: 'checkbox',
        default: false
      },
      {
        label: 'if yes, explain',
        name: 'violationExp',
        component: 'textarea',
      }
    ]
  },
  {
    label: 'Position being applied for (check all that apply)',
    span: 3,
    child: [
      {
        label: 'Store Manager',
        name: 'storeMananger',
        component: 'checkbox'
      },
      {
        label: 'Office Mananger',
        name: 'officeManager',
        component: 'checkbox'
      },
      {
        label: 'Cashier',
        name: 'cashier',
        component: 'checkbox'
      },
      {
        label: 'Assistant Store Manager',
        name: 'assistant',
        component: 'checkbox'
      },
      {
        label: 'Office Clerk/Bookkeeper',
        name: 'bookKeeper',
        component: 'checkbox'
      },
      {
        label: 'Grocery Clerk/Stocker',
        name: 'stocker',
        component: 'checkbox'
      },
      {
        label: 'Shift Mananger',
        name: 'shiftManager',
        component: 'checkbox'
      },
      {
        label: 'Perishables Department Clerk',
        name: 'depClerk',
        component: 'checkbox'
      },
      {
        label: 'Other (specify)',
        name: 'otherSpecify',
        component: 'input',
      }
    ]
  },
  {
    label: 'Indicate the number of hours/week you desire to work',
    span: 3,
    child: [
      {
        label: '25 or Less',
        name: 'less25',
        component: 'checkbox'
      },
      {
        label: '35 or More',
        name: 'more35',
        component: 'checkbox'
      },
    ],
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Location being applied for',
        name: 'location',
        component: 'select',
        options: [
          'Urbana',
          'North Lewisburg',
          'DeGraff'
        ]
      }
    ]
  },
  {
    label: 'List the approximate times of availability',
    span: 5,
    child: [
      {
        label: 'Sunday',
        name: 'sunday',
        component: 'input'
      },
      {
        label: 'Thursday',
        name: 'thursday',
        component: 'input'
      },
      {
        label: 'Monday',
        name: 'monday',
        component: 'input'
      },
      {
        label: 'Friday',
        name: 'friday',
        component: 'input'
      },
      {
        label: 'Tuesday',
        name: 'tuesday',
        component: 'input'
      },
      {
        label: 'Saturday',
        name: 'saturday',
        component: 'input'
      },
      {
        label: 'Wednesday',
        name: 'wednesday',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Date available',
        name: 'dateAvailable',
        component: 'input'
      },
      {
        label: 'Desired starting rate of pay',
        name: 'payRate',
        component: 'input'
      }
    ],
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'Have you ever worked with us before?',
        name: 'everWorkedWithUs',
        component: 'checkbox'
      },
    ]
  },
  {
    label: '',
    span: 3,
    child: [
      {
        label: 'Date/From',
        name: 'fromDate',
        component: 'input'
      },
      {
        label: 'Date/To',
        name: 'fromTo',
        component: 'input'
      },
      {
        label: 'Location worked',
        name: 'locationWorked',
        component: 'select',
        options: [
          'Urbana',
          'North Lewisburg',
          'DeGraff'
        ]
      },
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Job title',
        name: 'jobTitle',
        component: 'input',
      },
      {
        label: 'Why did you leave?',
        name: 'leaveReason',
        component: 'input'
      }
    ]
  },
  {
    label: 'Previous work experience (in most recent order)',
    span: 1,
    child: [
      {
        label: 'Are you currently working?',
        name: 'currentWorking',
        component: 'checkbox'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Company Name',
        name: 'companyName1',
        component: 'input'
      },
      {
        label: 'Company Address',
        name: 'companyAddress1',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 3,
    child: [
      {
        label: 'Job Title',
        name: 'jobTitle1',
        component: 'input'
      },
      {
        label: 'Start Date',
        name: 'startDate1',
        component: 'input'
      },
      {
        label: 'End Date',
        name: 'endDate1',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Supervisor Name',
        name: 'supervisor1',
        component: 'input'
      },
      {
        label: "Company's Phone",
        name: 'companyPhone1',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Starting rate of pay',
        name: 'startRate1',
        component: 'input'
      },
      {
        label: 'Ending rate of pay',
        name: 'endRate1',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'Summary of job responsibilities',
        name: 'jobSummary1',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'May we contact them for reference?',
        name: 'contactAvailable1',
        component: 'checkbox'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Company Name',
        name: 'companyName2',
        component: 'input'
      },
      {
        label: 'Company Address',
        name: 'companyAddress2',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 3,
    child: [
      {
        label: 'Job Title',
        name: 'jobTitle2',
        component: 'input'
      },
      {
        label: 'Start Date',
        name: 'startDate2',
        component: 'input'
      },
      {
        label: 'End Date',
        name: 'endDate2',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Supervisor Name',
        name: 'supervisor2',
        component: 'input'
      },
      {
        label: "Company's Phone",
        name: 'companyPhone2',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 2,
    child: [
      {
        label: 'Starting rate of pay',
        name: 'startRate2',
        component: 'input'
      },
      {
        label: 'Ending rate of pay',
        name: 'endRate2',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'Summary of job responsibilities',
        name: 'jobSummary2',
        component: 'input'
      }
    ]
  },
  {
    label: '',
    span: 1,
    child: [
      {
        label: 'May we contact them for reference?',
        name: 'contactAvailable2',
        component: 'checkbox'
      }
    ]
  },
  {
    label: 'Highest level of education completed and where?',
    span: 2,
    child: [
      {
        label: 'Education Level',
        name: 'educationLevel',
        component: 'select',
        options: [
          'Did not graduate',
          'GED',
          'High School',
          "Associate's",
          'Some College',
          "Bachelor's",
          "Master's",
          'Ph.D',
          'Technical School'
        ]
      },
      {
        label: 'School Attended',
        name: 'schoolAttended',
        component: 'input'
      },
      {
        label: 'Course of Study',
        name: 'courseOfStudy',
        component: 'input'
      },
      {
        label: 'Graduate',
        name: 'graduate',
        component: 'checkbox'
      }
    ]
  },
  {
    label: 'Please list two references other than relatives or previous employers.',
    span: 2,
    child: [
      {
        label: 'Name',
        name: 'employerName1',
        component: 'input'
      },
      {
        label: 'Name',
        name: 'employerName2',
        component: 'input'
      },
      {
        label: 'Telephone',
        name: 'telephone1',
        component: 'input'
      },
      {
        label: 'Telephone',
        name: 'telephone2',
        component: 'input'
      },
      {
        label: 'Relationship/Years known',
        name: 'relation1',
        component: 'input'
      },
      {
        label: 'Relationship/Years known',
        name: 'relation2',
        component: 'input'
      }
    ]
  },
  {
    label: 'Tell Us Something About You',
    span: 1,
    child: [
      {
        label: 'Do you have dependable transportation?',
        name: 'transportation',
        component: 'checkbox'
      },
      {
        label: 'Are you capable of lifting 40lbs regulary?',
        name: 'lifting',
        component: 'checkbox'
      },
      {
        label: 'How many days of school/work have you missed in the past 12 months',
        name: 'schoolMissed',
        component: 'textarea'
      },
      {
        label: 'What special skills or previous work experiences do you have that will enhance you job performance?',
        name: 'specialSkills',
        component: 'textarea'
      },
      {
        label: `What does "excellent customer service" meal to you and how would you apply it here at Steve's Market & Deli?`,
        name: 'excelCusService',
        component: 'textarea'
      },
      {
        label: 'What are your future plans? Where do you see yourself in 5 years?',
        name: 'futurePlans',
        component: 'textarea'
      },
      {
        label: 'If we were to check your references, what do you think your previous teach/employer would name as your greatest strength and weakness?',
        name: 'weakness',
        component: 'textarea'
      },
      {
        label: 'What do you enjoy doing when you are not at school/work?',
        name: 'enjoyment',
        component: 'textarea'
      },
      {
        label: 'Is there anything else that you would like us to know about you when considering you for employment?',
        name: 'employment',
        component: 'textarea'
      },
    ]
  },
  {
    label: 'A Little Something About Us',
    span: 1,
    descriptions: [
      `Steve's Market & Deli is an Equal Opportunity Employer and makes decisions regarding employment without regard to race, color, sex (including pregnancy), national origin, religion, marital status, age, sexual orientation, mental or physical disability or any other protected classification unrelated to job performance.`,
      `It is the Company's policy to comply with the provisions of the Immigration Reform and Control Act and to hire only authorized workers. If you are hired you will be asked to provide verification of your work eligibility. The type of verification required may change from time to time as federal regulations are promulgated or amended. Your employment will not be considered if you are unable or unwilling to provide the proper verification requested by the Company.`,
      `The Company will keep your application current for a period of not less than 60 days. At the conclusion of that time, if you have not heard from Company and wished to be considered, it will be necessary to reapply and fill out a new application.`,
    ]
  },
  {
    label: 'Application Statement of Understanding',
    span: 1,
    descriptions: [
      `I certify that all information I have provided in order to apply for and secure employment with Steve's Market & Deli is true, complete and accurate.`,
      `I understand that information provided by me that is found to be false, incomplete or misrepresented in any respect, will be deemed as sufficient cause to cancel further consideration of this application, or immediately discharge me from the employer's service, whenever it is discovered.`,
      `I understand that an investigation may be made whereby information regarding my character, previous employment, general reputation, educational background, credit record and/or criminal history. I authorize anyone possessing this information to furnish it to the Company, or any of its agents, upon request and I release anyone so authorized, the Company, or any of its agents from all liability and damages whatsoever in furnishing, obtaining or using said information.`
    ],
    child: [
      {
        label: 'Did you complete this application yourself?',
        name: 'completeYourself',
        component: 'checkbox'
      },
      {
        label: 'If not, who did?',
        name: 'completeWho',
        component: 'input'
      }
    ]
  },
  {
    label: 'I certify that I have read, fully understand and accept all terms of the foregoing Applicant Statement of Understanding.',
    span: 1,
    style: 'italic'
  }
]
