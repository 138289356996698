import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import { Button, Select, Option } from "@material-tailwind/react";
import ReactPaginate from "react-paginate";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";
import SlideSidebar from "components/slide/sidebar"
import CouponCard from "./CouponCard";
import CategoryCard from "./CategoryCard";
import CouponDetails from "./CouponDetails";
import { THEME_COLOR, ITEMS_PER_PAGE, SORT_ITEMS } from "variables/constants";
import { stateMappings } from "../../../../redux/stateMappings";
import { withTranslation } from "react-i18next";

class CouponsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      categories: {},
      brands: {},
      categoryFilter: [],
      brandFilter: [],
      couponList: [],
      categorySearch: '',
      brandSearch: '',
      sort: 'relevant',
      showModal: false,
      activeCoupon: {},
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.setCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const { coupons } = this.props;

    if (!_.isEqual(coupons, prevProps.coupons)) {
      this.setCategories();
    }
  }

  setCategories = () => {
    const { coupons } = this.props;

    this.setState({
      categories: _.groupBy(coupons, 'CategoryName'),
      brands: _.groupBy(coupons, 'Brand'),
    }, () => {
      this.handleSearch()
    })
  }

  getCategoryNames = (categories, filters, order = 'asc') => {
    if (_.isEmpty(categories)) {
      return [];
    }

    const names = _.orderBy(_.union(Object.keys(categories), filters), [], [order]);

    return names;
  }

  onCategoryChange = (categoryType, category) => {
    this.setState({
      [categoryType]: !this.state[categoryType].includes(category) ? [
        ...this.state[categoryType],
        category,
      ] : this.state[categoryType].filter(item => item !== category),
    }, () => {
      this.handleSearch();
    });
  }

  handleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { coupons } = this.props;
    const { brandFilter, categoryFilter, sort } = this.state;

    this.setState({
      couponList: this.sortBy(sort, this.search(categoryFilter, brandFilter, coupons)),
    });
  };

  onSortByChange = (value) => {
    this.setState({
      currentPage: 0,
      sort: value,
      couponList: this.sortBy(value, this.state.couponList)
    })
  }

  search = (c_filter, b_filter, coupons) => {
    const c_list = coupons.filter(coupon => c_filter.includes(coupon['CategoryName']));
    const b_list = coupons.filter(coupon => b_filter.includes(coupon['Brand']));
    const filters = [
      ...b_filter,
      ...c_filter
    ];

    return filters.length === 0 ? coupons : _.union(c_list, b_list);
  }

  sortBy = (sort, coupons) => {
    switch (sort) {
      case SORT_ITEMS.RELEVANT:
        const { categoryFilter, brandFilter } = this.state
        return this.search(categoryFilter, brandFilter, this.props.coupons)
      case SORT_ITEMS.NEWEST:
        return _.orderBy(coupons, 'ActivationDate', 'desc')
      case SORT_ITEMS.VAL_LOWEST:
        return _.orderBy(coupons, 'Value', 'asc')
      case SORT_ITEMS.VAL_HIGHEST:
        return _.orderBy(coupons, 'Value', 'desc')
      case SORT_ITEMS.EXPIRATION:
        return _.orderBy(coupons, 'ExpirationDate', 'asc')
      default:
        return coupons
    }
  }

  openCpDetailsModal = (callback = null) => {
    this.setState({
      showModal: !this.state.showModal
    }, () => {
      if (callback) {
        callback()
      }
    });
  }

  viewDetails = (coupon) => {
    this.setState({
      activeCoupon: {
        ...coupon,
        clipped: coupon.clipped || !this.props.clip
      }
    }, () => {
      this.openCpDetailsModal()
    })
  }
  
  clipCoupon = async (coupon, mode = 'coupon_list', callback = null) => {
    const { clip, balance, getBalance } = this.props;

    if (coupon.PointCost > balance) {
      return;
    }

    try {
      const success = await clip([coupon])

      if (!success) {
        return
      }

      if (balance > 0) {
        await getBalance();
      }

      this.setState({
        activeCoupon: {
          ...coupon,
          clipped: true
        }
      }, () => {
        if (mode === 'coupon_list') {
          this.openCpDetailsModal()
        }
  
        if (callback) {
          callback()
        }
      })
    } catch {

    }
  }

  clearFilters = () => {
    this.setState({
      categoryFilter: [],
      brandFilter: [],
    }, () => {
      const { coupons } = this.props;
      const { brandFilter, categoryFilter, sort } = this.state;
  
      this.setState({
        couponList: this.sortBy(sort, this.search(categoryFilter, brandFilter, coupons)),
      });
    });
  }

  handlePageClick = (event) => {
    this.setState({
      currentPage: event.selected
    })
  };

  render() {
    const {
      open,
      categories,
      categoryFilter,
      brands,
      brandFilter,
      couponList,
      sort,
      showModal,
      activeCoupon,
      currentPage
    } = this.state;
    const {
      isAuthenticated,
      className,
      clip,
      balance,
      client,
      t,
    } = this.props

    const itemOffset = (currentPage * ITEMS_PER_PAGE) % couponList.length
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    const currentItems = couponList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(couponList.length / ITEMS_PER_PAGE);

    const categoryNames = this.getCategoryNames(categories, categoryFilter);
    const brandNames = this.getCategoryNames(brands, brandFilter);

    const SORTLIST = [
      {
        name: t("most_relevant"),
        value: SORT_ITEMS.RELEVANT,
      },
      {
        name: t("newest"),
        value: SORT_ITEMS.NEWEST,
      },
      {
        name: t("value"),
        icon: <FaSortAmountDownAlt />,
        value: SORT_ITEMS.VAL_LOWEST,
      },
      {
        name: t("value"),
        icon: <FaSortAmountDown />,
        value: SORT_ITEMS.VAL_HIGHEST,
      },
      {
        name: t("expiration"),
        value: SORT_ITEMS.EXPIRATION,
      },
    ];

    const couponFilterNode = (
      <>
        <div className="mt-3 mb-3">
          <Select
            variant="standard"
            name="sort"
            label={t("sort_by")}
            color={client?.brColor || THEME_COLOR.BORDER}
            value={sort}
            onChange={this.onSortByChange}
          >
            {SORTLIST.map((sort, index) =>
              <Option
                key={`sort-${index}`}
                className="flex justify-between"
                value={sort.value}
              >
                <span>{sort.name}</span>
                {sort.icon}
              </Option>
            )}
          </Select>
        </div>
        <p className="mb-2 px-2 text-end">
          <span
            className={`text-sm underline cursor-${brandFilter.length === 0 && categoryFilter.length === 0 ? 'not-allowed' : 'pointer theme-tx-color-hover'} dark:text-white`}
            onClick={this.clearFilters}
          >
            {t("clear_all")}
          </span>
        </p>
        <CategoryCard
          className="mb-3"
          title={t("categories")}
          data={categories}
          filters={categoryFilter}
          categories={categoryNames}
          categoryType="categoryFilter"
          onCategoryChange={this.onCategoryChange}
          color={client?.brColor || THEME_COLOR.BORDER}
        />
        <CategoryCard
          className="mb-3"
          title={t("brands")}
          data={brands}
          filters={brandFilter}
          categoryType="brandFilter"
          categories={brandNames}
          onCategoryChange={this.onCategoryChange}
          color={client?.brColor || THEME_COLOR.BORDER}
        />
      </>
    )

    return (
      <>
        <CouponDetails
          header={t("coupon")+" "+t("details")}
          open={showModal}
          coupon={activeCoupon}
          openCpDetailsModal={this.openCpDetailsModal}
          clip={this.clipCoupon}
          balance={balance}
          isAuthenticated={isAuthenticated}
          color={client?.brColor || THEME_COLOR.BORDER}
        />
        <div className={`mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 ${className}`}>
          <div className="col-span-1 hidden md:block">
            {couponFilterNode}
          </div>
          <SlideSidebar
            className="fixed top-0 -left-[260px] w-[260px]"
            open={open}
            handleOpen={this.handleOpen}
            color={client?.brColor || THEME_COLOR.BORDER}
          >
            {couponFilterNode}
          </SlideSidebar>
          <div className="col-span-1 h-fit w-full md:col-span-2 xl:col-span-3 2xl:col-span-5">
            <div className="flex justify-end mb-3 md:hidden">
              <Button
                variant="text"
                color={client?.brColor || THEME_COLOR.BORDER}
                onClick={this.handleOpen}
              >
                {t("filter_sort")}
              </Button>
            </div>
            {/* coupon cards */}
            <div className="z-20 grid grid-cols-1 gap-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5">
              {currentItems.map((coupon, index) =>
                <CouponCard
                  key={`coupon-card-${index}`}
                  buttonClassName="mt-5"
                  { ...coupon }
                  clip={clip ? this.clipCoupon : undefined}
                  viewDetails={this.viewDetails}
                  balance={balance}
                  isAuthenticated={isAuthenticated}
                />
              )}
            </div>

            <div className="mt-5 flex justify-center">
              <ReactPaginate
                disabledClassName={"cursor-not-allowed disabled-pagination !text-gray-700 !bg-gray-300"}
                pageClassName="sm:px-3 px-2 py-2 sm:py-1 rounded-md dark:text-white"
                previousClassName="px-2 sm:px-3 py-2 sm:py-1 rounded-md theme-bg-color text-white mr-2 dark:bg-navy-800"
                nextClassName="px-2 sm:px-3 py-2 sm:py-1 rounded-md theme-bg-color text-white ml-2 dark:bg-navy-800"
                breakClassName={"break-me px-2 py-2 sm:px-1 text-gray-400"}
                containerClassName={"react-paginate text-sm leading-tight flex mt-8 items-center"}
                activeClassName={"bg-brand-600 text-white dark:bg-navy-700"}
                breakLabel="..."
                nextLabel={t("common:next")}
                previousLabel={t("common:prev")}
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    theme: sm.user.theme,
    client: sm.client.client || {}
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation(["common"])(CouponsPanel));
