//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getSpecials = async (storeid = 0, showdisabled = false) =>
  ApiRequest.request({
    method: 'GET',
    url: `/point-specials`,
    params: {
      storeid,
      showdisabled
    }
  });