
import moment from "moment/moment";
import styled from 'styled-components';
import { BsClock } from "react-icons/bs";
import { BiCut } from "react-icons/bi";
import { PiCheckLight } from "react-icons/pi";
import { IoIosLogIn } from "react-icons/io";
import Card from "components/card";
import { withRouter } from "lib/withRouter";
import { withTranslation } from "react-i18next";

const CouponCard = ({
  isAuthenticated,
  className,
  buttonClassName,
  viewDetails,
  clip,
  balance,
  navigate,
  t,
  ...coupon
}) => {
  const {
    PointCost,
    OfferSummaryTop,
    OfferSummaryDetail,
    ImageUrl,
    ExpirationDate
  } = coupon

  return (
    <Card
      className={`flex flex-col w-full h-full bg-white border-1 border-loyal-500 border-dashed hover:shadow-2xl ${className || ''}`}
    >
      <div className="absolute top-1 right-1">
        <span className={`theme-bg-color text-white dark:bg-blue-900 dark:text-blue-300 text-xs font-medium me-2 px-2.5 py-0.5 rounded`}>{`${PointCost} pts`}</span>
      </div>
      <div
        className="!p-4 3xl:p-![18px] cursor-pointer"
        onClick={() => viewDetails(coupon)}
      >
        <div className="mt-5 mb-3 flex justify-center">
          <img
            src={ImageUrl}
            className="h-[150px] w-auto"
          />
        </div>
        <p className={`mb-2 text-2xl uppercase theme-tx-color dark:text-white`}>
          <strong>{OfferSummaryTop}</strong>
        </p>
        <SummaryDetail className="mt-1 text-sm text-black-600 md:mt-2 font-normal">
          {OfferSummaryDetail}
        </SummaryDetail>
        <p className={`flex items-center mt-1 text-sm font-medium md:mt-2 theme-tx-color dark:text-white`}>
          <BsClock className="mr-1 h-3 w-3" />
          <small>{` ${t("expires")} ${moment(ExpirationDate).format('MM/DD/YY')}`}</small>
        </p>
      </div>

      <div className="mt-auto">
        <div className={buttonClassName || ''}>
          {clip &&
            <>
              {balance >= PointCost ?
                <div
                  className={`py-3 flex items-center justify-center gap-2 cursor-pointer border-t-1 theme-bg-color text-white rounded-b-[10px] dark:bg-navy-600`}
                  onClick={() => isAuthenticated ? clip(coupon) : navigate('/auth/sign-in')}
                >
                  {isAuthenticated ?
                    <>
                      <BiCut className="w-5 h-5" />
                      <strong>{t("common:clip_coupon")}</strong>
                    </>
                    :
                    <>
                      <IoIosLogIn className="w-5 h-5" />
                      <strong>{t("auth:sign_in_to_clip")}</strong>
                    </>
                  }
                </div>
                :
                <div
                  className="py-2 flex items-center justify-center gap-2 cursor-not-allowed border-t-1 border-dashed theme-br-color bg-gray-200 rounded-b-[10px] text-gray-500 dark:!bg-gray-800 dark:text-white"
                >
                  <strong className="italic">
                    {t("not_enough_points")}
                  </strong>
                </div>
              }
            </>
          }
          {!clip &&
            <div
              className="py-2 flex items-center justify-center gap-2 cursor-not-allowed border-t-1 border-dashed theme-br-color bg-loyal-500 rounded-b-[10px] text-white dark:!bg-navy-700 dark:text-white"
            >
              <PiCheckLight className="w-5 h-5" />
              <strong>
                {t("clipped_coupon")}
              </strong>
            </div>
          }
        </div>
      </div>
    </Card>
  );
};

export default withRouter(withTranslation(["common"])(CouponCard));

const SummaryDetail = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`