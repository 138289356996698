import FileUploader from "./components/FileUploader";

const AdUploader = () => {
  return (
    <>
      <FileUploader isAd />
    </>
  );
};

export default AdUploader;

