import { getStatesThunk, getStoresThunk } from '../redux/auth/thunks';
import { getShopperThunk } from '../redux/user/thunks';

export const setUpDataManager = (isAuthenticated) => async (dispatch, getState) => {
  try {
    await Promise.all([
      isAuthenticated ? dispatch(getShopperThunk()) : undefined,
      dispatch(getStoresThunk()),
      dispatch(getStatesThunk()),
    ]);
  } catch {
    
  }
};

