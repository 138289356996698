import React, { Component } from "react";
import { connect } from 'react-redux';
import { FaCopy } from "react-icons/fa6";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Button,
  Select,
  Option,
  Switch,
} from "@material-tailwind/react";
import AdminTable from "./components/AdminTable";
import { stateMappings } from "../../../redux/stateMappings";
import { getClientsThunk } from "../../../redux/client/thunks";
import { inviteAdminThunk, getAdminsThunk, updateAdminThunk, removeAdminThunk } from "../../../redux/admin/thunks";
import { THEME_COLOR, ROLE } from "variables/constants";
import { withTranslation } from "react-i18next";

class Admins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openInvite: false,
      openDetail: false,
      email: '',
      inviteLink: '',
      fullName: '',
      status: false,
      role: '',
      clientId: '',
    }
  }

  async componentDidMount() {
    await this.props.getAdmins();
    await this.props.getClients();
  }

  openInviteModal = () => {
    this.setState({
      openInvite: !this.state.openInvite,
      email: '',
      inviteLink: ''
    })
  }

  openDetailsModal = (data) => {
    const { _id, email, fullName, status, role, client } = data;
    this.setState({
      openDetail: !this.state.openDetail,
      _id,
      email,
      fullName, 
      status,
      role,
      clientId: client
    });
  }

  clearDetails = () => {
    this.setState({
      openDetail: !this.state.openDetail,
      _id: '',
      role: '',
      client: ''
    });
  }

  onChange = ({ target: { name, value, type } }) => {
    this.setState({
      ...this.state,
      [name]: type !== 'checkbox' ? value : !this.state[name],
    });
  }

  onChangeSelectItem = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    const token = await this.props.inviteAdmin(this.state.email)
    this.setState({
      inviteLink: `${window.location.origin}/invite/${token}`
    });
  };

  handleSubmitForUpdate = async e => {
    e.preventDefault();

    const { _id, email, fullName, status, role, clientId } = this.state;
    console.log(this.state);
    await this.props.updateAdmin({
      _id,
      email,
      fullName,
      status,
      role,
      client: clientId
    });
    this.setState({
      openDetail: !this.state.openDetail
    });
  };

  render() {
    const { openInvite, openDetail, email, inviteLink, role, clientId } = this.state;
    const { admins, removeAdmin, client, clients, t } = this.props;

    const columnsDataCheck = [
      {
        Header: t("name").toUpperCase(),
        accessor: "fullName",
      },
      {
        Header: t("email").toUpperCase(),
        accessor: "email",
      },
      {
        Header: t("date").toUpperCase(),
        accessor: "createdAt",
      },
      {
        Header: t("status").toUpperCase(),
        accessor: "status",
      },
      {
        Header: t("role").toUpperCase(),
        accessor: "role",
      },
      {
        Header: t("action").toUpperCase(),
        accessor: "_id",
      },
    ];

    return (
      <>
        <Dialog
          open={openInvite}
          size={"md"}
          handler={this.openInviteModal}
          className="py-2 px-3 dark:bg-navy-800 dark:text-white md:py-4 md:px-5"
        >
          <DialogHeader className="theme-tx-color">{t("Send An Invite")}</DialogHeader>
          <DialogBody>
            <form onSubmit={this.handleSubmit}>
              <div className="flex justify-center items-center gap-5">
                <Input
                  name="email"
                  variant="standard"
                  label={t("email")}
                  placeholder="mail@loyaltylane.com"
                  type="text"
                  onChange={this.onChange}
                  value={email}
                  required
                  color={client?.brColor || THEME_COLOR.BORDER}
                />
                <Button
                  variant="gradient"
                  type="submit"
                  color={client?.brColor || THEME_COLOR.BORDER}
                >
                  {t("Get Invite Link")}
                </Button>
              </div>
            </form>
            <div className="relative mt-5">
              <Input
                name="email"
                variant="standard"
                type="text"
                value={inviteLink}
                readOnly
                color={client?.brColor || THEME_COLOR.BORDER}
              />
              {!!inviteLink &&
                <FaCopy
                  className="absolute top-[15px] right-[10px] w-5 h-5 cursor-pointer text-brand-500 hover:text-brand-300"
                  onClick={() => {navigator.clipboard.writeText(inviteLink)}}
                />
              }
            </div>
          </DialogBody>
          <DialogFooter className="flex justify-center">
            <Button
              variant="gradient"
              onClick={() => this.openInviteModal()}
              color={client?.brColor || THEME_COLOR.BORDER}
            >
              <span>{t("common:ok")}</span>
            </Button>
          </DialogFooter>
        </Dialog>
        <Dialog
          open={openDetail}
          size={"md"}
          handler={this.openDetailsModal}
          className="py-2 px-3 dark:bg-navy-800 dark:text-white md:py-4 md:px-5"
        >
          <DialogHeader className="theme-tx-color">{t("edit")+" "+t("admin")}</DialogHeader>
          <DialogBody>
            <form onSubmit={this.handleSubmitForUpdate}>
              <div className="flex gap-5">
                <div className="mb-4">
                  <Switch
                    variant="standard"
                    name="status"
                    label={t("status")}
                    color={client?.brColor || THEME_COLOR.BORDER}
                    checked={this.state.status}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="flex grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="mb-4">
                  <Select
                    variant="standard"
                    label={t("admin")+" "+t("role")}
                    onChange={(data) => this.onChangeSelectItem("role", data)}
                    value={role}
                    color={client?.brColor || THEME_COLOR.brColor}
                  >
                    <Option key={`role-admin`} value={ROLE.ADMIN}>{ROLE.ADMIN.toUpperCase()}</Option>
                    <Option key={`role-shopper`} value={ROLE.SHOPPER}>{ROLE.SHOPPER.toUpperCase()}</Option>
                  </Select>
                </div>
                <div className="mb-4">
                  <Select
                    variant="standard"
                    label={t("client")+" "+t("name")}
                    onChange={(data) => this.onChangeSelectItem("clientId", data)}
                    value={clientId}
                    color={client?.brColor || THEME_COLOR.brColor}
                  >
                    {(clients || []).map((item, index)=>
                      <Option key={`client-${index}`} value={item._id}>{item.username}</Option>
                    )}
                  </Select>
                </div>
              </div>
            </form>
          </DialogBody>
          <DialogFooter className="flex justify-center">
            <Button
              variant="outlined"
              onClick={this.clearDetails}
              className="mr-3"
              color={client?.brColor || THEME_COLOR.brColor}
            >
              <span>{t("common:cancel")}</span>
            </Button>
            <Button
              variant="gradient"
              type="submit"
              onClick={this.handleSubmitForUpdate}
              color={client?.brColor || THEME_COLOR.brColor}
            >
              {t("common:save")}
            </Button>
          </DialogFooter>
        </Dialog>
        <div className="mt-5">
          <div className="flex justify-end mb-5">
            <Button
              variant="gradient"
              onClick={this.openInviteModal}
              color={client?.brColor || THEME_COLOR.BORDER}
            >
              {t("Add Admin")}
            </Button>
          </div>
          <AdminTable
            columnsData={columnsDataCheck}
            tableData={admins}
            openDetails={this.openDetailsModal}
            remove={removeAdmin}
            color={client?.brColor || THEME_COLOR.BORDER}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    admins: sm.admin.admins || [],
    clients: sm.client.clients || [],
    client: sm.client.client || {},
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  inviteAdmin: inviteAdminThunk,
  getAdmins: getAdminsThunk,
  getClients: getClientsThunk,
  updateAdmin: updateAdminThunk,
  removeAdmin: removeAdminThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["admin"])(Admins));
