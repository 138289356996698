import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import commonEN from '../src/locales/en/common.json';
import aboutEN from '../src/locales/en/about.json';
import adminEN from '../src/locales/en/admin.json';
import authEN from '../src/locales/en/auth.json';
import employmentEN from '../src/locales/en/employment.json';
import homeEN from '../src/locales/en/home.json';
import profileEN from '../src/locales/en/profile.json';
import serviceEN from '../src/locales/en/service.json';
import couponEN from '../src/locales/en/coupon.json';
import commonES from '../src/locales/es/common.json';
import aboutES from '../src/locales/es/about.json';
import adminES from '../src/locales/es/admin.json';
import authES from '../src/locales/es/auth.json';
import employmentES from '../src/locales/es/employment.json';
import homeES from '../src/locales/es/home.json';
import profileES from '../src/locales/es/profile.json';
import serviceES from '../src/locales/es/service.json';
import couponES from '../src/locales/es/coupon.json';

// const apiKey = "aRcFEdly9vWogq-lP_0BFA";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// i18next
//   .use(HttpBackend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     ns: ["common", "auth", "about", "admin", "home", "employment", "service", "profile", "coupon"],
//     defaultNS: "common",
//     supportedLngs: ["en","es"],
//     backend: {
//       loadPath: loadPath
//     }
//   })

const resources = {
  en: {
    common: commonEN,
    about: aboutEN,
    admin: adminEN,
    auth: authEN,
    employment: employmentEN,
    home: homeEN,
    profile: profileEN,
    service: serviceEN,
    coupon: couponEN,
  },
  es: {
    common: commonES,
    about: aboutES,
    admin: adminES,
    auth: authES,
    employment: employmentES,
    home: homeES,
    profile: profileES,
    service: serviceES,
    coupon: couponES,
  }
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })