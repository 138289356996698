import {
  getAdmins,
  createAd,
  updateAdmin,
  removeAdmin,
  getAbouts,
  getAbout,
  createAbout,
  updateAbout,
  removeAbout,
  getAds,
  removeAd,
  createCarousel,
  getCarousels,
  removeCarousel,
  inviteAdmin,
  acceptInvite
} from './apiCalls';
import {
  setAdmins,
  addAdmin,
  editAdmin,
  deleteAdmin,
  setAbouts,
  setAbout,
  addAbout,
  editAbout,
  deleteAbout,
  setAds,
  addAd,
  deleteAd,
  setCarousels,
  addCarousel,
  deleteCarousel,
  setLoadingStatus,
  setErrorMessage,
} from './actions';
import { setToast } from 'lib/toast';

export const getAdminsThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: admins } = await getAdmins();

    dispatch(setAdmins(admins));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const getAdsThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: ads } = await getAds(data);

    dispatch(setAds(ads));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const removeAdThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await removeAd(id);

    dispatch(deleteAd(id));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const getCarouselsThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: carousels } = await getCarousels(data);

    dispatch(setCarousels(carousels));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
    setToast('error', error.message);
  }
};

export const removeCarouselThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await removeCarousel(id);

    dispatch(deleteCarousel(id));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const inviteAdminThunk = (email, client = '') => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await inviteAdmin({
      email,
      client
    });

    dispatch(addAdmin({
      fullName: '',
      email,
      createdAt: new Date(),
      status: false,
    }));
    dispatch(setStatus(false, ''));

    return data.token;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return null;
};

export const acceptInviteThunk = (token) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await acceptInvite(token);

    dispatch(setStatus(false, ''));
    return {
      success: true,
      message: 'Your invitation has been accepted successfully.'
    };
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    return {
      success: false,
      message: error.response.data?.message
    }
  }
};

export const updateAdminThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const _id = data._id;
    delete data._id;
    await updateAdmin(_id, data);

    dispatch(editAdmin({...data, _id}));
    dispatch(setStatus(false, ''));
    setToast('success', "admin:msg_admin_updated");
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const removeAdminThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await removeAdmin(id);

    dispatch(deleteAdmin(id));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const uploadAdThunk = (file, ad) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const title = 'My file';
    const form = new FormData();
    form.append('title', title);
    form.append('file', file);
  
    const { data } = await createAd(form, ad);

    dispatch(addAd(data));
    dispatch(setStatus(false, ''));
    setToast('success', "admin:msg_ad_uploaded");
    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return false;
};

export const uploadCarouselThunk = (file, params) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const title = 'My file';
    const form = new FormData();
    form.append('title', title);
    form.append('file', file);
  
    const { data } = await createCarousel(form, params)

    dispatch(addCarousel(data));
    dispatch(setStatus(false, ''));
    setToast('success', "admin:msg_carousel_uploaded");
    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return false;
};

export const getAboutsThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: abouts } = await getAbouts();

    dispatch(setAbouts(abouts));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const getAboutThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: about } = await getAbout(id);

    dispatch(setAbout(about));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const createAboutThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: about } = await createAbout(data);

    dispatch(addAbout(about));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_aboutus_created");
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};


export const updateAboutThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const _id = data._id;
    delete data._id;
    await updateAbout(_id, data);

    dispatch(editAbout({...data, _id}));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_aboutus_updated");
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const removeAboutThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await removeAbout(id);

    dispatch(deleteAbout(id));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_aboutus_deleted");
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };

