//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getAccount = async (accountid) =>
  ApiRequest.request({
    method: 'GET',
    url: '/account',
    params: {
      accountid
    }
  });

export const putAccount = async (account) =>
  ApiRequest.request({
    method: 'PUT',
    url: '/account',
    data: {
      ...account
    }
  });

export const getShopper = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/shopper',
  });

export const putShopper = async (shopper) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/shopper`,
    data: {
      ...shopper,
    }
  });
