import React from 'react';
import styled from 'styled-components';
import GridLoader from 'react-spinners/GridLoader';


const Loader = (props) => {
  return (
    <DivContainer>
      <div className="react-loader">
        <GridLoader
          size={15}
          color={props.color}
          loading={props.loading || true}
        />
      </div>
    </DivContainer>
  );
}

export default Loader;

const DivContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: rgba(21,27,41,.4);

  .react-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`
