import React from "react";
import { connect } from 'react-redux';
import AboutUsImg from "assets/img/stores/1024.webp"
import { stateMappings } from '../../../redux/stateMappings';
import { getAboutThunk } from '../../../redux/admin/thunks';
import { LANGUAGES } from "../../../variables/constants";
import i18next from "i18next";
import { withTranslation } from 'react-i18next';

const AboutUs = ({ 
    configs, 
    about, 
    getAbout, 
    t,
  }) => {
  React.useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      <section
        className="mt-5 bg-cover w-full h-[150px] lg:h-[400px]"
        style={{ backgroundImage: `url(${AboutUsImg})`, backgroundPosition: 'bottom' }}
      >
        <div
          className="flex justify-center items-center w-full h-full"
          style={{ background: 'rgba(0,0,0,0.4)'}}
        >
          <div>
            <p className="md:pb-5 text-white text-center text-sm">{t("welcome_to")}</p>
            <p className="md:pb-5 text-white font-bold text-2xl md:text-5xl lg:text-7xl">{configs.ClientName}</p>
            <p className="text-white text-center md:text-2xl text-md">{t("you_are_in_good_company")}</p>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-[900px] mx-auto text-md text-gray-800 dark:text-white">
          <p className="mt-[50px]">
            {about[i18next.language]}
          </p>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    configs: sm.configs,
    about: sm.admin.about
  };
};

const mapActionsToProps = {
  getAbout: getAboutThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["about"])(AboutUs));

