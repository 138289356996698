//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const authenticate = async (credentials) =>
  ApiRequest.request({
    method: 'POST',
    url: `/login`,
    data: {
      ...credentials
    }
  });

export const register = async (user) =>
  ApiRequest.request({
    method: 'POST',
    url: `/register`,
    data: {
      ...user,
      SendEnrollmentEmails: true,
    }
  });

export const ResetPassword = async (email) =>
  ApiRequest.request({
    method: 'GET',
    url: `/reset-password`,
    params: {
      email
    }
  });

export const getStates = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/states',
  });

export const getStores = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/stores',
  });
