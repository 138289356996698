import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "components/header";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import Sidebar from "components/sidebar";
import { withRouter } from "lib/withRouter";
import PageNotFound from "views/default/page-not-found";
import { ROLE } from "variables/constants";

const Default = (props) => {
  const { isAuthenticated, isAdmin, location, ...rest } = props;
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes, isAdmin = false) => {
    return routes.map(({
      layout,
      path,
      component,
      authRequired,
      isLayout,
    }, key) => {
      if (layout === "" || (layout === ROLE.SHOPPER && isAuthenticated) || (layout === ROLE.ADMIN && isAdmin)) {
        return (
          <Route
            key={`shopper-route-${key}`}
            path={`${layout}/${path}${isLayout ? '/*' : ''}`}
            element={(authRequired && !isAuthenticated) ? <Navigate to="/auth/sign-in" replace /> : component}
          />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full">
      {/* Header & Main Content */}
      <div className={`h-full w-full flex-none transition-all${isAuthenticated ? ' xl:w-[calc(100%-262px)] xl:ml-[262px] ' : ''}bg-lightPrimary dark:!bg-navy-900`}>
        <Header
          {...rest}
        />
        {/* Main Content */}
        <main>
          {isAuthenticated && <Sidebar isAdmin={isAdmin} open={open} onClose={() => setOpen(false)} />}
          {/* Routes */}
          <div className={`mx-auto ${location.pathname === '/' ? '' : 'px-5 max-w-[1380px]'}`}>
            <div className="h-full">
              <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh]">
                <Routes>
                  {getRoutes(routes, isAdmin)}
                  <Route
                    path="*"
                    element={<PageNotFound />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                <Footer />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default withRouter(Default);