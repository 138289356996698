import {
  SET_ACCOUNT,
  EDIT_ACCOUNT,
  SET_SHOPPER,
  EDIT_SHOPPER,
  INITILA_USER,
  SET_THEME_MODE,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const defaultState = {
  account: {},
  shopper: {},
  admin: {},
  theme: 'light',
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, account: action.payload };

    case EDIT_ACCOUNT:
      return { ...state, account: action.payload };

    case SET_SHOPPER:
      return { ...state, shopper: action.payload.shopper, admin: action.payload.admin };

    case INITILA_USER:
      return { ...state, shopper: {}, account: {} };

    case EDIT_SHOPPER:
      return { ...state, shopper: action.payload };

    case SET_THEME_MODE:
    return { ...state, theme: action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  };
};

export default reducer;
