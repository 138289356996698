import { carousel } from '@material-tailwind/react';
import {
  SET_ADMINS,
  ADD_ADMIN,
  EDIT_ADMIN,
  DELETE_ADMIN,
  SET_ABOUTS,
  SET_ABOUT,
  ADD_ABOUT,
  EDIT_ABOUT,
  DELETE_ABOUT,
  SET_ADS,
  ADD_AD,
  DELETE_AD,
  SET_CAROUSELS,
  ADD_CAROUSEL,
  DELETE_CAROUSEL,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';
import aboutUs from 'views/default/about-us';

const defaultState = {
  admins: [],
  ads: [],
  pointSpecials: [],
  carousels: [],
  abouts: [],
  about: {},
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_ADMINS:
      return { ...state, admins: action.payload };

    case ADD_ADMIN:
      const admin = state.admins.find(admin => admin.email === action.payload.email);
      
      if (!!admin) {
        return state;
      }

      return { ...state, admins: [ ...state.admins, action.payload ] };
    
    case EDIT_ADMIN:
      return { ...state, admins: state.admins.map(admin => {
        if (admin._id !== action.payload._id) {
          return admin;
        }

        return action.payload;
      })};

    case DELETE_ADMIN:
      return {
        ...state,
        admins: state.admins.filter(admin => admin._id !== action.payload)
      };

    case SET_ABOUTS:
      return { ...state, abouts: action.payload };

    case SET_ABOUT:
      return { ...state, about: action.payload };

    case ADD_ABOUT:
      return { ...state, abouts: [ ...state.abouts, action.payload ] };
    
    case EDIT_ABOUT:
      return { ...state, abouts: state.abouts.map(about => {
        if (about._id !== action.payload._id) {
          return about;
        }
        return action.payload;
      })};

    case DELETE_ABOUT:
      return { ...state, abouts: state.abouts.filter(about => about._id !== action.payload) };

    case SET_ADS:
      return { ...state, ads: action.payload };

    case ADD_AD:
      return { ...state, ads: [ ...state.ads, action.payload ] };

    case DELETE_AD:
      return { ...state, ads: state.ads.filter(ad => ad._id !== action.payload) };

    case SET_CAROUSELS:
      return { ...state, carousels: action.payload };

    case ADD_CAROUSEL:
      return { ...state, carousels: [ ...state.carousels, action.payload ] };

    case DELETE_CAROUSEL:
      return { ...state, carousels: state.carousels.filter(ad => ad._id !== action.payload) };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
