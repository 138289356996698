/* provides a mapping to each nested (or not) endPoints so we can change
   the Redux store without having to change every connected component */
// import config from '../config';

export const stateMappings = (reduxTree) => {
  const {
    auth,
    balance,
    card,
    client,
    coupon,
    point,
    user,
    admin,
    application
  } = reduxTree;

  const { shopper: { token, accountid, shopperid, isAdmin }, loading } = auth;

  return {
    ...reduxTree,
    configs: client.configs,
    isAuthenticated: !!token,
    shopperid,
    accountid,
    isAdmin,
    account: user.account,
    shopper: user.shopper,
    specials: point.specials || [],
    stores: auth.stores || [],
    states: auth.states || [],
    loading: loading
      || balance.loading
      || card.loading
      || client.loading
      || coupon.loading
      || coupon.available_loading
      || point.loading
      || user.loading
      || admin.loading
      || application.loading
  };
};
