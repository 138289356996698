import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

const MiniCalendar = ({ className, value, onChange, single }) => {
  return (
    <div className={className || ''}>
      <Calendar
        value={value}
        onChange={onChange}
        prevLabel={<MdChevronLeft className="ml-1 h-6 w-6" />}
        nextLabel={<MdChevronRight className="ml-1 h-6 w-6" />}
        view={"month"}
        selectRange={!single}
      />
    </div>
  );
};

export default MiniCalendar;
