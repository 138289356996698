import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import Card from "components/card";
import CouponCard from "../coupons/components/CouponCard";
import CouponDetails from "../coupons/components/CouponDetails";
import { withTranslation } from "react-i18next";

const HomeCoupons = ({ coupons, isAuthenticated, balance, clip, getBalance, color, t }) => {
  const [activeCoupon, setActiveCoupon] = useState({});
  const [showModal, setShowModal] = useState(false);

  const openCpDetailsModal = () => {
    setShowModal(!showModal);
  }

  const viewDetails = (coupon) => {
    const active_coupon = {
      ...coupon,
      clipped: coupon.clipped || !clip
    };

    setActiveCoupon(active_coupon);
    openCpDetailsModal();
  }
  
  const clipCoupon = async (coupon, mode = 'coupon_list') => {
    if (coupon.PointCost > balance) return;

    try {
      const success = await clip([coupon])
      if (!success) return;

      if (balance > 0) {
        await getBalance();
      }

      const active_coupon = {
        ...coupon,
        clipped: true
      };
  
      setActiveCoupon(active_coupon);

      if (mode === 'coupon_list') {
        openCpDetailsModal()
      }
    } catch { }
  }

  return (
    <>
      <CouponDetails
        header={t("common:coupon")+" "+t("common:details")}
        open={showModal}
        coupon={activeCoupon}
        openCpDetailsModal={openCpDetailsModal}
        clip={clipCoupon}
        balance={balance}
        isAuthenticated={isAuthenticated}
        color={color}
      />

      <div className="mx-auto max-w-[1380px] rounded-[30px] px-3 py-3 md:px-8">
        <div className="flex justify-end">
          <Link
            to={`coupons`}
            className={`bg-transparent theme-bg-color-hover theme-tx-color font-semibold hover:text-white py-2 px-5 border theme-br-color hover:border-transparent rounded dark:hover:bg-white dark:text-white dark:border-white dark:hover:text-navy-900`}
          >
            {t("common:view_all")}
          </Link>
        </div>

        <div className="py-5 flex gap-3 overflow-x-auto">
          <Card
            className="flex flex-col w-full h-auto !bg-brand-50 border-1 border-brand-100 hover:shadow-2xl min-w-[250px] p-3 dark:!bg-navy-700 dark:border-navy-800"
          >
            <h5 className="mt-[50px] text-2xl text-center dark:text-white"><strong>{t("common:coupons")}</strong></h5>
            <p className="mt-5 text-center dark:text-white">{t("saving_is_easier_with_digital_coupons")}</p>
            {!isAuthenticated &&
              <Link
                to="auth/sign-in"
                className="mt-[50px] text-center"
              >
                <Button
                  variant="gradient"
                  color={color}
                >
                  {t("auth:sign_in")}
                </Button>
              </Link>
            }
          </Card>
          {coupons.map((coupon, index) => (
            <CouponCard
              key={`coupon-card-${index}`}
              className="min-w-[200px] !h-auto"
              { ...coupon }
              clip={clip ? clipCoupon : undefined}
              viewDetails={viewDetails}
              balance={balance}
              isAuthenticated={isAuthenticated}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default withTranslation(["home"])(HomeCoupons);
