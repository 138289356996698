
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { VscLocation } from "react-icons/vsc";
import Card from "components/card";
import { StoreNameComp } from './StoreNameComp';

const STORE_POSITIONS = {
  1: {
    lat: 40.1109413,
    lng: -83.7525035
  },
  2: {
    lat: 40.2229802,
    lng: -83.5531217
  }
}

const MapCard = ({ stores, selectedStore }) => {
  return (
    <Card className={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold theme-tx-color dark:text-white">
          Google Map
        </h4>
      </div>

      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao" }}
          defaultCenter={STORE_POSITIONS[selectedStore || 1]}
          defaultZoom={9}
        >
          {stores.map(({ ID, StoreName }, index) =>
            <StoreNameComp
              key={`google-store-${index}`}
              text={StoreName}
              selected={selectedStore === ID}
            />
          )}
        </GoogleMapReact>
      </div>
    </Card>
  );
};

export default MapCard;
