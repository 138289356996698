import React from "react";
import { connect } from 'react-redux';
import Card from "components/card";
import { stateMappings } from "../../../redux/stateMappings";
import { getAdsThunk } from "../../../redux/admin/thunks";
import config from "../../../config";
import { withTranslation } from "react-i18next";

const WeeklyAds = ({
  ads,
  getAds,
  t,
}) => {
  React.useEffect(() => {
    getAds({ date: new Date(), isAd: true });
  }, []);

  return (
    <>
      <h4 className="mt-5 mb-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        {t("common:our_current_ads")}
      </h4>
      <Card className={"w-full h-full mt-5 py-5 px-3"}>
        <div>
          {ads.map((ad, index) =>
            <img
              key={`weekly-ad-${index}`}
              className="w-full"
              src={`${config.api_url}/ad-post/images/${ad.fileName}`}
              alt=""
            />
          )}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    ads: sm.admin.ads || []
  };
};

const mapActionsToProps = {
  getAds: getAdsThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation()(WeeklyAds));

