import {
  SET_CLIENT_CONFIGS,
  SET_CLIENTS,
  SET_CLIENT,
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE
} from './actionEnum';

export const setClientConfigs = (configs) => ({
  type: SET_CLIENT_CONFIGS,
  payload: configs,
});

export const setClients = (clients) => ({
  type: SET_CLIENTS,
  payload: clients,
});

export const setClient = (client) => ({
  type: SET_CLIENT,
  payload: client,
});

export const addClient = (client) => ({
  type: ADD_CLIENT,
  payload: client,
});

export const editClient = (client) => ({
  type: EDIT_CLIENT,
  payload: client,
});

export const removeClient = (client) => ({
  type: DELETE_CLIENT,
  payload: client,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});
