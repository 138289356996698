import { ToastsStore } from 'react-toasts';
import { FaCheckCircle } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import i18next from 'i18next';

const TOAST_ICONS = {
    success: <FaCheckCircle className="h-5 w-5" />,
    info: <FaInfoCircle className="h-5 w-5" />,
    warning: <IoIosWarning className="h-5 w-5" />,
    error: <MdError className="h-5 w-5" />,
}

export const setToast = (type = 'success', msg = '') => {
    if (!msg) {
        return;
    }

    const t = i18next.t;

    const msgElement = (
        <div className="flex justify-center items-center gap-2">
            {TOAST_ICONS[type]}
            <strong>{t(msg)}</strong>
        </div>
    );

    ToastsStore[type](msgElement, 3000);
}
