import {
  getSpecials
} from './apiCalls';
import {
  setSpecials,
  setLoadingStatus,
  setErrorMessage,
} from './actions';
import { setToast } from 'lib/toast';

export const getSpecialsThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getSpecials();
    const { data: specials, result } = data || {};

    if (specials && result === "OK") {
      dispatch(setSpecials(specials));
    }
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
