import {
  getAvailableCoupons,
  getClippedCoupons,
  postClippedCoupons,
} from './apiCalls';
import {
  setAvailableCoupons,
  setClippedCoupons,
  addClippedCoupons,
  setErrorMessage,
  setAvailableLoadingStatus,
  setLoadingStatus,
} from './actions';
import { setToast } from '../../lib/toast';


export const getAvailableCouponsThunk = (accountid) => async (
  dispatch,
) => {
  dispatch(setStatus(true));
  dispatch(setAvailableLoadingStatus(true));

  try {
    const { data } = await getAvailableCoupons(accountid);
    const { data: couponsData, result } = data || {};
    const { coupons } = couponsData || {};

    if (coupons && result === "OK") {
      dispatch(setAvailableCoupons(coupons));
    }

    dispatch(setStatus(false));
    dispatch(setAvailableLoadingStatus(false));
  } catch (error) {
    dispatch(setStatus(false));
    dispatch(setAvailableLoadingStatus(false));
    // setToast('error', error.response.data?.message);
  }
};

export const getClippedCouponsThunk = (accountid) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getClippedCoupons(accountid);
    const { data: couponsData, result } = data || {};
    const { coupons } = couponsData || {};

    if (coupons && result === "OK") {
      dispatch(setClippedCoupons(coupons));
    }

    dispatch(setStatus(false, ''));

    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    // setToast('error', error.response.data?.message);
  }

  return false;
};

export const addClippedCouponsThunk = (coupons = []) => async (
  dispatch,
) => {
  let isClipped = false;
  dispatch(setStatus(true, ''));

  try {
    const { data } = await postClippedCoupons(coupons.map(coupon => coupon.id));
    const { result } = data || {};

    if (result === "OK") {
      dispatch(addClippedCoupons(coupons));
      setToast('success', 'coupon:msg_coupon_clipped');
      isClipped = true;
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return isClipped;
};

const setStatus = (status = true, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
