import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { thunk } from 'redux-thunk'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from "./redux/combineReducers";
import "./index.css";
import "./i18n.js";
import App from "./App";

const store = createStore(rootReducer, applyMiddleware(thunk))
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

