//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getEmploymnets = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/applications',
  });

export const postApplication = async (application) =>
  ApiRequest.request({
    method: 'POST',
    url: '/application',
    data: {
      ...application
    }
  });

export const putApplication = async (application) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/application/${application._id}`,
    data: {
      ...application
    }
  });

export const deleteApplication = async (appId) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/application/${appId}`,
  });

