import {
  SET_SPECIALS,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE
} from './actionEnum';

export const setSpecials = (specials) => ({
  type: SET_SPECIALS,
  payload: specials,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});
