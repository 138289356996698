import React from "react";
import Shopper from "./Shopper";
import Client from "./Client";
import MyStore from "./MyStore";
import Appearance from "./Appearance";
import { withTranslation } from "react-i18next";

const Profile = ({ t }) => {
  return (
    <>
      <h4 className="mt-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        {t("Account Profile")}
      </h4>
      <p className="mb-5 text-center text-sm text-gray-400">
        {t("Personal information related to your account")}
      </p>

      <Shopper
        className="w-full py-[32px] px-[16px] mb-5"
      />
      <Client
        className="w-full py-[32px] px-[16px] mb-5"
      />
      <Appearance
        className="w-full py-[32px] px-[16px] mb-5"
      />
      <MyStore
        className="w-full py-[32px] px-[16px] mb-5"
      />
    </>
  );
};

export default withTranslation(["profile"])(Profile);
