import Card from "components/card";
import { BsCheckAll } from "react-icons/bs";
import { services } from "./variables/services";
import { withTranslation } from "react-i18next";

const InStoreServices = ({props, t}) => {
  return (
    <>
      <h4 className="mt-5 mb-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        {t("Our Services")}
      </h4>
      <Card className={"w-full h-full py-5 px-3"}>

        <div className="flex justify-center">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
            {services.map((service, index) =>
              <div
                key={`our-service-${index}`}
                className="mt-3 flex items-center gap-3 text-white theme-bg-color rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:text-white"
              >
                <BsCheckAll className="w-6 h-6" />
                <label
                  className="text-base font-medium"
                >
                  {t(service.Header)}
                </label>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default withTranslation(["service"])(InStoreServices);
