import React, { Component } from "react";
import { connect } from 'react-redux';
import { HexColorPicker } from "react-colorful";
import { Input, Button, Select, Option, Switch } from "@material-tailwind/react";
import { DEFAULT_COLORS } from "material-tailwind-themes";
import { THEME_COLOR, COL_ACCESS } from "variables/constants";
import { stateMappings } from "../../../redux/stateMappings";
import { getClientsThunk, createClientThunk, removeClientThunk, updateClientThunk, } from "../../../redux/client/thunks";
import Card from "components/card";
import ClientTable from "./components/ClientTable";
import Dropdown from "components/dropdown";
import { setToast } from "lib/toast";
import { withTranslation } from "react-i18next";

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      username: '',
      password: '',
      confirmPassword: '',
      txColor: '',
      bgColor: '',
      brColor: '',
      isRoot: false,
      openPs: false,
    };
  }

  async componentDidMount() {
    await this.props.getClients();
  }

  onChange = ({ target: { name, value, type, checked } }) => {
    this.setState({
      ...this.state,
      [name]: type !== 'checkbox' ? value : checked,
    });
  }

  onChangeSelectItem = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  openDetails = (data) => {
    const { _id, username, txColor, bgColor, brColor, isRoot, openPs } = data;
    this.setState({
      _id,
      username,
      txColor,
      bgColor,
      brColor,
      isRoot,
      openPs
    });
  }

  clearDetails = () => {
    this.setState({
      _id: '',
      username: '',
      password: '',
      confirmPassword: '',
      txColor: '',
      bgColor: '',
      brColor: '',
      isRoot: false,
      openPs: false,
    });
  }

  validate = () => {
    let {
      _id,
      username,
      password,
      confirmPassword,
    } = this.state;

    const { t } = this.props;

    if (!username) {
      setToast('error', t("Client username is required!"));
      return false
    }

    if (password) {
      if (password.length < 4) {
        this.setState({
          states: {
            ...this.state.states,
            password: 'error'
          }
        }, () => {
          setToast('error', t("auth:msg_pwd_validation"));
        })

        return false
      }
    } else {
      if (!_id) {
        setToast('error', t("Client password is required!"));
        return false
      }
    }

    if (password && (password !== confirmPassword)) {
      this.setState({
        states: {
          ...this.state.states,
          confirmPassword: 'error'
        }
      }, () => {
        setToast('error', t("auth:msg_pwd_confirm_not_match"));
      })

      return false
    }

    return true
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) {
      return
    }

    let {
      _id,
      username,
      txColor,
      bgColor,
      brColor,
      password,
      isRoot,
      openPs,
    } = this.state;

    let {
      createClient,
      updateClient,
    } = this.props

    let data = {
      username,
      txColor,
      bgColor,
      brColor,
      isRoot,
      openPs,
    };
    if (password) data = {
      ...data,
      password,
    }
    if (_id) {
      data = {
        ...data,
        _id
      };  
      updateClient(data);
    } else {
      createClient(data);
    }
  };

  render() {
    const {
      username,
      txColor,
      bgColor,
      brColor,
      password,
      confirmPassword,
      isRoot, 
      openPs,
    } = this.state;
    const { client, clients, removeClient, t } = this.props;
    const columnsDataCheck = [
      {
        Header: t("admin:name").toUpperCase(),
        accessor: COL_ACCESS.USERNAME,
      },
      {
        Header: t("admin:text").toUpperCase(),
        accessor: COL_ACCESS.TX_COLOR,
      },
      {
        Header: t("admin:background").toUpperCase(),
        accessor: COL_ACCESS.BG_COLOR,
      },
      {
        Header: t("admin:border").toUpperCase(),
        accessor: COL_ACCESS.BR_COLOR,
      },
      {
        Header: t("admin:is_root").toUpperCase(),
        accessor: COL_ACCESS.IS_ROOT,
      },
      {
        Header: t("admin:open_ps").toUpperCase(),
        accessor: COL_ACCESS.OPEN_PS,
      },
      {
        Header: t("admin:action").toUpperCase(),
        accessor: COL_ACCESS.ACTION,
      },
    ];

    return (
      <>
        <Card className="my-5 p-4">
          <h5 className="text-center font-bold theme-tx-color text-xl mb-5 md:mb-8">{t("Client Information")}</h5>
          <form
            className="flex w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10"
            onSubmit={this.handleSubmit}
          >
            <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0">
              <div className="grid grid-cols-1 gap-3">
                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("auth:username")}
                    name="username"
                    placeholder={t("auth:username")}
                    onChange={this.onChange}
                    value={username}
                    required
                    color={client?.brColor || THEME_COLOR.brColor}
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div className="mb-4">
                  <Dropdown
                    button={
                      <Input
                        name="txColor"
                        variant="standard"
                        label={t("admin:theme")+" "+t("admin:foreground")}
                        placeholder="#FFFFFF"
                        type="text"
                        onChange={this.onChange}
                        value={txColor}
                        readOnly
                        required
                        color={client?.brColor || THEME_COLOR.brColor}
                      />
                    }
                    children={
                      <HexColorPicker color={txColor} onChange={(newColor) => this.onChange({ target: { name: "txColor", value: newColor } })} />
                    }
                    classNames={"z-51 left-[80px]"}
                  />
                </div>
                <div className="mb-4">
                  <Dropdown
                    button={
                      <Input
                        name="bgColor"
                        variant="standard"
                        label={t("admin:theme")+" "+t("admin:background")}
                        placeholder="#FFFFFF"
                        type="text"
                        onChange={this.onChange}
                        value={bgColor}
                        readOnly
                        required
                        color={client?.brColor || THEME_COLOR.brColor}
                      />
                    }
                    children={
                      <HexColorPicker color={bgColor} onChange={(newColor) => this.onChange({ target: { name: "bgColor", value: newColor } })} />
                    }
                    classNames={"z-51 left-[80px]"}
                  />
                </div>
                <div className="mb-4">
                  {(DEFAULT_COLORS || []).length > 0 &&
                    <Select
                      variant="standard"
                      label={t("admin:theme")+" "+t("admin:border")}
                      onChange={(data) => this.onChangeSelectItem("brColor", data)}
                      value={brColor}
                      color={client?.brColor || THEME_COLOR.brColor}
                    >
                      {(DEFAULT_COLORS || []).map((name, index)=>
                        <Option key={`option-color-${index}`} value={name}>{name.toUpperCase()}</Option>
                      )}
                    </Select>
                  }
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="mb-4">
                  <Switch
                    name="isRoot"
                    label={t("admin:is_root")}
                    color={client?.brColor || THEME_COLOR.BORDER}
                    checked={isRoot}
                    onChange={this.onChange}
                  />
                </div>
                <div className="mb-4">
                  <Switch
                    name="openPs"
                    label={t("admin:open_ps")}
                    color={client?.brColor || THEME_COLOR.BORDER}
                    checked={openPs}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <h4 className="mb-5 mt-8 text-lg font-bold text-navy-700 dark:text-white md:flex leading-5">
                {t("auth:change_pwd")}
                <small className="ml-2 font-medium">({t("Leave_blank_if_you_want_to_keep_your_current_password")})</small>
              </h4>

              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mb-5">
                <Input
                  variant="standard"
                  label={t("auth:password")}
                  name="password"
                  placeholder={t("auth:password")}
                  onChange={this.onChange}
                  value={password}
                  color={client?.brColor || THEME_COLOR.brColor}
                  type="password"
                  autoComplete="new-password"
                />

                <Input
                  variant="standard"
                  label={t("auth:confirm_pwd")}
                  name="confirmPassword"
                  placeholder={t("auth:confirm_pwd")}
                  onChange={this.onChange}
                  value={confirmPassword}
                  color={client?.brColor || THEME_COLOR.brColor}
                  type="password"
                  autoComplete="confirm-password"
                />
              </div>

              <div className="flex justify-center">
                <Button
                  variant="outlined"
                  onClick={this.clearDetails}
                  className="mr-3"
                  color={client?.brColor || THEME_COLOR.brColor}
                >
                  <span>{t("common:clear_all")}</span>
                </Button>
                <Button
                  variant="gradient"
                  type="submit"
                  color={client?.brColor || THEME_COLOR.brColor}
                >
                  {t("common:save")}
                </Button>
              </div>
            </div>
          </form>
        </Card>
        <ClientTable
          columnsData={columnsDataCheck}
          tableData={clients}
          remove={removeClient}
          openDetails={this.openDetails}
          clearDetails={this.clearDetails}
          color={client?.brColor || THEME_COLOR.BORDER}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    client: sm.client.client || {},
    clients: sm.client.clients || [],
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  getClients: getClientsThunk,
  createClient: createClientThunk,
  updateClient: updateClientThunk,
  removeClient: removeClientThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["profile"])(Clients));