import {
  SET_BALANCE,
  SET_OFFER,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';


const defaultState = {
  balances: [],
  offer_balances: [],
  targeted: [],
  untargeted: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_BALANCE:
      return { ...state, ...action.payload };

    case SET_OFFER:
      return { ...state, ...action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  };
};

export default reducer;
