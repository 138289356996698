//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';


export const getCard = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/card',
  });

export const putCard = async (card) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/card`,
    data: {
      ...card,
    }
  });

