import React from "react";
import { BiCut } from "react-icons/bi";
import { VscLocation } from "react-icons/vsc";
import { TbPointerDollar, TbUserCog } from "react-icons/tb";
import { MdAdminPanelSettings, MdOutlineViewCarousel, MdStore } from "react-icons/md";
import { FaAdversal, FaQuestionCircle } from "react-icons/fa";
import { FaFilePowerpoint } from "react-icons/fa6";
import { ImProfile } from "react-icons/im";

import HOME from "views/default/home";
import AboutUs from "views/default/about-us";
import Employment from "views/default/employment";
import InStoreServices from "views/default/in-store-services";
import WeeklyAds from "views/default/weekly-ads";
import FAQ from "views/default/faq";
import Stores from "views/default/stores";
import Coupons from "views/default/coupons";
import MyAccount from "views/default/my-account";
import PointSpecials from "views/default/point-specials";
import GreatePointSpecials from "views/default/point-specials/great";
import AcceptInvite from "views/default/invite";
import Admins from "views/default/admins/Admins";
import Clients from "views/default/admins/Clients";
import Applications from "views/default/admins/Applications";
import Abouts from "views/default/admins/Abouts";
import AdUploader from "views/default/admins/AdUploader";
import SpecialsUploader from "views/default/admins/SpecialsUploader";
import CarouselUploader from "views/default/admins/CarouselUploader";

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import ForgotPassword from "views/auth/ForgotPassword";

import { ROLE } from "variables/constants";

const routes = [
  {
    name: "common:home",
    layout: "",
    path: "",
    component: <HOME />,
    primary: true
  },
  {
    name: "auth:sign_in",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "auth:sign_up",
    layout: "/auth",
    path: "sign-up",
    component: <SignUp />,
  },
  {
    name: "auth:forgot_password",
    layout: "/auth",
    path: "forgot-password",
    component: <ForgotPassword />,
    disable: true
  },
  {
    name: "common:about_us",
    layout: "",
    path: "about-us",
    component: <AboutUs />,
    primary: true
  },
  {
    name: "common:rewards_faq",
    layout: "",
    path: "rewards-faq",
    component: <FAQ />,
  },
  {
    name: "common:employment",
    layout: "",
    path: "employment",
    component: <Employment />,
    primary: true
  },
  {
    name: "common:in_store_services",
    layout: "",
    path: "in-store-services",
    component: <InStoreServices />,
    primary: true
  },
  {
    name: "common:weekly_ads",
    layout: "",
    path: "weekly-ads",
    // href: "http://stevesmarketsrewards.loyaltylane.com/current_ad.shtml",
    component: <WeeklyAds />,
    primary: true
  },
  {
    name: "common:my_account",
    layout: "",
    path: "my-account",
    component: <MyAccount />,
    icon: (
      <TbUserCog className="w-4 h-4" />
    ),
    authRequired: true,
    dropdown: true,
    isLayout: true,
    sidebar: true,
  },
  {
    name: "common:select_store",
    getName: ({ store }) => {
      return store?.StoreName || 'Select Store'
    }, 
    layout: "",
    path: "stores",
    component: <Stores />,
    icon: (
      <div className="theme-bg-color text-white rounded-[50%] p-[3px]">
        <VscLocation className="w-4 h-4" />
      </div>
    ),
    secondary: true
  },
  {
    name: "common:point_specials",
    layout: "",
    path: "point-specials",
    component: <PointSpecials />,
    icon: (
      <TbPointerDollar className="theme-tx-color w-5 h-5" />
    ),
    secondary: true,
    authRequired: false
  },
  {
    name: "common:great_point_specials",
    layout: "",
    path: "great-point-specials",
    component: <GreatePointSpecials />,
    icon: (
      <FaFilePowerpoint className="w-5 h-5" />
    ),
    secondary: false,
    authRequired: false
  },
  {
    name: "common:digital_coupons",
    getName: ({ isAuthenticated, clippeds }) => {
      return (
        <>
          Coupons
          {isAuthenticated && <strong>{` ${clippeds.length} clipped`}</strong>}
        </>
      )
    }, 
    layout: "",
    path: "coupons",
    component: <Coupons />,
    icon: (
      <BiCut className="theme-tx-color w-5 h-5" />
    ),
    secondary: true,
    authRequired: false
  },
  {
    name: "admin:admins",
    layout: ROLE.ADMIN,
    path: "manage-admins",
    component: <Admins />,
    icon: (
      <MdAdminPanelSettings className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "common:ads",
    layout: ROLE.SHOPPER,
    path: "manage-ads",
    component: <AdUploader />,
    icon: (
      <FaAdversal className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "common:point_specials",
    layout: ROLE.SHOPPER,
    path: "manage-ps",
    component: <SpecialsUploader />,
    icon: (
      <FaFilePowerpoint className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "admin:carousels",
    layout: ROLE.SHOPPER,
    path: "manage-carousel",
    component: <CarouselUploader />,
    icon: (
      <MdOutlineViewCarousel className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "admin:applications",
    layout: ROLE.ADMIN,
    path: "manage-apps",
    component: <Applications />,
    icon: (
      <ImProfile className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "admin:clients",
    layout: ROLE.ADMIN,
    path: "manage-clients",
    component: <Clients />,
    icon: (
      <MdStore className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "admin:aboutus",
    layout: ROLE.SHOPPER,
    path: "manage-aboutus",
    component: <Abouts />,
    icon: (
      <FaQuestionCircle className="w-5 h-5" />
    ),
    sidebar: true,
    authRequired: true
  },
  {
    name: "common:accept_invite",
    layout: "",
    path: "invite/:token",
    component: <AcceptInvite />,
    icon: (
      <MdAdminPanelSettings className="w-5 h-5" />
    ),
    disable: true
  },
];
export default routes;
