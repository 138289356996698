import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from "@material-tailwind/react";
import { stateMappings } from "../../../redux/stateMappings";
import { getAdsThunk } from "../../../redux/admin/thunks";
import Card from "components/card";
import config from "../../../config";
import { THEME_COLOR } from "variables/constants";
import { useTranslation } from "react-i18next";

const PointSpecials = ({ getAds, ads, client }) => {
  React.useEffect(() => {
    getAds({ date: new Date(), isAd: false })
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <h4 className="mt-5 mb-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        {t("common:point_specials")}
      </h4>

      {client?.openPs &&
        <div className="flex justify-end">
          <Link to="/great-point-specials">
            <Button 
            variant="text" 
            color={client?.brColor || THEME_COLOR.BORDER}
            className="flex items-center gap-2 dark:text-white">
              {t("common:great_point_specials")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Button>
          </Link>
        </div>
      }

      <Card className={"w-full h-full mt-5 py-5 px-3"}>
        <div>
          {ads.map((ad, index) =>
            <img
              key={`weekly-ad-${index}`}
              className="w-full"
              src={`${config.api_url}/ad-post/images/${ad.fileName}`}
              alt=""
            />
          )}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    ads: sm.admin.ads || [],
    client: sm.client.client || {}
  };
};

const mapActionsToProps = {
  getAds: getAdsThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PointSpecials);

