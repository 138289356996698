import {
  SET_CLIENT_CONFIGS,
  SET_CLIENTS,
  SET_CLIENT,
  ADD_CLIENT,
  EDIT_CLIENT,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  DELETE_CLIENT,
} from './actionEnum';

const defaultState = {
  configs: [],
  clients: [],
  client: {},
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_CLIENT_CONFIGS:
      return { ...state, configs: action.payload };

    case SET_CLIENTS:
      return { ...state, clients: action.payload };

    case SET_CLIENT:
      return { ...state, client: action.payload };

    case ADD_CLIENT:
      const client = state.clients.find(client => client.username === action.payload.username);
      
      if (!!client) {
        return state;
      }

      return { ...state, clients: [ ...state.clients, action.payload ] };

    case EDIT_CLIENT:
      return { ...state, clients: state.clients.map(client => {
        if (client._id !== action.payload._id) {
          return client;
        }
        return action.payload;
      })};

    case DELETE_CLIENT:
      return { ...state, clients: state.clients.filter(client => client._id !== action.payload) };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
