export const LANGUAGES = [
    { en: "English", es: "Inglesa", code: "en", locale: "en-US"},
    { en: "Spanish", es: "Española", code: "es", locale: "es" }
];

export const THEME_COLOR = {
    TEXT: "#000000",
    BACKGROUND: "#ffffff",
    BORDER: "red",
};

export const THEME_MODE = {
    LIGHT: "light",
    DARK: "dark"
}

export const COUPON_STATUS = {
    AVAILABLE: "available",
    CLIPPED: "clipped"
}

export const BALANCE_TYPE = {
    POINTS: "points"
}

export const SORT_ITEMS = {
    RELEVANT: "relevant",
    NEWEST: "newest",
    VAL_LOWEST: "value_lowest",
    VAU_HIGHEST: "value_highest",
    EXPIRATION: "expiration",
    POINTS_LOWEST: "PointsSpent_Lowest",
    POINTS_HIGHEST: "PointsSpent_Highest",
    START_DATE: "StartDate",
    END_DATE: "EndDate",
}

export const ITEMS_PER_PAGE = 15;
export const ROWS_PER_PAGE = ["5", "10", "20", "50", "100"];

export const COL_ACCESS = {
    NAME: "fullName",
    EMAIL: "email",
    STATUS: "status",
    ROLE: "role",
    DATE: "createdAt",
    PROGRESS: "progress",
    ACTION: "_id",
    FILE: "fileName",
    START: "start",
    END: "end",
    HEADING: "heading",
    TITLE: "title",
    TEXT: "text",
    BACKGROUND: "background",
    USERNAME: "username",
    BG_COLOR: "bgColor",
    BR_COLOR: "brColor",
    TX_COLOR: "txColor",
    IS_ROOT: "isRoot",
    OPEN_PS: "openPs",
    CLIENT: "client",
    EN: "en",
    ES: "es"
}

export const APP_STATUS = {
    INREVIEW: "In review",
    ACCEPTED: "Accepted",
    REJECTED: "Rejected"
}

export const ROLE = {
    ADMIN: "admin",
    SHOPPER: "shopper"
}

export const OK = "OK";