//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';


export const getAvailableCoupons = async ( accountid ) =>
  ApiRequest.request({
    method: 'GET',
    url: '/coupons/available',
    params: {
      accountid
    }
  });

export const postClippedCoupons = async ( couponList, accountid ) =>
  ApiRequest.request({
    method: 'POST',
    url: '/coupons/clipped',
    data: accountid ? {
      couponidlist: couponList,
      accountid,
    } : {
      couponidlist: couponList,
    },
  });

export const getClippedCoupons = async ( accountid ) =>
  ApiRequest.request({
    method: 'GET',
    url: '/coupons/clipped',
    params: {
      accountid
    }
  });
