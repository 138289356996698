//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getClientConfigs = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/configs`,
  });

export const getClients = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/clients`,
  });

export const getClient = async (id) =>
  ApiRequest.request({
    method: 'GET',
    url: id ? `/client/${id}` : `/client`,
  });

export const createClient = async (data) =>
  ApiRequest.request({
    method: 'POST',
    url: '/client',
    data
  });

export const updateClient = async (id, data) =>
  ApiRequest.request({
    method: 'PUT',
    url: id ? `/client/${id}` : `/client`,
    data
  });

export const deleteClient = async (id) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/client/${id}`,
  });