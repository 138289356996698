import React, { useState } from "react";
import { connect } from 'react-redux';
import { stateMappings } from "../../../../redux/stateMappings";
import { getClientsThunk, updateClientThunk } from "../../../../redux/client/thunks";
import { getAdsThunk, removeAdThunk, uploadAdThunk } from "../../../../redux/admin/thunks";
import { Button, Select, Option, Switch } from "@material-tailwind/react";
import MiniCalendar from "components/calendar/MiniCalendar";
import FileUploadInput from "./FileUploadInput";
import AdTable from "./AdTable";
import { setToast } from "lib/toast";
import { THEME_COLOR } from "variables/constants";
import { withTranslation } from "react-i18next";

const FileUploader = ({ createAd, getAds, ads, removeAd, isAd, isAdmin, client, clients, getClients, updateClient, t }) => {
  const [file, setFile] = useState(null);
  const [range, setRange] = React.useState();
  const [clientId, setClientId] = React.useState();
  const [openPs, setOpenPs] = React.useState();

  const columnsDataCheck = [
    {
      Header: t("file_name").toUpperCase(),
      accessor: "fileName",
    },
    {
      Header: t("start_date").toUpperCase(),
      accessor: "start",
    },
    {
      Header: t("end_date").toUpperCase(),
      accessor: "end",
    },
    {
      Header: t("created_at").toUpperCase(),
      accessor: "createdAt",
    },
    {
      Header: t("action").toUpperCase(),
      accessor: "_id",
    },
  ];

  React.useEffect(() => {
    getClients();
    getAds({ isAd, isAdmin });
  }, [isAdmin]);

  const handleSwitchChanged = ({ target: { checked }}) => {
    setOpenPs(checked);
    updateClient({
      ...client,
      openPs
    });
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!range) {
      setToast('warning', t('please_select_the_date_range_in_the_calendar'))
      return
    }
    
    if (isAdmin && !clientId) {
      setToast('warning', t('please_select_the_client'))
      return
    }

    const success = await createAd(file, {
      start: range[0],
      end: range[1],
      isAd,
      client: isAdmin? clientId : client._id,
    });

    if (!success) {
      return;
    }

    setRange(null)
    setFile(null);
    setClientId('');
    setOpenPs(false);
  };

  return (
    <>
      <form
        className="mt-5"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <MiniCalendar
            className="col-span-1"
            value={range}
            onChange={setRange}
          />
          <div className="col-span-2">
            <div className="mb-2 flex justify-end">
              { isAdmin && 
              <Select
                variant="standard"
                label={t("admin:client")+" "+t("admin:name")}
                onChange={(value) => setClientId(value)}
                defaultValue={clientId}
                color={client?.brColor || THEME_COLOR.brColor}
              >
                {(clients || []).map((item, index)=>
                  <Option key={`client-${index}`} value={item._id}>{item.username}</Option>
                )}
              </Select>}
              { !isAdmin && !isAd && 
              <Switch
                label={t("display_other_great_point_specials")}
                color={client?.brColor || THEME_COLOR.BORDER}
                checked={openPs}
                onChange={handleSwitchChanged}
              />}
            </div>
            <FileUploadInput
              className="col-span-2"
              fileName={file?.name}
              onFileChange={handleFileChange}
              required
            />
          </div>
        </div>


        {file &&
          <div className="flex justify-center mb-5">
            <Button
              variant="gradient"
              type="submit"
              color={client?.brColor || THEME_COLOR.BORDER}
            >
              {t("Upload a File")}
            </Button>
          </div>
        }
      </form>

      <AdTable
        title={t(isAd ? "common:ads" : "common:point_specials")}
        isAd={isAd}
        columnsData={columnsDataCheck}
        tableData={ads}
        remove={removeAd}
        color={client?.brColor || THEME_COLOR.BORDER}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAdmin: sm.isAdmin,
    isAuthenticated: sm.isAuthenticated,
    ads: sm.admin.ads || [],
    client: sm.client.client || {},
    clients: sm.client.clients || []
  };
};

const mapActionsToProps = {
  getClients: getClientsThunk,
  updateClient: updateClientThunk,
  getAds: getAdsThunk,
  createAd: uploadAdThunk,
  removeAd: removeAdThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["admin"])(FileUploader));