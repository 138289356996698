import React, { Component } from "react";
import { connect } from 'react-redux';
import { Textarea, Button, Select, Option } from "@material-tailwind/react";
import Card from "components/card";
import AboutTable from "./components/AboutTable";
import { getAboutThunk, getAboutsThunk, createAboutThunk, updateAboutThunk, removeAboutThunk } from "../../../redux/admin/thunks";
import { getClientsThunk } from "../../../redux/client/thunks";
import { stateMappings } from "../../../redux/stateMappings";
import { setToast } from "lib/toast";
import { THEME_COLOR, COL_ACCESS } from "variables/constants";
import { withTranslation } from "react-i18next";

class Abouts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      en: '',
      es: '',
      clientId: '',
    };
    if (!props.isAdmin) {
      this.setState({
        ...this.state,
        ...this.props.about,
        clientId: this.props.about.client,
      });
    }
  }

  async componentDidMount() {
    await this.props.getAbout();
    await this.props.getAbouts();
    await this.props.getClients();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isAdmin && (JSON.stringify(prevProps.about) !== JSON.stringify(this.props.about))) {
      this.setState({
        ...this.state,
        ...this.props.about,
        clientId: this.props.about.client,
      });
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  onChangeSelectItem = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  openDetails = (data) => {
    const { _id, en, es, client } = data;
    this.setState({
      _id,
      en,
      es,
      clientId: client
    });
  }

  clearDetails = () => {
    this.setState({
      _id: '',
      en: '',
      es: '',
      clientId: ''
    });
  }

  validate = () => {
    let {
      en,
    } = this.state;

    const { t } = this.props;

    if (!en) {
      setToast('error', t("English content is required!"));
      return false
    }

    return true
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) {
      return
    }

    let {
      _id,
      en,
      es,
      clientId
    } = this.state;

    let {
      isAdmin,
      updateAbout,
      createAbout,
    } = this.props;

    const aId = isAdmin ? _id : this.props.about._id;
    const cId = isAdmin ? clientId : this.props.client._id;

    let data = {
      client: cId,
      en,
      es
    };

    if (aId) {
      data = {
        ...data,
        _id: aId,
      };  
      updateAbout(data);
    } else {
      createAbout(data);
    }
  };

  render() {
    const { en, es, clientId } = this.state;
    const { isAdmin, abouts, removeAbout, client, clients, t } = this.props;
    const columnsDataCheck = [
      {
        Header: t("admin:client").toUpperCase(),
        accessor: COL_ACCESS.CLIENT,
      },
      {
        Header: t("english").toUpperCase(),
        accessor: COL_ACCESS.EN,
      },
      {
        Header: t("spanish").toUpperCase(),
        accessor: COL_ACCESS.ES,
      },
      {
        Header: t("admin:date").toUpperCase(),
        accessor: COL_ACCESS.DATE,
      },
      {
        Header: t("admin:action").toUpperCase(),
        accessor: COL_ACCESS.ACTION,
      },
    ];

    return (
      <>
        <Card className="mt-5 w-full h-full p-4 sm:overflow-x-auto">
          <h5 className="text-center text-xl font-bold theme-tx-color dark:text-white">{t("common:about_us")+" "+t("common:information")}</h5>
          <form
            className="flex w-full items-center justify-center mt-8 px-2 md:mx-0 md:px-0 lg:mb-10"
            onSubmit={this.handleSubmit}
          >
            <div className="mt-[1vh] w-full max-w-full flex-col items-center">
              <div className="mt-2">
                <Textarea 
                  variant="static" 
                  name="en"
                  label={t("english")}
                  placeholder={t("Please_input_english_content_for_aboutus")}
                  value={en}
                  onChange={this.onChange}
                  color={client?.brColor || THEME_COLOR.BORDER}
                />
                </div>
                <div className="mt-4">
                <Textarea 
                  variant="static" 
                  name="es"
                  label={t("spanish")}
                  placeholder={t("Please_input_spanish_content_for_aboutus")}
                  value={es}
                  onChange={this.onChange}
                  color={client?.brColor || THEME_COLOR.BORDER}
                />
                { isAdmin && 
                <div className="mb-4">
                  <Select
                    variant="standard"
                    label={t("admin:client")+" "+t("admin:name")}
                    onChange={(data) => this.onChangeSelectItem("clientId", data)}
                    value={clientId}
                    color={client?.brColor || THEME_COLOR.brColor}
                  >
                    {(clients || []).map((item, index)=>
                      <Option key={`client-${index}`} value={item._id}>{item.username}</Option>
                    )}
                  </Select>
                </div> }
              </div>
              <div className="flex justify-center mt-4">
                <Button
                  variant="outlined"
                  onClick={this.clearDetails}
                  className="mr-3"
                  color={client?.brColor || THEME_COLOR.brColor}
                >
                  <span>{t("common:clear_all")}</span>
                </Button>
                <Button
                  variant="gradient"
                  type="submit"
                  color={client?.brColor || THEME_COLOR.BORDER}
                >
                  {t("common:save")}
                </Button>
              </div>
            </div>
          </form>
        </Card>
        { isAdmin && 
        <AboutTable
          columnsData={columnsDataCheck}
          tableData={abouts}
          remove={removeAbout}
          openDetails={this.openDetails}
          clearDetails={this.clearDetails}
          color={client?.brColor || THEME_COLOR.BORDER}
        />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    isAdmin: sm.isAdmin,
    client: sm.user.admin?.client || {},
    clients: sm.client.clients || [],
    about: sm.admin.about || {},
    abouts: sm.admin.abouts || [],
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  getAbout: getAboutThunk,
  getAbouts: getAboutsThunk,
  getClients: getClientsThunk,
  createAbout: createAboutThunk,
  updateAbout: updateAboutThunk,
  removeAbout: removeAboutThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["profile"])(Abouts));