import FAQ_LIST from './variables/faqList.json';

const FAQ = () => {
  return (
    <>
      <h4 className="mt-5 mb-5 text-center text-xl md:text-3xl font-bold text-navy-700 dark:text-white">
        Frequently Asked Questions By Customer
      </h4>
      {FAQ_LIST.map((faq, index) =>
        <div
          key={`faq-${index}`}
          className="mb-3 md:mb-5 dark:text-white"
        >
          <h3 className="md:text-xl text-md mb-1 font-medium">{faq.question}</h3>
          <p className="text-sm">
            {faq.answer}
          </p>
          {faq.list &&
            <div>
              {faq.list?.map((item, index) =>
                <p
                  key={`faq-item-${index}`}
                  className="text-sm"
                >
                  {item}
                </p>
              )}
            </div>
          }
        </div>
      )}
    </>
  );
};

export default FAQ;

