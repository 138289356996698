import {
  getEmploymnets,
  postApplication,
  putApplication,
  deleteApplication,
} from './apiCalls';
import {
  setApplications,
  addApplication,
  editApplication,
  removeApplication,
  setErrorMessage,
  setLoadingStatus,
} from './actions';
import { setToast } from '../../lib/toast';

export const getApplicationsThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: applications } = await getEmploymnets();
    dispatch(setApplications(applications));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
  }
};

export const createApplicationThunk = (application) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await postApplication(application);

    dispatch(addApplication(application));
    dispatch(setStatus(false, ''));
    setToast('success', 'admin:msg_app_submitted');
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const editApplicationThunk = (application) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await putApplication(application);

    dispatch(editApplication(application));
    dispatch(setStatus(false, ''));
    setToast('success', 'admin:msg_app_reviewed');
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const removeApplicationThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteApplication(id);

    dispatch(removeApplication(id));
    dispatch(setStatus(false, ''));
    setToast('success', 'admin:msg_app_deleted');
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
