/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { ROLE } from "variables/constants";
import { withTranslation } from "react-i18next";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes, t } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = ({ layout, path }) => {
    return location.pathname === `${!!layout ? `/${layout}` : '' }/${path}`;
  };

  const createLinks = (routes) => {
    return routes.map((route, index) =>
      <Link key={index} to={route.layout + "/" + route.path}>
        <div className="relative mb-3 flex hover:cursor-pointer">
          <li
            className="my-[3px] flex cursor-pointer items-center px-8"
            key={index}
          >
            <span
              className={`${
                activeRoute(route) === true
                  ? "font-bold theme-tx-color dark:text-white"
                  : "font-medium text-gray-600"
              }`}
            >
              {route.icon ? route.icon : <DashIcon />}{" "}
            </span>
            <p
              className={`leading-1 ml-4 flex ${
                activeRoute(route) === true
                  ? "font-bold theme-tx-color dark:text-white"
                  : "font-medium text-gray-600"
              }`}
            >
              {route.layout ? t("admin:manage")+" "+t(route.name) : t(route.name)}
            </p>
          </li>
          {activeRoute(route) ? (
            <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
          ) : null}
        </div>
      </Link>
    );
  };
  // BRAND
  return createLinks(routes);
}

export default withTranslation()(SidebarLinks);
