import {
  SET_CARD,
  EDIT_CARD,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';


const defaultState = {
  Number: '000000000000',
  Enabled: false,
  IsRedeemable: false,
  AccountID: -1,
  LastUpdatedTime: '',
  ID: -1,
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_CARD:
      return { ...state, ...action.payload };

    case EDIT_CARD:
      return { ...state, ...action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  };
};

export default reducer;
