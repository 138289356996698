import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button, Select, Option, Checkbox } from "@material-tailwind/react";

import { stateMappings } from '../../redux/stateMappings';
import { signUpThunk } from '../../redux/auth/thunks';
import { withRouter } from 'lib/withRouter';
import { setToast } from 'lib/toast';
import { THEME_COLOR } from 'variables/constants';
import { withTranslation } from 'react-i18next';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      streetaddress1: '',
      streetaddress2: '',
      city: '',
      state: 'AL',
      zipcode: 0,
      email: '',
      password: '',
      confirm_password: '',
      mobilephone: '',
      homestore: '1',
      emailannualpurchases: false,
      states: {},
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {    
      return
    }

    window.location.href = `/my-account`;
  }

  onChange = ({ target: { name, value, type, checked } }) => {
    this.setState({
      ...this.state,
      [name]: type !== 'checkbox' ? value : checked,
      states: {
        ...this.state.states,
        [name]: ''
      }
    });
  }

  onChangeSelectItem = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
      states: {
        ...this.state.states,
        [name]: ''
      }
    });
  }

  validate = () => {
    let {
      password,
      confirm_password,
      states,
      ...shopper
    } = this.state;

    const { t } = this.props;

    if (shopper.zipcode) {
      if (!/^\d{5}$/.test(shopper.zipcode)) {
        this.setState({
          states: {
            ...this.state.states,
            zipcode: 'error'
          }
        }, () => {
          setToast('error', t("Zip_code_must_be_5_digits"));
        })

        return false
      }
    }

    if (shopper.mobilephone) {
      if (!/^\d{10}$/.test(shopper.mobilephone)) {
        this.setState({
          states: {
            ...this.state.states,
            mobilephone: 'error'
          }
        }, () => {
          setToast('error', t("Phone_number_must_be_10_digits"));
        })

        return false
      }
    }

    if (shopper.email) {
      if (!/\S+@\S+\.\S+/.test(shopper.email)) {
        this.setState({
          states: {
            ...this.state.states,
            email: 'error'
          }
        }, () => {
          setToast('error', t("Invalid email address"));
        })

        return false
      }
    }

    if (password) {
      if (password.length < 8) {
        this.setState({
          states: {
            ...this.state.states,
            password: 'error'
          }
        }, () => {
          setToast('error', t("auth:msg_pwd_validation"));
        })

        return false
      }
    }

    if (password && (password !== confirm_password)) {
      this.setState({
        states: {
          ...this.state.states,
          confirm_password: 'error'
        }
      }, () => {
        setToast('error', t("auth:msg_pwd_confirm_not_match"));
      })

      return false
    }

    return true
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) {
      return
    }
    
    const { signUp, navigate } = this.props;
    const { confirm_password, states, ...shopper } = this.state

    try {
      const success = await signUp(shopper)

      if (success) {
        navigate('/auth/sign-in')
      }
    } catch(err) {
      console.log("[Sign up Error]", err)
    }
  };

  render() {
    const { state, homestore } = this.state
    const { client, theme, t } = this.props

    return (
      <form
        className="mt-5 flex h-full w-full items-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start"
        onSubmit={this.handleSubmit}
      >
        <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
          <h4 className="mb-5 text-4xl font-bold text-navy-700 dark:text-white">
            {t("auth:sign_up")}
          </h4>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            <div className="mb-4">
              <Input
                variant="standard"
                label={t("First Name")}
                name="firstname"
                placeholder={t("First Name")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
              />
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Last Name")}
                name="lastname"
                placeholder={t("Last Name")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
              />
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Street Address")+" 1"}
                name="streetaddress1"
                placeholder={t("Street Address")+" 1"}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
              />
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Street Address")+" 2"}
                name="streetaddress2"
                placeholder={t("Street Address")+" 2"}
                onChange={this.onChange}
                color={client?.brColor || THEME_COLOR.BORDER}
              />
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("City")}
                name="city"
                placeholder={t("City")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
              />
            </div>

            <div className="mb-4">
              {(this.props.states || []).length > 0 &&
                <Select
                  variant="standard"
                  label={t("State")}
                  placeholder={t("State")}
                  onChange={(data) => this.onChangeSelectItem("state", data)}
                  value={state}
                  color={client?.brColor || THEME_COLOR.BORDER}
                >
                  {(this.props.states || []).map(({ Abbreviation, FullName }, index)=>
                    <Option key={`option-state-${index}`} value={Abbreviation}>{FullName}</Option>
                  )}
                </Select>
              }
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Zip Code")+" 5"}
                name="zipcode"
                placeholder={t("Zip Code")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
                type="number"
              />
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Phone Number")}
                name="mobilephone"
                placeholder={t("Phone Number")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
                type="number"
              />
            </div>

            <div className="mb-4">
              {(this.props.stores || []).length > 0 &&
                <Select
                  variant="standard"
                  label={t("Home Store")}
                  onChange={(data) => this.onChangeSelectItem("homestore", data)}
                  placeholder={t("Home Store")}
                  required
                  value={homestore}
                  color={client?.brColor || THEME_COLOR.BORDER}
                >
                  {(this.props.stores || []).map(({ ID, StoreName }, index)=>
                    <Option key={`option-store-${index}`} value={ID.toString()}>{StoreName}</Option>
                  )}
                </Select>
              }
            </div>

            <div className="mb-4">
              <Input
                variant="standard"
                label={t("Email Address")}
                name="email"
                placeholder={t("Email Address")}
                onChange={this.onChange}
                required
                color={client?.brColor || THEME_COLOR.BORDER}
                type="email"
                autoComplete="user-email"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
            <Input
              variant="standard"
              label={t("auth:password")}
              name="password"
              placeholder={t("auth:password")}
              onChange={this.onChange}
              required
              color={client?.brColor || THEME_COLOR.BORDER}
              type="password"
              autoComplete="new-password"
            />

            <Input
              variant="standard"
              label={t("auth:confirm_pwd")}
              name="confirm_password"
              placeholder={t("auth:confirm_pwd")}
              onChange={this.onChange}
              required
              color={client?.brColor || THEME_COLOR.BORDER}
              type="password"
              autoComplete="new-confirm-password"
            />
          </div>

          <div className="flex justify-start -ml-4 my-6">
            <Checkbox
              name="emailannualpurchases"
              type="checkbox"
              onChange={this.onChange}
              color={theme === 'dark' ? 'white' : (client.brColor || THEME_COLOR.BORDER)}
            />
            <p className="text-sm font-medium text-navy-700 dark:text-white pt-3">
              {t("I_would_like_to_receive_emails")}
            </p>
          </div>

          <Button
            variant="gradient"
            type="submit"
            color={client?.brColor || THEME_COLOR.BORDER}
            className="w-full"
          >
            {t("auth:sign_up")}
          </Button>

          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              {t("auth:already_registered")}
            </span>
            <Link
              to="/auth/sign-in"
              className="ml-1 text-sm font-medium theme-tx-color theme-dc-color-hover dark:text-white"
            >
              {t("auth:sign_in")}
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    states: sm.states || [],
    stores: sm.stores || [],
    client: sm.client.client || {},
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  signUp: signUpThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(withTranslation(["profile"])(SignUp)));

