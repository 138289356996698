import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { LuChevronsDown } from "react-icons/lu";
import { RiLogoutCircleLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { Select, Option } from "@material-tailwind/react";
import { MdPerson } from "react-icons/md";
import { stateMappings } from "../../redux/stateMappings";
import { signOutThunk } from "../../redux/auth/thunks";
import { setThemeModeThunk } from '../../redux/user/thunks';
import routes from "routes.js";
import { withRouter } from "lib/withRouter";
import { LANGUAGES, THEME_COLOR, THEME_MODE, ROLE } from "variables/constants";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

const Header = (props) => {
  const {
    client,
    shopper,
    signOut,
    isAuthenticated,
    location,
    configs,
    setThemeMode,
    theme,
    t
  } = props;

  const [toggleMenu, setToggleMenu] = useState(false);
  const [lang, setLang] = useState("");

  const headerRoutes = routes.filter(route => route.primary);
  const secondaryRoutes = routes.filter(route => route.secondary);
  const dropdownRoutes = routes.filter(route => route.dropdown);
  let mobileRouteLinks = routes.filter(({ layout, disable }) => !(isAuthenticated && layout === '/auth') && !disable);

  if (!isAuthenticated) {
    mobileRouteLinks = mobileRouteLinks.filter(route => {
      if (route.layout !== ROLE.ADMIN && route.layout !== ROLE.SHOPPER && route.path !== "my-account") {
        return route;
      }
    })
  }

  useEffect(() => {
    setToggleMenu(false);
    setLang(i18next.language.slice(0, 2));
  }, [location]);

  const onLangChanged = (lng) => {
    setLang(lng);
    i18next.changeLanguage(lng);
  }

  return (
    <header>
      <nav className={`theme-bg-color dark:bg-navy-800`}>
        <div className="flex mx-auto justify-between max-w-[1380px] px-2">
          {/* Primary menu and logo */}
          <div className="flex items-center gap-16">
            {/* logo */}
            <div className="my-3">
              <a
                href="/"
                className="flex gap-1 font-bold text-white items-center"
              >
                <img
                  className="h-[50px] w-[auto]"
                  src={configs?.PublicPortalLogoImageURL}
                  alt=""
                />
              </a>
            </div>
            {/* primary */}
            <div className="hidden xl:flex gap-8 text-white font-bold">
              {headerRoutes.map((route, index) => {
                if (route.href) {
                  return (
                    <a
                      key={`header-route-${index}`}
                      href={route.href}
                      target="_blank"
                    >
                      {t(route.name)}
                    </a>
                  );
                }

                return (
                  <Link
                    key={`header-route-${index}`}
                    to={`${route.layout}/${route.path}`}
                  >
                    {t(route.name)}
                  </Link>
                );
              }
              )}
            </div>
          </div>
          <div className="relative flex flex-grow items-center justify-end gap-4 md:flex-grow-0 md:justify-around">
            <div className={`hidden sm:flex h-[45px] items-center rounded-full theme-bg-color text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[300px] border outline-white`}>
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-200 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder={t("common:search")+"..."}
                className={`block h-full w-full rounded-full theme-bg-color text-sm font-medium text-white outline-none placeholder:!text-gray-200 dark:bg-navy-900 dark:text-white dark:placeholder:!text-gray-500 sm:w-fit`}
              />
            </div>
            <div className="cursor-pointer text-white lang-wrapper">
              <Select
                variant="standard"
                label={t("common:store_location")}
                value={lang}
                color={client?.brColor || THEME_COLOR.BORDER}
                onChange={onLangChanged}
                className="max-w-[120px]"
              >
                {LANGUAGES.map((lng, index)=>
                  <Option 
                    key={`option-state-${index}`} 
                    value={lng.code} 
                    className="text-gray-900"
                    >
                      {lng[lng.code]}
                  </Option>
                )}
              </Select>
            </div>
            <div
              className="cursor-pointer text-gray-600"
              onClick={() => setThemeMode(theme === THEME_MODE.LIGHT ? THEME_MODE.DARK : THEME_MODE.LIGHT)}
            >
              {theme === THEME_MODE.DARK ? (
                <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
              ) : (
                <RiMoonFill className="h-4 w-4 text-yellow-50 dark:text-white" />
              )}
            </div>
            <span
              className="flex cursor-pointer text-xl text-white dark:text-white xl:hidden"
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <FiAlignJustify className="h-5 w-5" />
            </span>
            {/* Profile & Dropdown */}

            {!isAuthenticated &&
              <Link
                to="/auth/sign-in"
                className="rounded-[20px] p-[10px] cursor-pointer border-2 border-brand-600 hidden dark:border-navy-800 hover:!border-white hover:bg-brand-700 dark:hover:bg-navy-900 md:block"
              >
                <p className="flex items-center gap-2 text-sm text-white dark:text-white">
                  <MdPerson className="w-4 h-4" />
                  <span className="underline">{t("auth:sign_in")}</span>
                </p>
              </Link>
            }

            {isAuthenticated &&
              <Dropdown
                button={
                  <button className="z-100 rounded-[20px] p-[10px] cursor-pointer border-2 border-brand-600 hidden dark:border-navy-800 hover:!border-white hover:bg-brand-700 dark:hover:bg-navy-900 md:block">
                    <div className="flex items-center gap-1 text-sm text-white dark:text-white">
                      <MdPerson className="w-4 h-4" />
                      {t("common:hi", {name: shopper?.firstname || ''})}
                      <LuChevronsDown className="w-4 h-4" />
                    </div>
                  </button>
                }
                children={
                  <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                    <div className="flex flex-col p-4">
                      {dropdownRoutes.map((route, index) =>
                        <Link
                          key={`dropdown-route-${index}`}
                          to={`${route.layout}/${route.path}`}
                          className="flex items-center gap-2 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                        >
                          {route.icon}
                          {route.getName ? route.getName(props) : t(route.name)}
                        </Link>
                      )}
                      <strong
                        className="flex items-center gap-2 mt-[30px] text-sm font-medium text-brand-500 hover:text-brand-500 cursor-pointer"
                        onClick={() => signOut()}
                      >
                        <RiLogoutCircleLine className="w-4 h-4" />
                        {t("auth:sign_out")}
                      </strong>
                    </div>
                  </div>
                }
                classNames={"z-51 py-2 top-8 -left-[150px] w-max"}
              />
            }
          </div>
        </div>
        {/* mobile navigation */}
        <div
          className={`absolute z-40 w-full bg-white dark:bg-navy-900 overflow-hidden flex flex-col lg:hidden gap-12 origin-top duration-200 ${
            !toggleMenu ? "h-0" : "h-full"
          }`}
        >
          <div className="px-8 py-12 text-gray-800 dark:text-white overflow-auto">
            <div className="flex flex-col gap-8 font-bold tracking-wider">
              {isAuthenticated &&
                <div className="flex items-center gap-2 pl-[20px] text-gray-400">
                  <MdPerson className="w-4 h-4" />
                  <span>{t("auth:you_are_signed_in_as", {name: shopper?.firstname || ''})}</span>
                </div>
              }
              {mobileRouteLinks.map((route, index) => {
                  if (!client?.openPs && route.path === 'great-point-speicals') {
                    return null
                  }
                  
                  const pathname = `${route.layout}/${route.path}`;
                  return (
                    <Link
                      key={`mobile-route-${index}`}
                      to={pathname}
                      className={`flex items-center gap-2 hover:!pl-4 hover:!border-l-4 hover:!border-brand-500 ${pathname === location.pathname ? 'pl-4 border-l-4 border-brand-500' : 'pl-[20px]'}`}
                    >
                      {route.icon}
                      {route.getName ? route.getName(props) : (route.layout ? t("admin:manage")+" "+t(route.name) : t(route.name))}
                    </Link>
                  );
                }
              )}
              {isAuthenticated &&
                <div
                  className="flex items-center gap-2 text-brand-500 hover:!pl-4 hover:!border-l-4 hover:!border-brand-500 pl-[20px] cursor-pointer"
                  onClick={() => signOut()}
                >
                  <RiLogoutCircleLine className="w-4 h-4" />
                  {t("auth:sign_out")}
                </div>
              }
            </div>
          </div>
        </div>
      </nav>
      <nav
        className="bg-[rgb(245, 245, 245)] dark:bg-navy-700"
        style={{ boxShadow: '0 1px 3px 0 #00000026' }}
      >
        <div className="hidden xl:flex justify-center py-2">
          {secondaryRoutes.map((route, index) =>
            <div
              key={`secondary-route-${index}`}
              className={`flex items-center px-5${index !== secondaryRoutes.length - 1 ? ' border-r-1 border-gray-300' : ''}`}
            >
              <Link
                to={`${route.layout}/${route.path}`}
                className="flex items-center gap-3 py-2 px-5 text-sm rounded-[5px] hover:bg-gray-100 dark:text-white dark:hover:bg-navy-900"
              >
                {route.icon}
                {route.getName ? route.getName(props) : t(route.name)}
              </Link>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    client: sm.client.client || {},
    store: (sm.stores || []).find(store => store.ID === sm.account.homestoreid),
    shopper: sm.shopper,
    clippeds: sm.coupon.clippeds || [],
    isAuthenticated: sm.isAuthenticated,
    configs: sm.configs,
    theme: sm.user.theme,
  };
};

const mapActionsToProps = {
  signOut: signOutThunk,
  setThemeMode: setThemeModeThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(withTranslation()(Header)));
