import { Routes, Route, Link } from "react-router-dom";
import Profile from "./components/Profile";
import Perks from "./components/Perks";
import { withRouter } from "lib/withRouter";
import { withTranslation } from "react-i18next";

const MyAccount = ({ location, t }) => {
  const routes = [
    {
      name: t("Account Profile"),
      layout: "/my-account",
      path: 'profile',
      component: <Profile />
    },
    {
      name: "Steve's PERKS®",
      layout: "/my-account",
      path: 'perks',
      component: <Perks />
    },
  ];
  return (
    <div className="flex w-full flex-col gap-10">
      <div className="w-ful flex h-fit flex-col gap-5 grid grid-cols-1 md:grid-cols-12">
        <div className="col-span-1 md:col-span-4 xl:col-span-2">
          <ul className="mt-5">
            {routes.map((route, index) => {
                const pathname = `${route.layout}/${route.path}`;
                const active = pathname === location.pathname || (location.pathname === '/my-account' && route.path === 'profile');

                return (
                  <li
                    key={`my-account-${index}`}
                    className="text-sm border-b-2 border-gray-200 last:border-b-0">
                    <Link
                      to={`/my-account/${route.path}`}
                      className={`flex py-2 bg-white theme-br-color dark:bg-navy-800 dark:text-white hover:bg-gray-100 hover:dark:!bg-navy-700 hover:pl-4 hover:border-l-4 theme-br-color-hover ${active ? 'px-4 border-l-4 !bg-gray-200 dark:!bg-navy-800' : 'px-[20px]'}`}
                    >
                      {route.name}
                    </Link>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        <div className="col-span-1 md:col-span-8">
          <Routes>
            <Route
              path="/"
              element={<Profile />}
            />
            {routes.map((route, index) =>
              <Route
                key={`account-route-${index}`}
                path={`/${route.path}`}
                element={route.component}
              />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(["profile"])(MyAccount));
