/* eslint-disable */
import { connect } from 'react-redux';
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import logoImg from "assets/img/auth/logo.png";
import logoWhiteImg from "assets/img/auth/logo-white.png";
import config from "../../config";
import { stateMappings } from '../../redux/stateMappings';
import { ROLE } from 'variables/constants';

const Sidebar = ({ open, onClose, isAdmin, theme }) => {
  const routes_value = routes.filter(route => route.path === "my-account" || route.layout === ROLE.SHOPPER || (route.layout === ROLE.ADMIN && isAdmin))
  routes_value.unshift(routes[0]);

  return (
    <div
      className={`w-[262px] sm:none duration-175 linear fixed top-[0] left-[0] h-full !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`flex items-center justify-center`}>
        <a
          href={config.market_url}
          target="_blank"
        >
          <img
            src={theme === 'dark' ? logoWhiteImg : logoImg}
            alt=""
            width={154}
          />
        </a>
      </div>
      <div className="mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes_value} />
      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    theme: sm.user.theme,
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(Sidebar);
