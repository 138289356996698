import {
  SET_APPLICATIONS,
  ADD_APPLICATION,
  EDIT_APPLICATION,
  DELETE_APPLICATION,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const defaultState = {
  applications: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_APPLICATIONS:
      return { ...state, applications: action.payload };

    case ADD_APPLICATION:
      return { ...state };

    case EDIT_APPLICATION:
      return { ...state, applications: state.applications.map(emp => {
        if (emp._id !== action.payload._id) {
          return emp;
        }

        return action.payload;
      })};

    case DELETE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(emp => emp._id !== action.payload)
      };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  };
};

export default reducer;
