export const services = [
  {
    Header: "ATM",
    accessor: "",
  },
  {
    Header: "Check Cashing",
    accessor: "",
  },
  {
    Header: "Double Coupons",
    accessor: "",
  },
  {
    Header: "EBT/Food Stamps Accepted Gift Cards",
    accessor: "",
  },
  {
    Header: "Lottery",
    accessor: "",
  },
  {
    Header: "Major Credit Cards Accepted",
    accessor: "",
  },
  {
    Header: "Postage Stamps Utility Payments",
    accessor: "",
  },
  {
    Header: "Western Union Mondy Orders",
    accessor: "",
  },
  {
    Header: "Bakery Propane Exchange (North Lewisburg)",
    accessor: "",
  },
  {
    Header: "Fuel Center (North Lewisburg)",
    accessor: "",
  },
  {
    Header: "Full Service Deli (Urbana)",
    accessor: "",
  },
];
