import {
  getCard,
  putCard,
} from './apiCalls';
import {
  setCard,
  editCard,
  setErrorMessage,
  setLoadingStatus,
} from './actions';
import { setToast } from 'lib/toast';


export const getCardThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getCard();
    const { data: cardData, result } = data || {};
    const { card } = cardData || {};

    if (card && result === "OK") {
      dispatch(setCard(card));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const editCardThunk = (card) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await putCard(card);
    const { data: cardData, result } = data || {};
    const { card: editedCard } = cardData || {};

    if (editedCard && result === "OK") {
      dispatch(editCard(editedCard));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
