import FileUploader from "./components/FileUploader";

const SpecialsUploader = () => {
  return (
    <>
      <FileUploader isAd={false} />
    </>
  );
};

export default SpecialsUploader;


