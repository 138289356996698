import {
  getClientConfigs,
  getClients,
  getClient,
  createClient,
  updateClient,
  deleteClient,
} from './apiCalls';
import {
  setClientConfigs,
  setClients,
  setClient,
  addClient,
  editClient,
  setLoadingStatus,
  setErrorMessage,
  removeClient,
} from './actions';
import { setToast } from '../../lib/toast';

export const getClientConfigsThunk = (username = '') => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getClientConfigs(username);
    const { data: configsData, result } = data || {};
    const { configs } = configsData || {};

    if (configs && result === "OK") {
      dispatch(setClientConfigs(configs));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const getClientsThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: clients } = await getClients();

    dispatch(setClients(clients));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const getClientThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: client } = await getClient(id);

    dispatch(setClient(client));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const createClientThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: client } = await createClient(data);

    dispatch(addClient(client));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_client_created");
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};


export const updateClientThunk = (data) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const _id = data._id;
    delete data._id;
    await updateClient(_id, data);

    dispatch(editClient({...data, _id}));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_client_updated");
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const removeClientThunk = (id) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteClient(id);

    dispatch(removeClient(id));
    dispatch(setStatus(false, ''));
    setToast("success", "admin:msg_client_deleted");
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
