import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { Select, Option } from "@material-tailwind/react";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { stateMappings } from "../../../redux/stateMappings";
import { getSpecialsThunk } from "../../../redux/point/thunks";
import SpecialCard from "./components/SpecialCard";
import { THEME_COLOR, SORT_ITEMS, ITEMS_PER_PAGE } from "variables/constants";
import { withTranslation } from "react-i18next";

const GreatePointSpecials = ({ getSpecials, specials, client, t }) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sort, setSort] = React.useState('')

  React.useEffect(() => {
    getSpecials()
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  const onSortChange = (value) => {
    setCurrentPage(0)
    setSort(value)
  }

  const sortBy = (items) => {
    switch (sort) {
      case '':
        return items
      case SORT_ITEMS.START_DATE:
        return _.orderBy(items, 'StartDate', 'desc')
      case SORT_ITEMS.POINTS_LOWEST:
        return _.orderBy(items, 'PointsSpent', 'asc')
      case SORT_ITEMS.POINTS_HIGHEST:
        return _.orderBy(items, 'PointsSpent', 'desc')
      case SORT_ITEMS.END_DATE:
        return _.orderBy(items, 'EndDate', 'asc')
      default:
        return items
    }
  }

  const itemOffset = (currentPage * ITEMS_PER_PAGE) % specials.length
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentSpecials = sortBy(specials).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(specials.length / ITEMS_PER_PAGE);
  const SORTLIST = [
    {
      name: t("most_relevant"),
      value: ''
    },
    {
      name: t("newest"),
      value: SORT_ITEMS.START_DATE,
    },
    {
      name: t("points"),
      icon: <FaSortAmountDownAlt />,
      value: SORT_ITEMS.POINTS_LOWEST,
    },
    {
      name: t("points"),
      icon: <FaSortAmountDown />,
      value: SORT_ITEMS.POINTS_HIGHEST,
    },
    {
      name: t("expiration"),
      value: SORT_ITEMS.END_DATE,
    },
  ];

  return (
    <>
      <h4 className="mt-5 mb-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        {t("great_point_specials")}
      </h4>

      <div className="flex justify-center md:justify-end mb-5">
        <div className="w-full md:max-w-[200px]">
          <Select
            variant="standard"
            name="sort"
            label={t("sort_by")}
            color={client.brColor || THEME_COLOR.brColor}
            value={sort}
            onChange={onSortChange}
          >
            {SORTLIST.map((sort, index) =>
              <Option
                key={`sort-${index}`}
                className="flex justify-between"
                value={sort.value}
              >
                <span>{sort.name}</span>
                {sort.icon}
              </Option>
            )}
          </Select>
        </div>
      </div>

      {specials.length === 0 &&
        <h4 className="text-center dark:text-white">{t("no")} {t("great_point_specials")}</h4>
      }

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5">
        {currentSpecials.map((special, index) =>
          <SpecialCard
            key={`special-${index}`}
            { ...special }
          />
        )}
      </div>

      <div className="mt-5 flex justify-center">
        <ReactPaginate
          disabledClassName={"cursor-not-allowed disabled-pagination !text-gray-700 !bg-gray-300"}
          pageClassName="sm:px-3 px-2 py-2 sm:py-1 rounded-md dark:text-white"
          previousClassName="px-2 sm:px-3 py-2 sm:py-1 rounded-md theme-bg-color text-white mr-2 dark:bg-navy-800"
          nextClassName="px-2 sm:px-3 py-2 sm:py-1 rounded-md theme-bg-color text-white ml-2 dark:bg-navy-800"
          breakClassName={"break-me px-2 py-2 sm:px-1 text-gray-400"}
          containerClassName={"react-paginate text-sm leading-tight flex mt-8 items-center"}
          activeClassName={"theme-bg-color text-white dark:bg-navy-700"}
          breakLabel="..."
          nextLabel={t("next")}
          previousLabel={t("prev")}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    specials: sm.specials || [],
    client: sm.client.client || {}
  };
};

const mapActionsToProps = {
  getSpecials: getSpecialsThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["common"])(GreatePointSpecials));

