import React, { Component } from "react";
import { connect } from 'react-redux';
import { Input, Button, Select, Option, Checkbox } from "@material-tailwind/react";
import Card from "components/card";
import { getShopperThunk, editShopperThunk } from "../../../../redux/user/thunks";
import { getBalanceThunk, getOfferThunk } from "../../../../redux/balance/thunks";
import { getClippedCouponsThunk, getAvailableCouponsThunk } from "../../../../redux/coupon/thunks";
import { getCardThunk } from "../../../../redux/card/thunks";
import { stateMappings } from "../../../../redux/stateMappings";
import { setToast } from "lib/toast";
import { THEME_COLOR } from "variables/constants";
import { withTranslation } from "react-i18next";

class Shopper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      streetaddress1: '',
      streetaddress2: '',
      city: '',
      state: '',
      zipcode: 0,
      email: '',
      password: '',
      confirm_password: '',
      mobilephone: '',
      homestore: 1,
      emailannualpurchases: false,
      states: {},
      ...props.shopper
    };
  }

  async componentDidMount() {
    const {
      getOffer,
    } = this.props;

    try {
      await Promise.all([
        getOffer(),
      ]);
    } catch {
      
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.shopper) !== JSON.stringify(this.props.shopper)) {
      this.setState({
        ...this.state,
        ...this.props.shopper
      });
    }
  }

  onChange = ({ target: { name, value, type, checked } }) => {
    this.setState({
      ...this.state,
      [name]: type !== 'checkbox' ? value : checked,
      states: {
        ...this.state.states,
        [name]: ''
      }
    });
  }

  onChangeSelectItem = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
      states: {
        ...this.state.states,
        [name]: ''
      }
    });
  }

  validate = () => {
    let {
      password,
      confirm_password,
      states,
      ...shopper
    } = this.state;

    const { t } = this.props;

    if (shopper.zipcode) {
      if (!/^\d{5}$/.test(shopper.zipcode)) {
        this.setState({
          states: {
            ...this.state.states,
            zipcode: 'error'
          }
        }, () => {
          setToast('error', t("Zip_code_must_be_5_digits"));
        })

        return false
      }
    }

    if (shopper.mobilephone) {
      if (!/^\d{10}$/.test(shopper.mobilephone)) {
        this.setState({
          states: {
            ...this.state.states,
            mobilephone: 'error'
          }
        }, () => {
          setToast('error', t("Phone_number_must_be_10_digits"));
        })

        return false
      }
    }

    if (shopper.email) {
      if (!/\S+@\S+\.\S+/.test(shopper.email)) {
        this.setState({
          states: {
            ...this.state.states,
            email: 'error'
          }
        }, () => {
          setToast('error', t("Invalid email address"));
        })

        return false
      }
    }

    if (password) {
      if (password.length < 8) {
        this.setState({
          states: {
            ...this.state.states,
            password: 'error'
          }
        }, () => {
          setToast('error', t("auth:msg_pwd_validation"));
        })

        return false
      }
    }

    if (password && (password !== confirm_password)) {
      this.setState({
        states: {
          ...this.state.states,
          confirm_password: 'error'
        }
      }, () => {
        setToast('error', t("auth:msg_pwd_confirm_not_match"));
      })

      return false
    }

    return true
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) {
      return
    }

    let {
      password,
      confirm_password,
      states,
      ...shopper
    } = this.state;

    shopper = {
      ...shopper,
      shopperid: this.props.shopperid,
    };

    this.props.editShopper(
      (!password && !confirm_password) ? shopper : {
        ...shopper,
        password,
      }
    );
  };

  readCardNumber = (cardNum) => {
    return `${cardNum.slice(0, 4)}-${cardNum.slice(4, 8)}-${cardNum.slice(8)}`;
  }

  render() {
    const {
      firstname,
      lastname,
      streetaddress1,
      streetaddress2,
      city,
      state,
      zipcode,
      email,
      password,
      confirm_password,
      mobilephone,
      homestore,
      emailannualpurchases,
      states
    } = this.state;
    const { className, theme, client, t } = this.props;

    return (
      <>
        <Card className={className}>
          <h5 className="text-center font-bold text-xl mb-5 md:mb-8">{t("Personal Information")}</h5>
          <form
            className="flex w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10"
            onSubmit={this.handleSubmit}
          >
            <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
              <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("First Name")}
                    name="firstname"
                    placeholder={t("First Name")}
                    onChange={this.onChange}
                    value={firstname}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Last Name")}
                    name="lastname"
                    placeholder={t("Last Name")}
                    onChange={this.onChange}
                    value={lastname}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Street Address")+" 1"}
                    name="streetaddress1"
                    placeholder={t("Street Address")+" 1"}
                    onChange={this.onChange}
                    value={streetaddress1}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Street Address")+" 2"}
                    name="streetaddress2"
                    placeholder={t("Street Address")+" 2"}
                    onChange={this.onChange}
                    value={streetaddress2}
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("City")}
                    name="city"
                    placeholder={t("City")}
                    onChange={this.onChange}
                    value={city}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>

                <div className="mb-4">
                  {(this.props.states || []).length > 0 &&
                    <Select
                      variant="standard"
                      label={t("State")}
                      onChange={(data) => this.onChangeSelectItem("state", data)}
                      value={state}
                      color={client?.brColor || THEME_COLOR.BORDER}
                    >
                      {(this.props.states || []).map(({ Abbreviation, FullName }, index)=>
                        <Option key={`option-state-${index}`} value={Abbreviation}>{FullName}</Option>
                      )}
                    </Select>
                  }
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Zip Code")+" 5"}
                    name="zipcode"
                    placeholder={t("Zip Code")}
                    onChange={this.onChange}
                    value={zipcode}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                    type="number"
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Phone Number")}
                    name="mobilephone"
                    placeholder={t("Phone Number")}
                    onChange={this.onChange}
                    value={mobilephone}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                    type="number"
                  />
                </div>

                <div className="mb-4">
                  <Input
                    variant="standard"
                    label={t("Email Address")}
                    name="email"
                    placeholder={t("Email Address")}
                    onChange={this.onChange}
                    value={email}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                    type="email"
                    autoComplete="user-email"
                  />
                </div>
              </div>

              <div className="flex justify-start -ml-4">
                <Checkbox
                  name="emailannualpurchases"
                  onChange={this.onChange}
                  checked={emailannualpurchases}
                  type="checkbox"
                  color={client?.brColor || THEME_COLOR.BORDER}
                />
                <p className="text-sm font-medium text-navy-700 dark:text-white pt-3">
                  {t("I_would_like_to_receive_emails")}
                </p>
              </div>

              <h4 className="mb-5 mt-8 text-lg font-bold text-navy-700 dark:text-white md:flex leading-5">
                {t("auth:change_pwd")}
                <small className="ml-2 font-medium">({t("Leave_blank_if_you_want_to_keep_your_current_password")})</small>
              </h4>

              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mb-5">
                <Input
                  variant="standard"
                  label={t("auth:password")}
                  name="password"
                  placeholder={t("auth:password")}
                  onChange={this.onChange}
                  value={password}
                  color={client?.brColor || THEME_COLOR.BORDER}
                  type="password"
                  autoComplete="new-password"
                />

                <Input
                  variant="standard"
                  label={t("auth:confirm_pwd")}
                  name="confirm_password"
                  placeholder={t("auth:confirm_pwd")}
                  onChange={this.onChange}
                  value={confirm_password}
                  color={client?.brColor || THEME_COLOR.BORDER}
                  type="password"
                  autoComplete="new-confirm-password"
                />
              </div>

              <div className="flex justify-center">
                <Button
                  variant="gradient"
                  type="submit"
                  color={client?.brColor || THEME_COLOR.BORDER}
                >
                  {t("common:save")}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    client: sm.client.client || {},
    states: sm.states || [],
    stores: sm.stores || [],
    shopperid: sm.shopperid,
    shopper: sm.shopper,
    cardNum: sm.card.Number.toString(),
    balances: sm.balance.balances || [],
    targeted: sm.balance.targeted || [],
    accountid: sm.accountid,
    clippeds: sm.coupon.clippeds || [],
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  getShopper: getShopperThunk,
  editShopper: editShopperThunk,
  getCard: getCardThunk,
  getOffer: getOfferThunk,
  getClippedCoupons: getClippedCouponsThunk,
  getAvailableCoupons: getAvailableCouponsThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["profile"])(Shopper));