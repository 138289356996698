import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
 
export function ConfirmDialog(props) {
  const {
    title,
    description,
    id,
    open,
    handleOpen,
    action,
    color,
  } = props;

  const handleConfirm = async () => {
    try {
      await action(id)
      handleOpen()
    } catch(err) {

    }
  }

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        className="dark:bg-navy-800"
        open={open}
        handler={handleOpen}
      >
        <DialogHeader className="theme-bg-color dark:bg-navy-800 text-white rounded-t-lg">
          {title}
        </DialogHeader>
        <DialogBody className="dark:text-white">
          {description}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color={color}
            onClick={handleOpen}
            className="mr-3"
          >
            <span>{t("common:cancel")}</span>
          </Button>
          <Button 
            variant="gradient" 
            color={color}
            onClick={handleConfirm}>
            <span>{t("common:confirm")}</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}