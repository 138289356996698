import React from "react";
import moment from "moment/moment";
import Card from "components/card";
import { Typography} from "@material-tailwind/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { useMemo } from "react";
import Progress from "components/progress";
import { ConfirmDialog } from "./ConfirmDialog";
import { withTranslation } from "react-i18next";
import { COL_ACCESS, ROWS_PER_PAGE } from "variables/constants";
import { setToast } from "lib/toast";

const AboutTable = (props) => {
  const { columnsData, tableData, remove, openDetails, clearDetails, color, t } = props;

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(null);
  const handleOpen = () => setOpen(!open);

  const removeAbout = (id) => {
    setOpen(true)
    setId(id)
  }
  const action = (id) => {
    remove(id);
    clearDetails();
  }

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <>
      <ConfirmDialog
        title={t("Confirm delete")}
        description={t("Are you sure you want to delete this")+" "+t("aboutus").toLowerCase()+"?"}
        id={id}
        open={open}
        handleOpen={handleOpen}
        action={action}
        color={color}
      />
      <Card className={"w-full h-full p-4 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold theme-tx-color dark:text-white">
            {t("common:about_us")+" "+t("common:list")}
          </div>
        </div>

        <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                  <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  className="border-b py-4 border-gray-200 pr-8 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <Typography
                      variant="small"
                      textGradient
                      className="font-bold flex items-center leading-none text-gray-800 dark:text-white"
                    >
                      {column.render("Header")}{" "}
                      {index !== headerGroup.headers.length - 1 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                          />
                        </svg>
                      )}
                    </Typography>
                  </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr 
                    {...row.getRowProps()} 
                    key={index}
                    >
                    {row.cells.map((cell, cIndex) => {
                      let data = "";
                      if (cell.column.id === COL_ACCESS.USERNAME || cell.column.id === COL_ACCESS.CLIENT) {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === COL_ACCESS.EN || cell.column.id === COL_ACCESS.ES) {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value ? (
                                <MdCheckBox className="text-green-500" />
                              ) : (
                                <MdCheckBoxOutlineBlank className="text-orange-500" />
                              )}
                            </div>
                          </div>
                        );
                      } else if (cell.column.id === COL_ACCESS.ACTION) {
                        data = (
                          <div className="flex items-center gap-2">
                            <FaTrashAlt
                              className="cursor-pointer text-brand-500"
                              onClick={() => removeAbout(cell.value)}
                            />
                            <FaPencilAlt
                              className="cursor-pointer text-brand-500"
                              onClick={() => openDetails(tableData[index])}
                            />
                          </div>
                        );
                      } else if (cell.column.id === COL_ACCESS.START || cell.column.id === COL_ACCESS.END || cell.column.id === COL_ACCESS.DATE) {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {moment(cell.value).format('MM/DD/YY')}
                          </p>
                        );
                      } else if (cell.column.id === COL_ACCESS.PROGRESS) {
                        data = <Progress width="w-[68px]" value={cell.value} />;
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={cIndex}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

export default withTranslation(["admin"])(AboutTable);
