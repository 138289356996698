import React, { useState } from "react";
import { connect } from 'react-redux';
import { HexColorPicker } from "react-colorful";
import { Input, Button, Select, Option } from "@material-tailwind/react";
import { stateMappings } from "../../../redux/stateMappings";
import { getClientsThunk } from "../../../redux/client/thunks";
import { getCarouselsThunk, removeCarouselThunk, uploadCarouselThunk } from "../../../redux/admin/thunks";
import MiniCalendar from "components/calendar/MiniCalendar";
import Card from "components/card";
import FileUploadInput from "./components/FileUploadInput";
import CarouselTable from "./components/CarouselTable";
import Dropdown from "components/dropdown";
import { setToast } from "lib/toast";
import { THEME_COLOR, COL_ACCESS } from "variables/constants";
import { withTranslation } from "react-i18next";

const CarouselUploader = ({
  isAdmin,
  createCarousel,
  getCarousels,
  carousels,
  removeCarousel,
  client,
  getClients,
  clients,
  t
}) => {
  const [file, setFile] = useState(null);
  const [range, setRange] = React.useState();
  const [heading, setHeading] = React.useState();
  const [title, setTitle] = React.useState();
  const [text, setText] = React.useState();
  const [background, setBackground] = React.useState();
  const [clientId, setClientId] = React.useState();
  const columnsDataCheck = [
    {
      Header: t("image").toUpperCase(),
      accessor: COL_ACCESS.FILE,
    },
    {
      Header: t("start_date").toUpperCase(),
      accessor: COL_ACCESS.START,
    },
    {
      Header: t("end_date").toUpperCase(),
      accessor: COL_ACCESS.END,
    },
    {
      Header: t("heading").toUpperCase(),
      accessor: COL_ACCESS.HEADING,
    },
    {
      Header: t("title").toUpperCase(),
      accessor: COL_ACCESS.TITLE,
    },
    {
      Header: t("text").toUpperCase(),
      accessor: COL_ACCESS.TEXT,
    },
    {
      Header: t("background").toUpperCase(),
      accessor: COL_ACCESS.BACKGROUND,
    },
    // {
    //   Header: "CREATED AT",
    //   accessor: "createdAt",
    // },
    {
      Header: t("action").toUpperCase(),
      accessor: COL_ACCESS.ACTION,
    },
  ];

  React.useEffect(() => {
    getClients();
    getCarousels({ isAdmin });
  }, [isAdmin]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!range) {
      setToast('warning', t('please_select_the_date_range_in_the_calendar'))
      return
    }

    if (isAdmin && !clientId) {
      setToast('warning', t('please_select_the_client'))
      return
    }

    const success = await createCarousel(file, {
      start: range[0],
      end: range[1],
      heading,
      title,
      text,
      background,
      client: isAdmin? clientId : client._id,
    });

    if (!success) {
      return;
    }

    setRange(null);
    setHeading('');
    setTitle('');
    setText('');
    setBackground('');
    setFile(null);
    setClientId('');
  };

  return (
    <>
      <Card className="my-5 p-4">
        <h5 className="text-center font-bold theme-tx-color text-xl mb-5 md:mb-8">{t("carousel")+" "+t("common:information")}</h5>
        <form
          className="mt-5"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-5">
            <div className="col-span-1">
              <MiniCalendar
                className="flex items-center justify-center col-span-1"
                value={range}
                onChange={setRange}
              />
            </div>
            <div className="col-span-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                { isAdmin && 
                <div className="col-span-1 md:col-span-2">
                  <Select
                    variant="standard"
                    label={t("admin:client")+" "+t("admin:name")}
                    onChange={(value) => setClientId(value)}
                    defaultValue={clientId}
                    color={client?.brColor || THEME_COLOR.brColor}
                  >
                    {(clients || []).map((item, index)=>
                      <Option key={`client-${index}`} value={item._id}>{item.username}</Option>
                    )}
                  </Select>
                </div> }
                <div className="col-span-1">
                  <Input
                    name="heading"
                    variant="standard"
                    label={t("heading")}
                    placeholder="2/$9.00"
                    type="text"
                    onChange={(e) => setHeading(e.target.value)}
                    value={heading}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>
                <div className="col-span-1">
                  <Input
                    name="title"
                    variant="standard"
                    label={t("title")}
                    placeholder="Johnsonville Italian Sausage or Brats"
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>
                <div className="col-span-1">
                  <Input
                    name="text"
                    variant="standard"
                    label={t("text")}
                    placeholder="19 oz pkg"
                    type="text"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    required
                    color={client?.brColor || THEME_COLOR.BORDER}
                  />
                </div>
                <div className="col-span-1">
                  <Dropdown
                    button={
                      <Input
                        name="background"
                        variant="standard"
                        label={t("background")}
                        placeholder="#FFFFFF"
                        type="text"
                        onChange={(e) => setBackground(e.target.value)}
                        value={background}
                        readOnly
                        required
                        color={client?.brColor || THEME_COLOR.BORDER}
                      />
                    }
                    children={
                      <HexColorPicker color={background} onChange={setBackground} />
                    }
                    classNames={"z-51 col-span-2 left-[80px]"}
                  />
                </div>
              </div>
              <FileUploadInput
                className="mt-5"
                fileName={file?.name}
                onFileChange={handleFileChange}
                required
              />
            </div>
          </div>

          {file &&
            <div className="flex justify-center mb-5">
              <Button
                variant="gradient"
                type="submit"
                className="rounded-xl px-4"
                color={client?.brColor || THEME_COLOR.BORDER}
              >
                {t("Upload a File")}
              </Button>
            </div>
          }
        </form>
      </Card>

      <CarouselTable
        columnsData={columnsDataCheck}
        tableData={carousels}
        remove={removeCarousel}
        color={client?.brColor || THEME_COLOR.BORDER}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    isAdmin: sm.isAdmin,
    carousels: sm.admin.carousels || [],
    theme: sm.user.theme,
    client: sm.client.client || {},
    clients: sm.client.clients || []
  };
};

const mapActionsToProps = {
  getClients: getClientsThunk,
  getCarousels: getCarouselsThunk,
  createCarousel: uploadCarouselThunk,
  removeCarousel: removeCarouselThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(['admin'])(CarouselUploader));