//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';


export const getBalance = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/balance',
  });

export const getOffer = async (includeuntargeted) =>
  ApiRequest.request({
    method: 'GET',
    url: 'offer',
    params: {
      includeuntargeted
    }
  });
