import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Card from "components/card";
import Checkbox from "components/checkbox";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const CategoryCard = ({ className, title, data, categoryType, filters, categories, onCategoryChange, color }) => {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <Card className={`!z-5 overflow-hidden px-4 ${className || ''}`}>
      <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader
          className="text-lg font-bold border-none theme-tx-color dark:text-white"
          onClick={() => handleOpen(1)}
        >
          {title}
        </AccordionHeader>
        <AccordionBody className="pt-0">
          {categories.map((category, index) =>
            <div
              key={`category-checkbox-${index}`}
              className="flex items-center mb-1"
            >
              <Checkbox
                checked={filters.includes(category)}
                onChange={() => onCategoryChange(categoryType, category)}
                color={color}
              />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                {`${category} (${data[category].length})`}
              </p>
            </div>
          )}
        </AccordionBody>
      </Accordion>
    </Card>
  );
};

export default CategoryCard;
