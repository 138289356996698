// Chakra Imports
// Custom Icons
import React from "react";
import { connect } from 'react-redux';
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { stateMappings } from "../../redux/stateMappings";
import { setThemeModeThunk } from "../../redux/user/thunks";

const FixedPlugin = (props) => {
  const { theme, setThemeMode, ...rest } = props;

  return (
    <button
      className="border-px fixed bottom-[30px] right-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[#6a53ff] bg-gradient-to-br from-brandLinear to-blueSecondary p-0"
      onClick={() => setThemeMode(theme === "light" ? "dark" : "light")}
      {...rest}
    >
      {/* // left={document.documentElement.dir === "rtl" ? "35px" : ""}
      // right={document.documentElement.dir === "rtl" ? "" : "35px"} */}
      <div className="cursor-pointer text-gray-600">
        {theme === 'dark' ? (
          <RiSunFill className="h-4 w-4 text-white" />
        ) : (
          <RiMoonFill className="h-4 w-4 text-white" />
        )}
      </div>
    </button>
  );
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    theme: sm.user.theme,
  };
};

const mapActionsToProps = {
  setThemeMode:setThemeModeThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(FixedPlugin);