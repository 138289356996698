export const DEFAULT_COLORS = [
  "blue-gray",
  "gray",
  "brown",
  "deep-orange",
  "orange",
  "amber",
  "yellow",
  "lime",
  "light-green",
  "green",
  "teal",
  "cyan",
  "light-blue",
  "blue",
  "indigo",
  "deep-purple",
  "purple",
  "pink",
  "red",
];

export const STEVES_THEME = {
  checkbox: {
    defaultProps: {
      color: "blue",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: DEFAULT_COLORS,
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-3",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-md",
          borderColor: "border-gray-500",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-blue-gray-500",
            width: "before:w-12",
            height: "before:h-12",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          color: "text-white",
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        "blue-gray": {
          background: "checked:bg-blue-gray-500",
          border: "checked:border-blue-gray-500",
          before: "checked:before:bg-blue-gray-500",
        },
        gray: {
          background: "checked:bg-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
        brown: {
          background: "checked:bg-brown-500",
          border: "checked:border-brown-500",
          before: "checked:before:bg-brown-500",
        },
        "deep-orange": {
          background: "checked:bg-deep-orange-500",
          border: "checked:border-deep-orange-500",
          before: "checked:before:bg-deep-orange-500",
        },
        orange: {
          background: "checked:bg-orange-500",
          border: "checked:border-orange-500",
          before: "checked:before:bg-orange-500",
        },
        amber: {
          background: "checked:bg-amber-500",
          border: "checked:border-amber-500",
          before: "checked:before:bg-amber-500",
        },
        yellow: {
          background: "checked:bg-yellow-500",
          border: "checked:border-yellow-500",
          before: "checked:before:bg-yellow-500",
        },
        lime: {
          background: "checked:bg-lime-500",
          border: "checked:border-lime-500",
          before: "checked:before:bg-lime-500",
        },
        "light-green": {
          background: "checked:bg-light-green-500",
          border: "checked:border-light-green-500",
          before: "checked:before:bg-light-green-500",
        },
        green: {
          background: "checked:bg-green-500",
          border: "checked:border-green-500",
          before: "checked:before:bg-green-500",
        },
        teal: {
          background: "checked:bg-teal-500",
          border: "checked:border-teal-500",
          before: "checked:before:bg-teal-500",
        },
        cyan: {
          background: "checked:bg-cyan-500",
          border: "checked:border-cyan-500",
          before: "checked:before:bg-cyan-500",
        },
        "light-blue": {
          background: "checked:bg-light-blue-500",
          border: "checked:border-light-blue-500",
          before: "checked:before:bg-light-blue-500",
        },
        blue: {
          background: "checked:bg-blue-500",
          border: "checked:border-blue-500",
          before: "checked:before:bg-blue-500",
        },
        indigo: {
          background: "checked:bg-indigo-500",
          border: "checked:border-indigo-500",
          before: "checked:before:bg-indigo-500",
        },
        "deep-purple": {
          background: "checked:bg-deep-purple-500",
          border: "checked:border-deep-purple-500",
          before: "checked:before:bg-deep-purple-500",
        },
        purple: {
          background: "checked:bg-purple-500",
          border: "checked:border-purple-500",
          before: "checked:before:bg-purple-500",
        },
        pink: {
          background: "checked:bg-pink-500",
          border: "checked:border-pink-500",
          before: "checked:before:bg-pink-500",
        },
        red: {
          background: "checked:bg-red-500",
          border: "checked:border-red-500",
          before: "checked:before:bg-red-500",
        },
      },
    },
  },
};