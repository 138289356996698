import {
  SIGNIN_USER,
  SIGNOUT_USER,
  REGISTER_USER,
  SET_PASSWORD_RESET_TOKEN,
  SET_STORES,
  SET_STATES,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE
} from './actionEnum';

export const registerUser = (response) => ({
  type: REGISTER_USER,
  payload: response
});

export const signInUser = (response) => ({
  type: SIGNIN_USER,
  payload: response,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});

export const signOutUser = (response = {}) => ({
  type: SIGNOUT_USER,
  payload: response,
});

export const setPasswordResetToken = (response) => ({
  type: SET_PASSWORD_RESET_TOKEN,
  payload: response,
});

export const setStates = (states) => ({
  type: SET_STATES,
  payload: states,
});

export const setStores = (stores) => ({
  type: SET_STORES,
  payload: stores,
});
