import React, { Component } from "react";
import { connect } from 'react-redux';
import { stateMappings } from "../../../redux/stateMappings";
import StoreCard from "./components/StoreCard";
import MapCard from "./components/MapCard";
import { editAccountThunk } from "../../../redux/user/thunks";
import { setToast } from "lib/toast";

class Stores extends Component {
  constructor(props) {
    super(props);
  }

  selectStore = async (storeId) => {
    const {
      isAuthenticated,
      editAccount,
      account,
      accountid
    } = this.props

    if (!isAuthenticated) {
      setToast('error', 'Please sign in first to select your store.')
      return
    }

    try {
      await editAccount({
        ...account,
        accountid: accountid,
        homestoreid: storeId
      }, 'Your store has been selected successfully.')
    } catch {

    }
  }

  render() {
    const { account, stores } = this.props

    return (
      <>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
          <div className="col-span-1 h-full w-full md:col-span-1">
            <StoreCard
              stores={stores}
              selectedStore={account.homestoreid}
              selectStore={this.selectStore}
            />
          </div>
          <div className="col-span-1 h-fit w-full md:col-span-2">
            <MapCard
              stores={stores}
              selectedStore={account.homestoreid}
            />
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    accountid: sm.accountid,
    account: sm.account,
    stores: sm.stores || []
  };
};

const mapActionsToProps = {
  editAccount: editAccountThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Stores);
