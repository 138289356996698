import {
  getAccount,
  putAccount,
  getShopper,
  putShopper,
} from './apiCalls';
import {
  setShopper,
  editShopper,
  setErrorMessage,
  setLoadingStatus,
  setAccount,
  editAccount,
  setThemeMode,
} from './actions';
import { setToast } from '../../lib/toast';
import { resetApiResponseByLowerCase } from '../../lib/mapping';

export const getAccountThunk = (accountid) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getAccount(accountid);
    const { data: accountData, result } = data || {};
    const { account } = accountData || {};

    if (account && result === "OK") {
      dispatch(setAccount(resetApiResponseByLowerCase(account)));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    // setToast('error', error.response.data?.message);
  }
};

export const editAccountThunk = (account, msg = '') => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    await putAccount(account);

    dispatch(editAccount(account));
    dispatch(setStatus(false, ''));
    setToast('success', msg || 'admin:msg_account_updated');
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};


export const getShopperThunk = (signOut = null, loading = true) => async (
  dispatch,
) => {
  dispatch(setStatus(loading, ''));

  try {
    const { data } = await getShopper();
    const { data: shopperData, result } = data || {};
    const { shopper, admin } = shopperData || {};

    if (shopper && result === "OK") {
      dispatch(setShopper({
        shopper: resetApiResponseByLowerCase(shopper),
        admin
      }));
    }

    dispatch(setStatus(false, ''));

    return shopper;
  } catch (error) {
    if (signOut) {
      return signOut();
    }

    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return null;
};

export const editShopperThunk = ({ email, mobilephone, ...shopper }) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await putShopper({
      ...shopper,
      emailaddress: email,
      mobilephonenumber: mobilephone,
    });
    const { data: editedShopper, result } = data || {};

    if (editedShopper && result === "OK") {
      dispatch(editShopper(resetApiResponseByLowerCase(editedShopper)));
      setToast('success', 'admin:msg_profile_updated');
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const setThemeModeThunk = (mode) => (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    if (mode === 'dark') {
      document.body.classList.add("dark")
    } else {
      document.body.classList.remove("dark")
    }
    dispatch(setThemeMode(mode));
    dispatch(setStatus(false, ''));
    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
  }

  return false;
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
