import {
  SET_AVAILABLE_COUPONS,
  SET_CLIPPED_COUPONS,
  ADD_CLIPPED_COUPONS,
  SET_ERROR_MESSAGE,
  SET_LOADING_STATUS,
  SET_AVAILABLE_LOADING_STATUS
} from './actionEnum';

export const setAvailableCoupons = (coupons) => ({
  type: SET_AVAILABLE_COUPONS,
  payload: coupons,
});

export const setClippedCoupons = (coupons) => ({
  type: SET_CLIPPED_COUPONS,
  payload: coupons,
});

export const addClippedCoupons = (coupons) => ({
  type: ADD_CLIPPED_COUPONS,
  payload: coupons,
});

export const setLoadingStatus = (loading) => ({
  type: SET_LOADING_STATUS,
  payload: loading,
});

export const setAvailableLoadingStatus = (loading) => ({
  type: SET_AVAILABLE_LOADING_STATUS,
  payload: loading,
});

export const setErrorMessage = (errMsg) => ({
  type: SET_ERROR_MESSAGE,
  payload: errMsg,
});