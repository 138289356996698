
import styled from 'styled-components';
import Card from "components/card";
import { AiOutlineShoppingCart } from "react-icons/ai";
import UrbanaImg from "assets/img/stores/Urbana.png";
import LewisburgImg from "assets/img/stores/Lewisburg.jpg";

const StoreCard = ({ stores, selectedStore, selectStore }) => {
  return (
    <Card className={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold theme-tx-color dark:text-white">
          All stores
        </h4>
        <p className="mt-2 text-base text-gray-600">
          Here you can select your store.
        </p>
      </div>

      {stores.map(({ ID, StoreNumber, StoreName, PostalCode, StoreCity }, index) =>
        <div
          key={`stevesmarket-store-${index}`}
          className={`mt-3 flex w-full items-center justify-between cursor-pointer rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none${selectedStore === ID ? ' border-1 border-brand-500' : ''}`}
          onClick={() => selectStore(ID)}
        >
          <div className="flex items-center">
            <div className="">
              <div className="flex items-center justify-center h-[83px] w-[83px] rounded-lg bg-gray-200">
                {/* <AiOutlineShoppingCart className="w-8 h-8" /> */}
                <img
                  src={ID === 1 ? UrbanaImg : LewisburgImg}
                  alt=""
                />
              </div>
            </div>
            <div className="ml-4">
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {StoreName}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                {`Store Num # ${StoreNumber}`}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                Zip Code .
                <span
                  className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                >
                  {PostalCode}
                </span>
              </p>
              <p className="mt-2 text-sm text-gray-600">
                City .
                <span
                  className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                >
                  {StoreCity}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default StoreCard;
