import React, { Component } from "react";
import { connect } from 'react-redux';
import { Input } from "@material-tailwind/react";
import { THEME_COLOR } from "variables/constants";
import Card from "components/card";
import { stateMappings } from "../../../../redux/stateMappings";
import { withTranslation } from "react-i18next";

const Client = ({ className, client, t }) => {

  return (
    <>
      <Card className={className}>
        <h5 className="text-center font-bold text-xl mb-5 md:mb-8">{t("Client Information")}</h5>
        <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0">
          <div className="mb-4">
            <Input
              variant="standard"
              label={t("auth:username")}
              name="username"
              placeholder={t("auth:username")}
              value={client?.username}
              color={client?.brColor || THEME_COLOR.brColor}
            />
          </div>
        </div>
      </Card>
    </>
  ); 
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    client: sm.user.admin?.client,
    theme: sm.user.theme
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation(["profile"])(Client));