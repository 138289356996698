import React, { useState } from "react";
import { connect } from 'react-redux';
import { stateMappings } from "../../../redux/stateMappings";
import { acceptInviteThunk } from "../../../redux/admin/thunks";
import { setShopperThunk } from "../../../redux/auth/thunks";
import { withRouter } from "lib/withRouter";

const AcceptInvite = ({
  isAuthenticated,
  setShopper,
  acceptInvite,
  params
}) => {
  const [status, setStatus] = useState({});

  React.useEffect(async () => {
    const data = await acceptInvite(params?.token);
    setStatus(data);

    if (data.success && isAuthenticated) {
      setShopper();
    }
  }, []);

  return (
    <>
      <div className="mt-[50px]">
        <p className={`text-center text-${status.success ? 'brand' : 'gray'}-500 text-3xl`}>{status?.message || ''}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  acceptInvite: acceptInviteThunk,
  setShopper: setShopperThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(AcceptInvite));
