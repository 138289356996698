import {
  SET_SPECIALS,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const defaultState = {
  specials: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_SPECIALS:
      return { ...state, specials: action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
