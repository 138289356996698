import React from "react";
import Balances from "./Balances";
import PerksCard from "./PerksCard";
import { withTranslation } from "react-i18next";

const Perks = ({ t }) => {
  return (
    <>
      <h4 className="mt-5 text-center text-3xl font-bold theme-tx-color dark:text-white">
        Steve's PERKS®
      </h4>
      <p className="mb-5 text-center text-sm text-gray-400">
        {t("Save_money_on_gas")}
      </p>

      <Balances className="w-full py-[32px] px-[16px] mb-5" />
      <PerksCard className="w-full py-[32px] px-[16px] mb-5" />
    </>
  );
};

export default withTranslation(["profile"])(Perks);
