import React, { Component } from "react";
import { connect } from 'react-redux';
import { Radio } from "@material-tailwind/react";
import { stateMappings } from "../../../../redux/stateMappings";
import { setThemeModeThunk } from "../../../../redux/user/thunks";
import Card from "components/card";
import { withTranslation } from "react-i18next";
import { THEME_COLOR } from "variables/constants";

class Appearance extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  onChange = ({ target: { value } }) => {
    const { setTheme } = this.props
    setTheme(value)
  }

  render() {
    const { theme, className, client, t } = this.props;

    return (
      <>
        <Card className={className}>
          <h5 className="text-center font-bold text-xl mb-5">{t("common:appearance")}</h5>
          <h4 className="mt-5 text-lg font-bold text-navy-700 dark:text-white">
            {t("common:modes").toUpperCase()}
          </h4>
          <div className="mt-5 flex gap-10">
            <Radio
              name="type"
              label={t("common:light")}
              color={client.brColor || THEME_COLOR.brColor}
              value="light"
              checked={theme === 'light'}
              onChange={this.onChange}
            />
            <Radio
              name="type"
              label={t("common:dark")}
              color={client.brColor || THEME_COLOR.brColor}
              value="dark"
              checked={theme === 'dark'}
              onChange={this.onChange}
            />
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    theme: sm.user.theme,
    client: sm.client.client || {},
  };
};

const mapActionsToProps = {
  setTheme: setThemeModeThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation()(Appearance));
