import {
  SET_ADMINS,
  ADD_ADMIN,
  EDIT_ADMIN,
  DELETE_ADMIN,
  SET_ABOUTS,
  SET_ABOUT,
  ADD_ABOUT,
  EDIT_ABOUT,
  DELETE_ABOUT,
  SET_ADS,
  ADD_AD,
  DELETE_AD,
  SET_CAROUSELS,
  ADD_CAROUSEL,
  DELETE_CAROUSEL,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

export const setAdmins = (admins) => ({
  type: SET_ADMINS,
  payload: admins,
});

export const addAdmin = (admin) => ({
  type: ADD_ADMIN,
  payload: admin,
});

export const editAdmin = (admin) => ({
  type: EDIT_ADMIN,
  payload: admin,
});

export const deleteAdmin = (id) => ({
  type: DELETE_ADMIN,
  payload: id,
});

export const setAbouts = (abouts) => ({
  type: SET_ABOUTS,
  payload: abouts,
});

export const setAbout = (about) => ({
  type: SET_ABOUT,
  payload: about,
});

export const addAbout = (about) => ({
  type: ADD_ABOUT,
  payload: about,
});

export const editAbout = (about) => ({
  type: EDIT_ABOUT,
  payload: about,
});

export const deleteAbout = (id) => ({
  type: DELETE_ABOUT,
  payload: id,
});

export const setAds = (ads) => ({
  type: SET_ADS,
  payload: ads,
});

export const addAd = (ad) => ({
  type: ADD_AD,
  payload: ad,
});

export const deleteAd = (id) => ({
  type: DELETE_AD,
  payload: id,
});

export const setCarousels = (carousels) => ({
  type: SET_CAROUSELS,
  payload: carousels,
});

export const addCarousel = (carousel) => ({
  type: ADD_CAROUSEL,
  payload: carousel,
});

export const deleteCarousel = (id) => ({
  type: DELETE_CAROUSEL,
  payload: id,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});

