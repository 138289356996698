import React from "react";
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Card from "components/card";
import { stateMappings } from "../../../../redux/stateMappings";
import { StoreNameComp } from "views/default/stores/components/StoreNameComp";
import { withTranslation } from "react-i18next";

const STORE_POSITIONS = {
  1: {
    lat: 40.1109413,
    lng: -83.7525035
  },
  2: {
    lat: 40.2229802,
    lng: -83.5531217
  }
}

const MyStore = ({
  className,
  store: {
    ID,
    StoreNumber,
    StoreName,
    PostalCode,
    StoreCity
  },
  t,
}) => {
  return (
    <>      
      <Card className={className}>
        <h5 className="text-center font-bold text-xl mb-5">{t("My Store")}</h5>
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="flex items-center justify-center col-span-1 md:col-span-5">
            <div className="text-center mb-5 md:mb-0">
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {StoreName}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                {t("Store Num #", {number: StoreNumber})}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                {t("Zip Code")} .
                <span
                  className="ml-1 font-medium theme-tx-color dark:text-white"
                >
                  {PostalCode}
                </span>
              </p>
              <p className="mt-2 text-sm text-gray-600">
                {t("City")} .
                <span
                  className="ml-1 font-medium theme-tx-color dark:text-white"
                >
                  {StoreCity}
                </span>
              </p>
              <p className="mt-3 text-sm">
                <Link to="/stores" className="underline theme-tx-color-hover">{t("Change My Store")}</Link>
              </p>
            </div>
          </div>
          <div className="col-span-1 md:col-span-7">
            <div style={{ height: '300px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao" }}
                defaultCenter={STORE_POSITIONS[ID]}
                defaultZoom={9}
              >
                <StoreNameComp
                  text={StoreName}
                  selected
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    store: (sm.stores || []).find(store => store?.ID === sm.account?.homestoreid) || {}
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(withTranslation(["profile"])(MyStore));
