import React from "react";
import moment from "moment/moment";
import Card from "components/card";
import { Typography, Button, ButtonGroup, Input, Select, Option} from "@material-tailwind/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdOutlineError, MdCancel, MdPreview } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { useMemo } from "react";
import Progress from "components/progress";
import { ConfirmDialog } from "./ConfirmDialog";
import { COL_ACCESS, APP_STATUS, ROWS_PER_PAGE } from "variables/constants";
import { withTranslation } from "react-i18next";

const AppTable = (props) => {
  const { columnsData, tableData, remove, openDetails, color, t } = props;

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(null);
  const handleOpen = () => setOpen(!open);

  const removeAdmin = (id) => {
    setOpen(true)
    setId(id)
  }

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setGlobalFilter,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;
  // Change the page index.
  const onChange = ({ target: { value } }) => {
    const page = value ? Number(value) - 1 : 0;
    gotoPage(page);
  }
  // Change rows count per page.
  const onChangeSelectItem = (value) => {
    setPageSize(Number(value));
  }

  const statusText = (status) => {
    switch(status){
      case APP_STATUS.ACCEPTED:
        return t("admin:accepted");
      case APP_STATUS.INREVIEW:
        return t("admin:in_review");
      case APP_STATUS.REJECTED:
        return t("admin:rejected");
    }
  }

  return (
    <>
      <ConfirmDialog
        title={t("Confirm delete")}
        description={t("Are you sure you want to delete this")+" "+t("common:application").toLowerCase()+"?"}
        id={id}
        open={open}
        handleOpen={handleOpen}
        action={remove}
        color={color}
      />
      <Card className={"w-full h-full p-4 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold theme-tx-color dark:text-white">
            {t("admin:applications")}
          </div>
        </div>

        <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <div className="flex justify-center md:justify-end mb-2">
            <div className="w-full md:max-w-[250px]">
              <Input
                name="search"
                variant="outlined"
                type="text"
                label={t("common:search")}
                placeholder={t("common:search")+"..."}
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                size="lg"
                color={color}
              />
            </div>
          </div>
          <table {...getTableProps()} className="w-full">
            <thead className="border-b border-gray-100 bg-gray-50 p-4 dark:bg-navy-900">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b py-4 border-gray-200 pr-8 pb-[10px] text-start dark:!border-navy-700"
                    >
                      <Typography
                        variant="small"
                        textGradient
                        className="font-bold flex items-center leading-none text-gray-800 dark:text-white"
                      >
                        {column.render("Header")}{" "}
                        {index !== headerGroup.headers.length - 1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    className="cursor-pointer hover:bg-gray-100  dark:hover:bg-navy-900"
                    {...row.getRowProps()}
                    key={index}
                    onClick={() => openDetails(tableData[index])}
                  >
                    {row.cells.map((cell, cIndex) => {
                      let data = "";
                      if (cell.column.id === COL_ACCESS.NAME || cell.column.id === COL_ACCESS.EMAIL) {
                        data = (
                          <Typography variant="small" className="font-semibold text-navy-700 dark:text-white">
                            {cell.value || ''}
                          </Typography>
                        );
                      } else if (cell.column.id === COL_ACCESS.STATUS) {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === APP_STATUS.ACCEPTED &&
                                <MdCheckCircle className="text-green-500" />
                              }
                              {cell.value === APP_STATUS.INREVIEW &&
                                <MdOutlineError className="text-orange-500" />
                              }
                              {cell.value === APP_STATUS.REJECTED &&
                                <MdCancel className="text-brand-500" />
                              }
                            </div>
                            <p className="text-sm font-semibold text-navy-700 dark:text-white">
                              {statusText(cell.value)}
                            </p>
                          </div>
                        );
                      } else if (cell.column.id === COL_ACCESS.ACTION) {
                        data = (
                          <div className="flex items-center gap-3">
                            <MdPreview
                              className="w-6 h-6 cursor-pointer text-green-500"
                              onClick={() => openDetails(tableData[index])}
                            />
                            <FaTrashAlt
                              className="w-4 h-4 cursor-pointer text-brand-500"
                              onClick={(e) => {
                                e.stopPropagation()
                                removeAdmin(cell.value)
                              }}
                            />
                          </div>
                        );
                      } else if (cell.column.id === COL_ACCESS.DATE) {
                        data = (
                          <Typography variant="small" color="blue-gray" className="font-semibold text-navy-700 dark:text-white">
                            {moment(cell.value).format('MM/DD/YY')}
                          </Typography>
                        );
                      } else if (cell.column.id === COL_ACCESS.PROGRESS) {
                        data = <Progress width="w-[68px]" value={cell.value} />;
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px] p-4"
                          {...cell.getCellProps()}
                          key={cIndex}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex items-center md:justify-center mt-8 text-center gap-4">
            <ButtonGroup color={color}>
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </ButtonGroup>
            <span>
              {t("common:page")}{" "}
              <strong>{pageIndex + 1}</strong>{" "}
              {t("common:of").toLowerCase()}{" "}
              <strong>{pageOptions.length}</strong>
            </span>
            <div>
              <Input
                type="number"
                min={1}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                placeholder={t("common:page")+" "+t("common:index")}
                onChange={onChange}
                color={color}
              />
            </div>
            <div>
              <Select 
                value={pageSize.toString()} 
                onChange={(val) => onChangeSelectItem(val)}
                color={color}
              >
                {ROWS_PER_PAGE.map(pageSize => (
                  <Option key={pageSize} value={pageSize} className="text-left">
                    {t("common:show_rows", {count: pageSize})}
                  </Option>
                ))}
              </Select>
            </div>
            <ButtonGroup color={color}>
              <Button 
                onClick={nextPage} 
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Card>
    </>
  );
};

export default withTranslation(["admin"])(AppTable);
