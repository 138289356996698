import React, { Component } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TbCoins } from "react-icons/tb";
import Widget from "components/widget/Widget";
import Banner from "./components/Banner";
import CouponsPanel from "./components/CouponsPanel";
import { stateMappings } from "../../../redux/stateMappings";
import {
  getClippedCouponsThunk,
  getAvailableCouponsThunk,
  addClippedCouponsThunk
} from "../../../redux/coupon/thunks";
import { getBalanceThunk } from "../../../redux/balance/thunks";
import { COUPON_STATUS, BALANCE_TYPE } from "variables/constants";
import { withTranslation } from "react-i18next";

class Coupons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel: COUPON_STATUS.AVAILABLE,
    }
  }

  setPanelName = (name) => {
    this.setState({
      panel: name,
    })
  }

  async componentDidMount() {
    const {
      isAuthenticated,
      getAvailableCoupons,
      getBalance,
      accountid
    } = this.props;

    try {
      await Promise.all([
        isAuthenticated ? getBalance() : undefined,
        getAvailableCoupons(isAuthenticated ? accountid : 1),
      ]);
    } catch {
      
    }
  }

  render() {
    const { panel } = this.state;
    const {
      isAuthenticated,
      availables,
      clippeds,
      clip,
      balance,
      getBalance,
      t,
    } = this.props;

    return (
      <>
        <div className="mt-5">
          <Banner />
          <div className="mb-4 mt-4 grid grid-cols-1 md:grid-cols-12">
            <Tab className="mt-0 col-span-1 md:col-span-8 lg:col-span-10 md:mt-3">
              <ul className="mb-4 flex items-center justify-center gap-2 md:mb-0 md:justify-start md:!gap-5 2xl:!gap-12">
                <li
                  className={`text-center cursor-pointer p-0 sm:p-5${panel === COUPON_STATUS.AVAILABLE ? ' active' : ''}`}
                  onClick={() => this.setPanelName(COUPON_STATUS.AVAILABLE)}
                >
                  <span
                    className="text-base font-medium theme-tx-color theme-tx-color-hover dark:text-white"
                  >
                    {`${t("available")} ${t("coupons")} (${availables.length})`}
                  </span>
                </li>
                {isAuthenticated &&
                  <li
                    className={`text-center cursor-pointer p-0 sm:p-5${panel === COUPON_STATUS.CLIPPED ? ' active' : ''}`}
                    onClick={() => this.setPanelName(COUPON_STATUS.CLIPPED)}
                  >
                    <span
                      className="text-base font-medium theme-tx-color theme-tx-color-hover dark:text-white"
                    >
                      {`${t("clipped")} ${t("coupons")} (${clippeds.length})`}
                    </span>
                  </li>
                }
              </ul>
            </Tab>
            {isAuthenticated &&
              <div className="col-span-1 md:col-span-4 lg:col-span-2">
                <Widget
                  icon={<TbCoins className="h-7 w-7" />}
                  title={t("points")}
                  subtitle={balance}
                />
              </div>
            }
          </div>

          {panel === COUPON_STATUS.AVAILABLE &&
            <CouponsPanel
              coupons={availables}
              clip={clip}
              className={panel === COUPON_STATUS.AVAILABLE ? '' : 'hidden'}
              balance={balance}
              getBalance={getBalance}
              isAuthenticated={isAuthenticated}
            />
          }

          {isAuthenticated &&
            <>
              {panel === COUPON_STATUS.CLIPPED &&
                <CouponsPanel
                  coupons={clippeds}
                  className={panel === COUPON_STATUS.CLIPPED ? '' : 'hidden'}
                  balance={balance}
                  getBalance={getBalance}
                  isAuthenticated={isAuthenticated}
                />
              }
            </>
          }
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    accountid: sm.accountid,
    availables: sm.coupon.availables || [],
    clippeds: sm.coupon.clippeds || [],
    balance: (sm.balance.balances || []).find(balance => balance.BalanceType === BALANCE_TYPE.POINTS)?.BalanceAmount || 0,
  };
};

const mapActionsToProps = {
  getClippedCoupons: getClippedCouponsThunk,
  getAvailableCoupons: getAvailableCouponsThunk,
  clip: addClippedCouponsThunk,
  getBalance: getBalanceThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["common"])(Coupons));

const Tab = styled.div`
  .active {
    border-bottom: 2px solid #e1392a;

    span {
      font-weight: 600;
      color: #e1392a;
    }
  }
`