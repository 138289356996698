import Cookies from 'js-cookie';
import axios from 'axios';

import config from '../config';

const DEFAULT_HEADERS = {
  // 'Content-Type': 'application/json'
};

export const getToken = () => {
  let token = Cookies.get('shopper_token');

  if (!token) {
    const shopper = JSON.parse(localStorage.getItem('shopper') || '{}');
    token = shopper.token;
  }

  return token || null;
};

export const setToken = (shopper) => {
  localStorage.setItem('shopper', JSON.stringify(shopper));

  Cookies.set(`shopper_token`, shopper.token, {
    domain: `.${config.domain}`,
    expires: undefined,
  });
};

export const removeToken = () => {
  localStorage.removeItem('shopper');
  
  Cookies.remove('shopper_token', {
    domain: `.${config.domain}`,
    expires: undefined,
  });
}

const getHeaders = () => {
  let header = {
    ...DEFAULT_HEADERS
  };

  const shopper_token = getToken();

  if (shopper_token) {
    header = {
      ...header,
      'authorization': `Bearer ${shopper_token}`,
    }
  }

  return header;
};

export let ApiRequest = axios.create({
  baseURL: config.api_url,
  headers: getHeaders()
});

export let ApiRequestWithoutToken = axios.create({
  baseURL: config.api_url
});

export const refreshInstance = () =>
  (ApiRequest = axios.create({
    baseURL: config.api_url,
    headers: getHeaders()
  }));
