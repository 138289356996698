import { combineReducers } from "redux";
import client from "./client/reducer";
import auth from "./auth/reducer";
import user from "./user/reducer";
import card from "./card/reducer";
import balance from "./balance/reducer";
import coupon from "./coupon/reducer";
import point from "./point/reducer";
import admin from "./admin/reducer";
import application from "./application/reducer";

const rootReducer = combineReducers({
  client,
  auth,
  user,
  card,
  balance,
  coupon,
  point,
  admin,
  application
});

export default rootReducer;
