import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Select, Option, Checkbox, Textarea } from "@material-tailwind/react";
import JobInterviewImg from "assets/img/stores/interview.jpg"
import Card from "components/card";
import { stateMappings } from "../../../redux/stateMappings";
import { createApplicationThunk } from '../../../redux/application/thunks';
import { applicationComplex } from './variables/applicationComplex';
import { withTranslation } from "react-i18next";
import { THEME_COLOR } from 'variables/constants';

const Employment = ({ createApp, client, t }) => {

  const [data, setData] = React.useState({});
  const [disable, setDisable] = React.useState(false);

  const onChange = (e) => {
    const { target: { name, value, type, checked } } = e

    setData({
      ...data,
      [name]: type !== 'checkbox' ? value : checked,
    })
  }

  const onSelectChange = (value, name) => {
    setData({
      ...data,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await createApp({
        details: data,
        status: 'In review'
      });
    } catch {

    }

    setDisable(true)
  };

  const brColor = client.brColor || THEME_COLOR.BORDER;

  return (
    <>
      <section
        className="mt-5 bg-cover w-full h-[150px] lg:h-[400px]"
        style={{ backgroundImage: `url(${JobInterviewImg})`, backgroundPosition: 'bottom' }}
      >
        <div
          className="flex justify-center items-center w-full h-full"
          style={{ background: 'rgba(0,0,0,0.4)'}}
        >
          <div>
            <p className="uppercase text-white font-bold text-2xl md:text-5xl lg:text-7xl">{t("we_are_hiring")}</p>
          </div>
        </div>
      </section>
      <h4 className="mt-5 mb-5 text-center text-3xl font-bold text-navy-700 dark:text-white invisible">
        {t("employment_application")}
      </h4>
      {/* <p className="text-center text-xl lg:text-2xl dark:text-white">
        Please download <a className="text-brand-500 underline hover:no-underline" href={EmpPDF} download>Steves Market Employment Application PDF</a> file. You'll need an Adobe Reader required for this form to function properly. It is available for free download <a className="text-brand-500 underline hover:no-underline" href="https://get.adobe.com/reader" target="_blank">here</a>.
      </p> */}

      <Card className="w-full py-[32px] px-[16px] mb-5">
        <form onSubmit={handleSubmit}>
          {applicationComplex.map((em, index) =>
            <div
              key={`employment-complex-${index}`}
              className="mb-5"
            >
              {em.label && <h5 className={`mb-3 ${em.style || ''}`}><strong>{t(em.label)}</strong></h5>}
              {em.descriptions && 
              <ul>
                {(em.descriptions || []).map((dc, dIndex) => {
                  return <li key={`em-dc-${dIndex}`} className="text-gray-500">{t(dc)}</li>
                })}  
              </ul>}
              <div className={`grid grid-cols-1 md:grid-cols-${em.span} gap-5`}>
                {(em.child || []).map((ch, index) => {
                  return (
                    <div key={`em-child-${index}`}>
                      {ch.component === 'input' &&
                        <Input
                          variant="standard"
                          name={ch.name}
                          label={t(ch.label)}
                          color={brColor}
                          onChange={onChange}
                          required={ch.required}
                        /> 
                      }
                      {ch.component === 'checkbox' &&
                        <Checkbox
                          label={t(ch.label)}
                          name={ch.name}
                          color={brColor}
                          onChange={onChange}
                        />
                      }
                      {ch.component === 'select' &&
                        <Select
                          variant="standard"
                          name={ch.name}
                          label={t(ch.label)}
                          color={brColor}
                          onChange={(value) => onSelectChange(value, ch.name)}
                        >
                          {(ch.options || []).map((op, oIndex) =>
                            <Option key={`ch-option-${oIndex}`} value={op}>{t(op)}</Option>
                          )}
                        </Select>
                      }
                      {ch.component === 'textarea' &&
                        <Textarea
                          variant="static"
                          label={t(ch.label)}
                          name={ch.name}
                          color={brColor}
                          onChange={onChange}
                          required={ch.required}
                        />
                      }
                    </div>
                  )
                }
                )}
              </div>
            </div>
          )}

          <div className="flex justify-center">
            <Button
              variant="gradient"
              type="submit"
              color={brColor}
              disabled={disable}
            >
              {t("common:submit")}
            </Button>
          </div>
        </form>
      </Card>

    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    theme: sm.user.theme,
    client: sm.client.client || {}
  };
};

const mapActionsToProps = {
  createApp: createApplicationThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["employment"])(Employment));

