
import moment from "moment/moment";
import styled from 'styled-components';
import { BsClock } from "react-icons/bs";
import { BiCut } from "react-icons/bi";
import { PiCheckLight } from "react-icons/pi";
import Card from "components/card";
import noImg from "assets/img/layout/no-image.jpg";
import { withTranslation } from "react-i18next";

const SpecialCard = ({
  className,
  ImageURL,
  Discount,
  PointsSpent,
  Description,
  EndDate,
  IsFree, 
  t
}) => {

  return (
    <Card
      className={`flex flex-col w-full h-full bg-white hover:shadow-2xl ${className || ''}`}
    >
      <div
        className="!p-4 3xl:p-![18px] cursor-pointer"
        // onClick={() => viewDetails(coupon)}
      >
        <div className="mt-5 mb-3 flex justify-center">
          <img
            src={ImageURL || noImg}
            className="h-[150px] w-auto"
          />
        </div>
        <p className="mb-2 text-2xl font-bold text-brand-500 dark:text-white">
          <strong>{IsFree ? t("free_with_points", {points: PointsSpent}).toUpperCase() : t("discount_with_points", {discount: Discount, points: PointsSpent}).toUpperCase()}</strong>
        </p>
        <SummaryDetail className="mt-1 text-sm font-medium text-black-600 md:mt-2">
          {Description}
        </SummaryDetail>
        <p className="flex items-center mt-1 text-sm font-medium theme-tx-color md:mt-2">
          <BsClock className="mr-1 h-3 w-3" />
          <small>{t("expires")+" "+moment(EndDate).format('MM/DD/YY')}</small>
        </p>
      </div>
    </Card>
  );
};

export default withTranslation(["common"])(SpecialCard);

const SummaryDetail = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`