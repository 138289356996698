import { connect } from 'react-redux';
import { Button } from "@material-tailwind/react";
import { stateMappings } from '../../../redux/stateMappings';
import { withRouter } from 'lib/withRouter';
import { THEME_COLOR } from 'variables/constants';

const PageNotFound = ({ client, navigate }) => {
  return (
    <>
      <div className="mt-[100px] text-center">
        <p className="text-gray-500 text-8xl font-bold">Oops!</p>
        <p className="mt-5 dark:text-white">
          <strong className='text-2xl'>404 - PAGE NOT FOUND</strong>
        </p>
        <p className="mt-2 mb-5 max-w-[300px] mx-auto text-md dark:text-white">
          The page you're looking for might have been removed had its name changed or is temporarily unavailable.
        </p>
        <Button
          variant="gradient"
          onClick={() => navigate('/')}
          color={client?.brColor || THEME_COLOR.BORDER}
        >
          GO TO HOMEPAGE
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    client: sm.client.client || {}
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(withRouter(PageNotFound));

