import {
  SET_AVAILABLE_COUPONS,
  SET_CLIPPED_COUPONS,
  ADD_CLIPPED_COUPONS,
  SET_ERROR_MESSAGE,
  SET_LOADING_STATUS,
  SET_AVAILABLE_LOADING_STATUS
} from './actionEnum';


const defaultState = {
  availables: [],
  clippeds: [],
  loading: false,
  available_loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case SET_AVAILABLE_COUPONS:
      return { ...state, availables: action.payload };

    case SET_CLIPPED_COUPONS:
      return { ...state, clippeds: action.payload };

    case ADD_CLIPPED_COUPONS:
      return {
        ...state,
        clippeds: [
          ...state.clippeds,
          ...action.payload,
        ],
        availables: state.availables.filter(coupon =>
          !((action.payload || [])
          .map(pcoupon => pcoupon.id)
          .includes(coupon.id))
        ),
      }

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_AVAILABLE_LOADING_STATUS:
      return { ...state, available_loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  };
};

export default reducer;
