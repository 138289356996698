import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Checkbox, Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { stateMappings } from '../../redux/stateMappings';
import { signInThunk } from '../../redux/auth/thunks';
import { withRouter } from 'lib/withRouter';
import { THEME_COLOR } from 'variables/constants';
import { withTranslation } from 'react-i18next';

class SignIn extends Component {
  constructor(props) {
    super(props);

    const username = localStorage.getItem('loyaltylane') || '';

    this.state = {
      username,
      password: '',
      isRemember: !!username,
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    
    window.location.href = `/my-account`;
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));

  handleIsRememberClick = (e) => {
    this.setState({
      isRemember: !this.state.isRemember,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    const {
      props: { signIn },
      state: { username, password, isRemember },
    } = this;

    try {
      const success = await signIn({
        username,
        password,
        isRemember,
      });

      if (success) {
        this.props.navigate('/')
      }
    } catch(err) {
      console.log("[Login Error]", err);
    }
  };

  render() {
    const { username, password, isRemember } = this.state;
    const { client, t } = this.props

    return (
      <form
        className="mt-[10vh] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start"
        onSubmit={this.handleSubmit}
      >
        {/* Sign in section */}
        <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold theme-tx-color dark:text-white">
            {t("sign_in")}
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            {t("enter_email_password")}
          </p>

          <div className="mb-4">
            <Input
              variant="standard"
              id="email"
              type="email"
              label={t("admin:email")}
              name="username"
              placeholder="mail@loyaltylane.com"
              onChange={this.onChange}
              value={username}
              required
              color={client?.brColor || THEME_COLOR.BORDER}
              autoComplete="user-email"
            />
          </div>

          <div className="mb-6">
            <Input
              variant="standard"
              id="password"
              type="password"
              label={t("password")}
              name="password"
              placeholder={t("min_8_characters")}
              onChange={this.onChange}
              value={password}
              required
              autoComplete="current-password"
              color={client?.brColor || THEME_COLOR.BORDER}
            />
          </div>

          {/* Checkbox */}
          <div className="mb-8 flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox color={client?.brColor || THEME_COLOR.BORDER} />
              <p className="text-sm font-medium text-navy-700 dark:text-white">
                {t("keep_me_logged_in")}
              </p>
            </div>
            <Link
              className="ml-1 text-sm font-medium theme-tx-color theme-dc-color-hover dark:text-white"
              to="/auth/forgot-password"
            >
              {t("forgot_password")+"?"}
            </Link>
          </div>

          <Button
            variant="gradient"
            type="submit"
            color={client?.brColor || THEME_COLOR.BORDER}
            className="w-full"
          >
            {t("sign_in")}
          </Button>

          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              {t("not_registered_yet")}
            </span>
            <Link
              to="/auth/sign-up"
              className="ml-1 text-sm font-medium theme-tx-color theme-dc-color-hover dark:text-white"
            >
              {t("create_account")}
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated,
    client: sm.client.client || {},
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  signIn: signInThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(withTranslation(["auth"])(SignIn)));

