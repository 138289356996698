import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import moment from "moment/moment";
import { BiCut } from "react-icons/bi";
import { IoIosLogIn } from "react-icons/io";
import { PiCheckLight } from "react-icons/pi";
import { withRouter } from "lib/withRouter";
import { withTranslation } from "react-i18next";

const CouponDetails = ({
  isAuthenticated,
  navigate,
  header,
  coupon,
  open,
  openCpDetailsModal,
  clip,
  balance,
  color,
  t
}) => {
  const {
    PointCost,
    ImageUrl,
    OfferSummaryTop,
    OfferSummaryDetail,
    OfferShortDesc,
    ExpirationDate,
    CategoryName,
    Brand,
    ItemUPCs
  } = coupon

  return (
    <Dialog
      open={open}
      size={"lg"}
      handler={openCpDetailsModal}
      className="py-2 px-3 dark:bg-navy-800 dark:text-white md:py-4 md:px-5"
    >
      <div className="absolute top-4 right-4">
        <span className="theme-bg-color text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{`${PointCost} pts`}</span>
      </div>
      <DialogHeader>{header}</DialogHeader>
      <DialogBody className="overflow-auto max-h-[70vh]">
        <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-5">
          <div className="col-span-1 h-full w-full md:col-span-1">
            <div className="mt-5 mb-3 flex justify-center">
              <img
                src={ImageUrl}
                className="h-[150px] w-auto"
              />
            </div>
          </div>
          <div className="col-span-1 h-fit w-full md:col-span-4">
            <p className="mb-2 text-2xl font-bold theme-tx-color dark:text-white">
              <strong>{OfferSummaryTop}</strong>
            </p>
            <p className="mt-1 text-sm font-medium text-black-600">
              {OfferSummaryDetail}
            </p>
            <p className="italic pb-[20px] border-b-1 border-gray-500 theme-tx-color dark:text-white">
              <small>{`${t("common:end_date")} ${moment(ExpirationDate).format('MM/DD/YY')}`}</small>
            </p>
            <div className="mt-5 pb-[20px] border-b-1 border-gray-500">
              {!coupon.clipped &&
                <>
                  {balance >= coupon.PointCost ?
                    <div
                      className="w-[200px] py-2 flex justify-center items-center gap-2 cursor-pointer border-1 border-dashed border-loyal-500  rounded-[20px] theme-bg-color dark:bg-navy-600 text-white"
                      onClick={() => isAuthenticated ? clip(coupon, 'coupon_modal') : navigate('/auth/sign-in')}
                    >
                      {isAuthenticated ?
                        <>
                          <BiCut className="w-5 h-5" />
                          <strong>{t("common:clip_coupon")}</strong>
                        </>
                        :
                        <>
                          <IoIosLogIn className="w-5 h-5" />
                          <strong>{t("auth:sign_in_to_clip")}</strong>
                        </>
                      }
                    </div>
                    :
                    <div
                      className="w-[200px] py-2 flex justify-center items-center gap-2 cursor-not-allowed border-1 border-dashed border-loyal-500 bg-gray-200 rounded-[20px] text-gray-500 dark:bg-gray-800 dark:text-white"
                    >
                      <strong className="italic">{t("common:not_enough_points")}</strong>
                    </div>
                  }
                </>
              }
              {coupon.clipped &&
                <div
                  className="w-[200px] py-2 flex justify-center items-center gap-2 cursor-not-allowed border-1 border-dashed border-loyal-500 bg-loyal-500 rounded-[20px] text-white dark:bg-navy-800 dark:text-white"
                >
                  <PiCheckLight className="w-5 h-5" />
                  <strong>{t("common:clipped_coupon")}</strong>
                </div>
              }
            </div>
            <p className="mt-[20px] text-sm font-medium theme-tx-color dark:text-white">
              {OfferShortDesc}
            </p>
            <div className="mt-5 py-3 px-4 border-1 border-gray-500">
              <p className="text-sm">{`${t("category")}: ${CategoryName}`}</p>
              <p className="text-sm">{`${t("brand")}: ${Brand}`}</p>
            </div>
            <div className="mt-5 py-3 px-4 max-h-[150px] overflow-y-scroll border-1 border-gray-500">
              <strong>{t("common:available_upcs").toUpperCase()}</strong>
              <p className="flex flex-column text-sm">
                {(ItemUPCs || []).join(', ')}
              </p>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="flex justify-center">
        <Button
          variant="gradient"
          onClick={() => openCpDetailsModal()}
          color={color}
        >
          {t("common:ok")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default withRouter(withTranslation()(CouponDetails));
