import React from "react";
import { connect } from 'react-redux';
import { TbCoins } from "react-icons/tb";
import { BsCashCoin } from "react-icons/bs";
import Card from "components/card";
import { getBalanceThunk } from "../../../../redux/balance/thunks";
import { stateMappings } from "../../../../redux/stateMappings";
import Widget from "components/widget/Widget";
import { withTranslation } from "react-i18next";

const Balances = ({
  className,
  balances,
  getBalances,
  t
}) => {

  React.useEffect(() => {
    getBalances();
  }, []);

  const BALANCE_ICONS = {
    points: {
      icon: <TbCoins className="h-7 w-7" />,
      badge: <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-100">{t("common:points")}</span>
    },
    rewards: {
      icon: <BsCashCoin className="h-7 w-7" />,
      badge: <span className="bg-brand-100 text-brand-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-brand-900 dark:text-brand-100">{t("common:rewards")}</span>
    }
  };

  return (
    <>      
      <Card className={className}>
        <h5 className="text-center font-bold text-xl mb-5">{t("My PERKS")}</h5>
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {balances.map(({ BalanceType, BalanceDescription, BalanceAmount }, index) =>
            <Widget
              key={`balance-${index}`}
              icon={BALANCE_ICONS[BalanceType]?.icon}
              badge={BALANCE_ICONS[BalanceType]?.badge}
              title={t(BalanceDescription)}
              subtitle={BalanceAmount}
            />
          )}
      </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    balances: sm.balance.balances || []
  };
};

const mapActionsToProps = {
  getBalances: getBalanceThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["profile"])(Balances));
