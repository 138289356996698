//This file holds the API calls that hit the /user route for DRY purposes
import { ApiRequest } from '../../lib/apiRequest';

export const getAdmins = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/admins`,
  });

export const inviteAdmin = async (data) =>
  ApiRequest.request({
    method: 'POST',
    url: `/admins/invite`,
    data
  });

export const updateAdmin = async (id, data) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/admin/${id}`,
    data
  });

export const removeAdmin = async (id) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/admin/${id}`,
  });

export const acceptInvite = async (token) =>
  ApiRequest.request({
    method: 'POST',
    url: `/admins/accept`,
    data: {
      token
    }
  });

export const createAd = async (data, params) =>
  ApiRequest.request({
    method: 'POST',
    url: `/ad-post/upload`,
    data,
    params
  });
  
export const removeAd = async (id) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/ad-post/${id}`,
  });

export const getAds = async (data) =>
  ApiRequest.request({
    method: 'GET',
    url: `/ad-posts`,
    params: {
      ...data
    }
  });

export const createCarousel = async (data, params) =>
  ApiRequest.request({
    method: 'POST',
    url: `/carousel/upload`,
    data,
    params
  });

export const removeCarousel = async (id) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/carousel/${id}`,
  });

export const getCarousels = async (data = null) =>
  ApiRequest.request({
    method: 'GET',
    url: `/carousels`,
    params: {
      ...data
    }
  });

export const getAbouts = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/abouts`,
  });

export const getAbout = async (id) =>
  ApiRequest.request({
    method: 'GET',
    url: id ? `/about/${id}` : `/about`,
  });

export const createAbout = async (data) =>
  ApiRequest.request({
    method: 'POST',
    url: '/about',
    data
  });

export const updateAbout = async (id, data) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/about/${id}`,
    data
  });

export const removeAbout = async (id) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/about/${id}`,
  });
