/* eslint-disable */
import { IconButton } from "@material-tailwind/react";
import { IoClose } from "react-icons/io5";

const SlideSidebar = ({
  open,
  handleOpen,
  className,
  children,
  color
}) => {
  
  return (
    <>
      <div
        className="z-40 fixed top-0 left-0 w-[100vw] h-[100vh] backdrop-blur-sm bg-opacity-60"
        style={{ display: open ? 'block' : 'none' }}
        onClick={handleOpen}
      >
      </div>
      <div className={`z-50 bg-white dark:bg-navy-900 p-2 h-[100vh] transition-all duration-500 ease-in-out ${className} ${open ? '!left-0' : ''}`}>
        <div className="flex justify-end">
          <IconButton
            variant="text"
            color={color}
            size="lg"
            onClick={handleOpen}
          >
            <IoClose className="w-5 h-5" />
          </IconButton>
        </div>
        <div className="overflow-auto h-[90vh]">
          {children}
        </div>
      </div>
    </>
  );
};

export default SlideSidebar
