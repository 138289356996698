import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { stateMappings } from '../../redux/stateMappings';
import { setResetPasswordThunk } from '../../redux/auth/thunks';
import { withRouter } from 'lib/withRouter';
import { THEME_COLOR } from 'variables/constants';
import { withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    
    window.location.href = `/my-account`;
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = async e => {
    e.preventDefault();

    const {
      props: { resetPassword },
      state: { email },
    } = this;

    try {
      const success = await resetPassword(email);

      if (success) {
        this.props.navigate('/auth/sign-in')
      }
    } catch {

    }

  };

  render() {
    const { email, theme } = this.state;
    const { client, t } = this.props;

    return (
      <form
        className="mt-[10vh] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start"
        onSubmit={this.handleSubmit}
      >
        <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {t("forgot_password")}
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            {t("enter_email_to_get_password")}
          </p>
          {/* Email */}
          <div className="mb-10">
            <Input
              name="email"
              variant="standard"
              label={t("admin:email")}
              placeholder="mail@loyaltylane.com"
              id="email"
              type="email"
              onChange={this.onChange}
              value={email}
              required
              color={client.brColor || THEME_COLOR.brColor}
            />
          </div>
          <Button
            variant="gradient"
            type="submit"
            className="w-full rounded-xl"
            color={client.brColor || THEME_COLOR.brColor}
          >
            {t("send_email")}
          </Button>
          <div className="mt-4 text-center">
            <Link
              to="/auth/sign-in"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              {t("sign_in")}
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated,
    theme: sm.user.theme,
    client: sm.client.client || {}
  };
};

const mapActionsToProps = {
  resetPassword: setResetPasswordThunk,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(withTranslation(["auth"])(ForgotPassword)));

