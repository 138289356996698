import {
  SET_BALANCE,
  SET_OFFER,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';


export const setBalance = (balances) => ({
  type: SET_BALANCE,
  payload: balances,
});

export const setOffer = (offers) => ({
  type: SET_OFFER,
  payload: offers,
});

export const setLoadingStatus = (loading) => ({
  type: SET_LOADING_STATUS,
  payload: loading,
});

export const setErrorMessage = (errMsg) => ({
  type: SET_ERROR_MESSAGE,
  payload: errMsg,
});
