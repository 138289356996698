import React from "react";
import Barcode from 'react-barcode';
import { connect } from 'react-redux';
import { CiCreditCard2 } from "react-icons/ci";
import Card from "components/card";
import { getCardThunk } from "../../../../redux/card/thunks";
import { stateMappings } from "../../../../redux/stateMappings";
import { withTranslation } from "react-i18next";

const PerksCard = ({
  className,
  cardNum,
  getCard,
  theme,
  t
}) => {

  React.useEffect(() => {
    getCard();
  }, []);

  const readCardNumber = (card_number) => {
    return `${card_number.slice(0, 4)}-${card_number.slice(4, 8)}-${card_number.slice(8)}`;
  }

  return (
    <>      
      <Card className={className}>
        <h5 className="text-center font-bold text-xl mb-5">{t("My Rewards Cards")}</h5>
        <div className="mt-5 w-full rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <div className="grid grid-cols-1 md:grid-cols-10">
            <div className="col-span-1 md:col-span-5 flex items-center">
              <div className="flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-900">
                  <span className="flex items-center theme-tx-color dark:text-white">
                    <CiCreditCard2 className="w-8 h-8" />
                  </span>
                </div>
              </div>
              <div className="ml-4">
                <p className="text-xl font-bold text-gray-600 dark:text-white">{t("Card #", {index: "git 1"})}</p>
                <p className="mt-2 text-sm text-gray-600">
                  <span className="ml-1 text-sm font-bold theme-tx-color dark:text-white">
                    {readCardNumber(cardNum)}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-span-1 md:col-span-5">
              <div className="flex justify-center">
                <Barcode
                  value={cardNum}
                  height={70}
                  background={theme === 'light' ? '#ffffff' : '#1B254B'}
                  lineColor={theme === 'light' ? '#000000' : '#ffffff'}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    cardNum: sm.card.Number.toString(),
    theme: sm.user.theme
  };
};

const mapActionsToProps = {
  getCard: getCardThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["profile"])(PerksCard));
