import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Button,
  Select,
  Textarea,
  Option,
  Checkbox
} from "@material-tailwind/react";
import { MdCheckCircle, MdOutlineError, MdCancel } from "react-icons/md";
import AppTable from "./components/AppTable";
import { stateMappings } from "../../../redux/stateMappings";
import { getApplicationsThunk, editApplicationThunk, removeApplicationThunk } from "../../../redux/application/thunks";
import { applicationComplex } from "../employment/variables/applicationComplex";
import { THEME_COLOR, COL_ACCESS, APP_STATUS } from "variables/constants";
import { withTranslation } from "react-i18next";

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {
        details: {},
        status: 'In review'
      }
    }
  }

  async componentDidMount() {
    await this.props.getApps();
  }

  openDetailsModal = (data = {
    details: {},
    status: 'In review'
  }) => {
    this.setState({
      open: !this.state.open,
      data
    })
  }

  reviewApp = async () => {
    const { data } = this.state
    const { applications, editApp } = this.props
    const application = applications.find(app => app._id === data._id);

    if (application.status !== data.status) {
      await editApp(data)
    }

    this.openDetailsModal()
  }

  onStatusChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        status: value
      }
    })
  }

  render() {
    const { open, data } = this.state;
    const { applications, removeApp, client, t } = this.props;
    const columnsDataCheck = [
      {
        Header: t("admin:name").toUpperCase(),
        accessor: COL_ACCESS.NAME
      },
      {
        Header: t("admin:email").toUpperCase(),
        accessor: COL_ACCESS.EMAIL,
      },
      {
        Header: t("admin:date").toUpperCase(),
        accessor: COL_ACCESS.DATE,
      },
      {
        Header: t("admin:status").toUpperCase(),
        accessor: COL_ACCESS.STATUS,
      },
      {
        Header: t("admin:action").toUpperCase(),
        accessor: COL_ACCESS.ACTION,
      },
    ];

    return (
      <>
        <Dialog
          open={open}
          size={"xl"}
          handler={() => this.openDetailsModal()}
          className="py-2 px-3 dark:bg-navy-800 dark:text-white md:py-4 md:px-5"
        >
          <DialogHeader className="block md:flex justify-between">
            <div className="">
              <span className="theme-tx-color">{t("common:application")} {t("common:details")}</span>
            </div>
            <div className="md:max-w-[200px]">
              <Select
                variant="standard"
                name="status"
                label=""
                color={client?.brColor || THEME_COLOR.BORDER}
                value={data.status}
                onChange={this.onStatusChange}
              >
                <Option
                  className="flex items-center gap-2"
                  value={APP_STATUS.INREVIEW}
                >
                  <MdOutlineError className="text-orange-500" />
                  <span>{t("admin:in_review")}</span>
                </Option>
                <Option
                  className="flex items-center gap-2"
                  value={APP_STATUS.REJECTED}
                >
                  <MdCancel className="text-brand-500" />
                  <span>{t("admin:rejected")}</span>
                </Option>
                <Option
                  className="flex items-center gap-2"
                  value={APP_STATUS.ACCEPTED}
                >
                  <MdCheckCircle className="text-green-500" />
                  <span>{t("admin:accepted")}</span>
                </Option>
              </Select>
            </div>
          </DialogHeader>
          <DialogBody className="overflow-auto max-h-[70vh]">
            {applicationComplex.map((em, index) =>
              <div
                key={`employment-complex-${index}`}
                className="mb-5"
              >
                {em.label && <h5 className={`mb-3 ${em.style || ''}`}><strong>{t(em.label)}</strong></h5>}
                {em.descriptions && 
                <ul>
                  {(em.descriptions || []).map((dc, dIndex) => {
                    return <li key={`em-dc-${dIndex}`} className="text-gray-500">{t(dc)}</li>
                  })}  
                </ul>}
                <div className={`grid grid-cols-1 md:grid-cols-${em.span} gap-5`}>
                  {(em.child || []).map((ch, index) => {
                    return (
                      <div key={`em-child-${index}`}>
                        {ch.component === 'input' &&
                          <Input
                            variant="standard"
                            name={ch.name}
                            label={t(ch.label)}
                            color={client?.brColor || THEME_COLOR.BORDER}
                            value={data.details[ch.name]}
                            readOnly
                          /> 
                        }
                        {ch.component === 'checkbox' &&
                          <Checkbox
                            label={t(ch.label)}
                            name={ch.name}
                            color={client?.brColor || THEME_COLOR.BORDER}
                            value={data.details[ch.name]}
                            disabled
                          />
                        }
                        {ch.component === 'select' &&
                          <Select
                            variant="standard"
                            name={ch.name}
                            label={t(ch.label)}
                            color={client?.brColor || THEME_COLOR.BORDER}
                            value={data.details[ch.name]}
                            readOnly
                          >
                            {(ch.options || []).map((op, oIndex) =>
                              <Option key={`ch-option-${oIndex}`} value={op}>{t(op)}</Option>
                            )}
                          </Select>
                        }
                        {ch.component === 'textarea' &&
                          <Textarea
                            variant="static"
                            label={t(ch.label)}
                            name={ch.name}
                            color={client?.brColor || THEME_COLOR.BORDER}
                            value={data.details[ch.name]}
                            readOnly
                          />
                        }
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
            )}
          </DialogBody>
          <DialogFooter className="flex justify-center">
            <Button
              variant="gradient"
              onClick={this.reviewApp}
              color={client?.brColor || THEME_COLOR.BORDER}
            >
              <span>{t("common:ok")}</span>
            </Button>
          </DialogFooter>
        </Dialog>
        <div className="mt-5">
          <AppTable
            columnsData={columnsDataCheck}
            tableData={applications}
            remove={removeApp}
            openDetails={this.openDetailsModal}
            color={client?.brColor || THEME_COLOR.BORDER}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    applications: (sm.application.applications || []).map(app => {
      return {
        ...app,
        details: app.details || {},
        fullName: `${app.details?.firstName} ${app.details?.lastName}`,
        email: app.details?.email,
      };
    }),
    theme: sm.user.theme,
    client: sm.client.client || {}
  };
};

const mapActionsToProps = {
  getApps: getApplicationsThunk,
  removeApp: removeApplicationThunk,
  editApp: editApplicationThunk
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["employment"])(Applications));
