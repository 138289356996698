export const getSubDomain = () => {
    let subDomain = "";
    const host = window.location.host;
    const domains = host.split(".").splice(0, host.includes("localhost")? 1 : 2);
    if (domains.length > 0) subDomain = domains[0];
    return subDomain;
}

export const setThemeColors = (bgColor, brColor, txColor) => {
    document.documentElement.style.setProperty('--bgColor', bgColor);
    document.documentElement.style.setProperty('--brColor', brColor);
    document.documentElement.style.setProperty('--txColor', txColor);
}