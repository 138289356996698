import {
  SET_APPLICATIONS,
  ADD_APPLICATION,
  EDIT_APPLICATION,
  DELETE_APPLICATION,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

export const setApplications = (applications) => ({
  type: SET_APPLICATIONS,
  payload: applications,
});

export const addApplication = (application) => ({
  type: ADD_APPLICATION,
  payload: application,
});

export const editApplication = (application) => ({
  type: EDIT_APPLICATION,
  payload: application,
});

export const removeApplication = (application) => ({
  type: DELETE_APPLICATION,
  payload: application,
});

export const setLoadingStatus = (loading) => ({
  type: SET_LOADING_STATUS,
  payload: loading
});

export const setErrorMessage = (errMsg) => ({
  type: SET_ERROR_MESSAGE,
  payload: errMsg
});
