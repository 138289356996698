import {
  SIGNIN_USER,
  SIGNOUT_USER,
  REGISTER_USER,
  SET_PASSWORD_RESET_TOKEN,
  SET_STATES,
  SET_STORES,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const shopper = {
  token: '',
  shopperid: 0,
  accountid: 0,
  isfulllogin: false,
  isAdmin: false,
};

const user = {
  NewAccountShopper: {},
  BalanceList: [],
  EnrollCard: {},
  AccountTypeIds: [],
  CashierNumber: 0,
  IsNewAccount: true,
  IsVirtual: true,
  AccountName: '',
  AccountDescription: '',
  HomeStoreID: 0,
  ShooperLevelID: 0,
  Enabled: true,
  Tier: 0,
  IsTestAccount: true,
  MasterAccountID: 0,
  RegisteredDate: '',
  EnrollDate: '',
  MemberType: '',
  MemberSince: '',
  MemberExpiration: '',
  MemberLastPaid: '',
  ID: 0,
};

const passwordResetToken = {
  contactInfo: '',
  nonce: '',
  expiration: '',
  shopperId: 0,
  accountId: 0,
  ID: 0,
};

const defaultState = {
  shopper: JSON.parse(
    localStorage.getItem('shopper')
    || JSON.stringify(shopper)
  ),
  user: {},
  passwordResetToken: passwordResetToken,
  states: [],
  stores: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    case REGISTER_USER:
      return { ...state, user: action.payload };

    case SIGNIN_USER:
      return { ...state, shopper: action.payload };

    case SIGNOUT_USER:
      return { ...state, user: {}, shopper: {} };

    case SET_STATES:
      return { ...state, states: action.payload };
    
    case SET_STORES:
      return { ...state, stores: action.payload };

    case SET_PASSWORD_RESET_TOKEN:
        return { ...state, passwordResetToken: action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
