import {
  SET_CARD,
  EDIT_CARD,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';


export const setCard = (card) => ({
  type: SET_CARD,
  payload: card,
});

export const editCard = (card) => ({
  type: EDIT_CARD,
  payload: card,
});

export const setLoadingStatus = (loading) => ({
  type: SET_LOADING_STATUS,
  payload: loading,
});

export const setErrorMessage = (errMsg) => ({
  type: SET_ERROR_MESSAGE,
  payload: errMsg,
});
