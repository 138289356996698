import {
  register,
  authenticate,
  ResetPassword,
  getStates,
  getStores,
} from './apiCalls';
import {
  registerUser,
  signInUser,
  signOutUser,
  setPasswordResetToken,
  setStates,
  setLoadingStatus,
  setErrorMessage,
  setStores,
} from './actions';
import { initialUser } from '../user/actions';
import { setToken, removeToken, refreshInstance } from '../../lib/apiRequest';
import { setToast } from '../../lib/toast';
import { OK } from 'variables/constants';

export const signUpThunk = (candidate) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await register(candidate);
    const { user, result } = data || {};

    if (user && result === OK) {
      dispatch(registerUser(user));
      setToast('success', 'auth:msg_account_registered');
    }
    dispatch(setStatus(false, ''));

    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return false;
};

export const signInThunk = (credentials) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await authenticate({
      username: credentials.username,
      password: credentials.password,
    });
    const { data: shopper, result } = data || {};

    if (shopper && result === "OK") {
      setToken(shopper);
      refreshInstance();

      if (credentials.isRemember) {
        localStorage.setItem('loyaltylane', credentials.username);
      }

      dispatch(signInUser(shopper));
      setToast('success', 'auth:msg_account_signed_in');
    }

    dispatch(setStatus(false, ''));
    
    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return false;
};

export const setShopperThunk = (admin = true) => (
  dispatch
) => {
  try {
    const shopper = JSON.parse(localStorage.getItem('shopper'));
    dispatch(signInUser({
      ...shopper,
      isAdmin: admin,
    }));
  } catch (err) {
    return;
  }
};

export const signOutThunk = () => async (
  dispatch
) => {
  removeToken();
  refreshInstance();

  dispatch(signOutUser());
  dispatch(initialUser());
  dispatch(setStatus(false, ''));
  setToast('info', 'auth:msg_signed_out');
};

export const setResetPasswordThunk = (email) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await ResetPassword(email);
    const { data: passwordResetToken, result } = data || {};
    if (passwordResetToken && result === "OK") {
      dispatch(setPasswordResetToken(passwordResetToken));
      dispatch(setStatus(false, ''));
    }

    return true;
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }

  return false;
};

export const getStatesThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getStates();
    const { data: statesData, result } = data || {};
    const { states } = statesData || {};

    if (states && result === "OK") {
      dispatch(setStates(states));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    // setToast('error', error.response.data?.message);
  }
};

export const getStoresThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getStores();
    const { data: storesData, result } = data || {};
    const { stores } = storesData || {};

    if (stores && result === "OK") {
      dispatch(setStores(stores));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    // setToast('error', error.response.data?.message);
  }
};


const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
