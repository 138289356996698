import React from "react";
import { connect } from 'react-redux';
import { Carousel, IconButton } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { FaQuestionCircle } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";
import { TbCoins } from "react-icons/tb";
import CardImg from "assets/img/layout/card_front.jpg";
import logoImg from "assets/img/auth/logo.png";
import logoWhiteImg from "assets/img/auth/logo-white.png";
import { getCarouselsThunk } from '../../../redux/admin/thunks';
import { getAvailableCouponsThunk, addClippedCouponsThunk } from '../../../redux/coupon/thunks';
import { getBalanceThunk } from "../../../redux/balance/thunks";
import { stateMappings } from '../../../redux/stateMappings';
import config from "../../../config";
import { THEME_MODE, THEME_COLOR } from "variables/constants";
import HomeCoupons from "./HomeCoupons";
import { withTranslation } from "react-i18next";

const HOME = ({
  isAuthenticated,
  configs,
  client,
  carousels,
  getCarousels,
  accountid,
  getAvailableCoupons,
  coupons,
  getBalance,
  balance,
  clip,
  theme,
  t
}) => {

  React.useEffect(() => {
    getCarousels({date: new Date()});

    async function fetchCoupons() {
      try {
        await Promise.all([
          isAuthenticated ? getBalance() : undefined,
          getAvailableCoupons(isAuthenticated ? accountid : 1),
        ]);
      } catch { }
    }

    fetchCoupons();
  }, [client]);

  return (
    <>
      <section className="pt-4 pb-4 md:pt-6 md:pb-6 px-3 2xl:px-0">
        <div className={`mx-auto max-w-[1380px] rounded-[30px] px-3 py-5 md:px-8 theme-bg-color dark:bg-navy-800`}>
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-1 md:col-span-7 flex items-center">
              <div className="text-white dark:text-white">
                <h1 className="text-2xl md:text-4xl text-center md:text-start font-bold mb-5">{t("let_us_shop_for_you")}</h1>
                <p className="text-sm">{t("with_online_ordering_and_curbside_pickup")}</p>
              </div>
            </div>
            <div className="col-span-1 md:col-span-5">
              <div className="flex items-center justify-center">
                <a
                  href={config.market_url}
                  target="_blank"
                >
                  <img className="max-w-[150px] md:max-w-[200px]" src={theme === THEME_MODE.DARK ? logoWhiteImg : logoImg} alt={t("steve_logo")} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white py-8 dark:bg-navy-800 px-3 2xl:px-0">
        <div className="mx-auto max-w-[1380px]">
          <Carousel
            className="mb-10 relative rounded-[30px]"
            loop
            autoplay
            prevArrow={({ handlePrev }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handlePrev}
                className="!absolute top-2/4 -left-2 md:left-4 -translate-y-2/4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={4}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </IconButton>
            )}
            nextArrow={({ handleNext }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handleNext}
                className="!absolute top-2/4 md:!right-4 -right-2 -translate-y-2/4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={4}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </IconButton>
            )}
            navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className="absolute md:bottom-4 bottom-1 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                {new Array(length).fill("").map((_, i) => (
                  <span
                    key={i}
                    className={`block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] ${
                      activeIndex === i ? "bg-white" : "bg-white/50"
                    }`}
                    onClick={() => setActiveIndex(i)}
                  />
                ))}
              </div>
            )}
          >
            {carousels.map(({
              fileName,
              background,
              heading,
              title,
              text
            }, index) => {
              return (
                <div
                  key={`carousel-div-${index}`}
                  className="flex justify-between h-[250px] md:h-[400px] p-3"
                  style={{ background: background }}
                >
                  <div className="flex items-center justify-center w-2/5">
                    <div className="text-center text-black dark:text-white whitespace-normal">
                      <h1
                        className="text-2xl md:text-5xl font-bold mb-3"
                        dangerouslySetInnerHTML={{ __html: heading.replace('$', '<span class="dollar"></span>') }}
                      />
                      <p className="text-md md:text-2xl lg:text-3xl mb-1">{title}</p>
                      <p className="text-sm md:text-xl lg:text-2xl">{text}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-left w-3/5">
                    <img className="h-full" src={`${config.api_url}/ad-post/images/${fileName}`} alt="Ad Image" />
                  </div>
                </div>
              )
            })}
          </Carousel>
          <div className="text-center text-black dark:text-white">
            <h1 className="text-2xl md:text-4xl font-bold mb-5">
              {t("all_shopping_should_be_simple")}
            </h1>
            <p className="text-md md:text-2xl">
              {t("great_prices_on_family_favorite_items")}
            </p>
          </div>
        </div>
      </section>
      <section className="pt-12 pb-12 md:pt-24 md:pb-24 px-3 2xl:px-0">
        <div className="mx-auto max-w-[1380px]">
          <div className="flex items-center justify-center">
            <img className="w-[240px] md:min-w-[300px]" src={CardImg} alt={t("steve_card")} />
          </div>
          <div className="text-center pt-[50px]">
            <p className="text-xl md:text-4xl font-bold dark:text-white">{t("enroll_today_at_your_nearest")}</p>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-navy-800 pt-12 pb-12 md:pt-24 md:pb-24 px-3 2xl:px-0">
        <div className="mx-auto max-w-[1380px] dark:text-white">
          <p className="text-md md:text-2xl text-center mb-5">{t("we_believe_perfect_combination")}</p>
          <p className="text-md md:text-2xl text-center mb-5">{t("we_call_it")} <span className={`text-lg md:text-3xl font-bold theme-tx-color`}>{t("family_rewards_program").toUpperCase()}</span></p>
          <p className="text-md md:text-2xl text-center">{t("we_strive_to_exceed_your_expectations")}</p>
        </div>
      </section>

      {coupons.length > 0 &&
        <section className="pt-12 pb-12 md:pt-24 md:pb-24 px-3 2xl:px-0 dark:bg-navy-900">
          <h1 className="text-xl md:text-4xl text-center font-bold mb-[40px] dark:text-white">{t("common:digital_coupons")}</h1>
          <HomeCoupons
            coupons={coupons}
            isAuthenticated={isAuthenticated}
            clip={clip}
            getBalance={getBalance}
            balance={balance}
            color={client?.brColor || THEME_COLOR.BORDER}
          />
        </section>
      }

      <section className="pt-12 pb-12 md:pt-24 md:pb-24 px-3 2xl:px-0 bg-white dark:bg-navy-800">
        <div className="mx-auto max-w-[1380px] dark:text-white">
          <h1 className="text-xl md:text-4xl text-center font-bold mb-[40px]">{t("check_out_our_new_rewards_program")}</h1>
          <div className="flex justify-center">
            <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
              <div className="rounded-[10px] bg-gray-200 px-2 py-3 dark:bg-navy-900">
                <div className="flex items-center md:justify-center gap-2">
                  <div className="flex w-auto flex-row items-center">
                    <div className="rounded-full bg-lightPrimary p-2 dark:bg-navy-700">
                      <span className={`flex items-center theme-tx-color dark:text-white text-2xl`}>
                        <FaQuestionCircle />
                      </span>
                    </div>
                  </div>
                  <Link
                    to="/rewards-faq"
                    className={`md:text-xl text-md hover:underline theme-tx-color dark:text-white font-medium`}
                  >
                    {t("view_rewards_program_details")}
                  </Link>
                </div>
              </div>

              {isAuthenticated ?
                <div className="rounded-[10px] bg-gray-200 px-2 py-3 dark:bg-navy-900">
                  <div className="flex items-center md:justify-center gap-3">
                    <div className="flex w-auto flex-row items-center">
                      <div className="rounded-full bg-lightPrimary p-2 dark:bg-navy-700">
                        <span className="flex items-center theme-tx-color dark:text-white text-2xl">
                          <TbCoins />
                        </span>
                      </div>
                    </div>
                    <Link
                      to="/my-account/perks"
                      className="md:text-xl text-md hover:underline theme-tx-color dark:text-white font-medium"
                    >
                      {t("check_your_points_balance")}
                    </Link>
                  </div>
                </div>
                :
                <div></div>
              }
              <div className="rounded-[10px] bg-gray-200 px-2 py-3 dark:bg-navy-900">
                <div className="flex items-center md:justify-center gap-3">
                  <div className="flex w-auto flex-row items-center">
                    <div className="rounded-full bg-lightPrimary p-2 dark:bg-navy-700">
                      <span className="flex items-center theme-tx-color dark:text-white text-2xl">
                        <ImFacebook2 />
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://www.facebook.com/StevesMarketDeli?fref=ts"
                    className="md:text-xl text-md hover:underline theme-tx-color dark:text-white font-medium"
                  >
                    {t("find_us_on_facebook")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    accountid: sm.accountid,
    coupons: sm.coupon.availables || [],
    configs: sm.configs,
    client: sm.client.client || {},
    carousels: sm.admin.carousels || [],
    theme: sm.user.theme,
    balance: (sm.balance.balances || []).find(balance => balance.BalanceType === 'points')?.BalanceAmount || 0,
  };
};

const mapActionsToProps = {
  getCarousels: getCarouselsThunk,
  getAvailableCoupons: getAvailableCouponsThunk,
  getBalance: getBalanceThunk,
  clip: addClippedCouponsThunk,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withTranslation(["home"])(HOME));