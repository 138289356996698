const config = {
  dev: {
    domain: '*.localhost',
    base_url: '',
    api_url: 'http://localhost:5000/api',
    market_url: 'https://shop4u.stevesmarkets.com/online'
  },
  prod: {
    domain: '*.ax-test.com',
    base_url: '',
    api_url: '/api',
    market_url: 'https://shop4u.stevesmarkets.com/online'
  },
};

export default config['prod'];

