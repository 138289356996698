import {
  SET_ACCOUNT,
  EDIT_ACCOUNT,
  SET_SHOPPER,
  EDIT_SHOPPER,
  INITILA_USER,
  SET_THEME_MODE,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

export const setAccount = (account) => ({
  type: SET_ACCOUNT,
  payload: account,
});

export const editAccount = (account) => ({
  type: EDIT_ACCOUNT,
  payload: account,
});

export const setShopper = (shopper) => ({
  type: SET_SHOPPER,
  payload: shopper,
});

export const editShopper = (shopper) => ({
  type: EDIT_SHOPPER,
  payload: shopper,
});

export const initialUser = (user = null) => ({
  type: INITILA_USER,
  payload: user,
});

export const setThemeMode = (theme) => ({
  type: SET_THEME_MODE,
  payload: theme,
});

export const setLoadingStatus = (loading) => ({
  type: SET_LOADING_STATUS,
  payload: loading
});

export const setErrorMessage = (errMsg) => ({
  type: SET_ERROR_MESSAGE,
  payload: errMsg
});
