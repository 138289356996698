import {
  getBalance,
  getOffer,
} from './apiCalls';
import {
  setBalance,
  setOffer,
  setErrorMessage,
  setLoadingStatus,
} from './actions';
import { setToast } from 'lib/toast';


export const getBalanceThunk = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getBalance();
    const { data: balance, result } = data || {};

    if (balance && result === "OK") {
      dispatch(setBalance(balance));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

export const getOfferThunk = (includeuntargeted = true) => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data } = await getOffer(includeuntargeted);
    const { data: offer, result } = data || {};

    if (offer && result === "OK") {
      dispatch(setOffer(offer.offers || {
        targeted: [],
        untargeted: [],
      }));
    }

    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.response.data?.message));
    setToast('error', error.response.data?.message);
  }
};

const setStatus = (status, message = '') =>
  async (dispatch) => {
    dispatch(setErrorMessage(message));
    dispatch(setLoadingStatus(status));
  };
