export const SET_ADMINS = "SET_ADMINS";
export const ADD_ADMIN = "ADD_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const SET_ABOUTS = "SET_ABOUTS";
export const SET_ABOUT = "SET_ABOUT";
export const ADD_ABOUT = "ADD_ABOUT";
export const EDIT_ABOUT = "EDIT_ABOUT";
export const DELETE_ABOUT = "DELETE_ABOUT";
export const SET_ADS = "SET_ADS";
export const ADD_AD = "ADD_AD";
export const DELETE_AD = "DELETE_AD";
export const SET_CAROUSELS = "SET_CAROUSELS";
export const ADD_CAROUSEL = "ADD_CAROUSEL";
export const DELETE_CAROUSEL = "DELETE_CAROUSEL";
export const SET_LOADING_STATUS = "SET_ADMINS_LOADING_STATUS";
export const SET_ERROR_MESSAGE = "SET_ADMINS_ERROR_MESSAGE";

